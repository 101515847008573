import React from 'react';
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Style from 'assets/jss/material-dashboard-pro-react/views/cartStyle';
import { Card, Typography } from '@material-ui/core'

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// icons
import { CheckCircleOutline, Close, Info } from "@material-ui/icons";
import { BsCheckCircleFill } from 'react-icons/bs';
;

export function ConfirmModal(props){
    const{
        classes,
        history,
        setConfirmModal,
        Trash,
        confirmOrderUpdate
    } = props

    return(
        <Card className={classes.confirmModalCard}>
            <GridContainer >
                <GridItem xs={12} style={{ justifyContent: "center", display: "flex" }}>
                <h5 className={classes.cardHeader}>
                    Are you sure? 
                </h5>
                </GridItem>

                <GridItem xs={12} style={{ justifyContent: "center", display: "flex" }}>
                <h5 className={classes.cardDescription} style={{ textAlign: "center" }}>
                    All changes will be sent in an email to the Merchant. 
                    Any deleted rewards will reimburse Merchants with the points or money spent.
                </h5>
                </GridItem>

                <GridItem
                    xs={12}
                    style={{ justifyContent: "center", display: "flex" }}
                >
                    <GridContainer>
                        <GridItem xs={6}>
                            <Button
                                    className={classes.checkoutButton}
                                    style={{background:"#56CA93"}}
                                    onClick={() => {
                                        setConfirmModal(false)
                                    }}
                                >
                                <Trash style={{ marginRight: "10px" }} /> Cancel
                            </Button>
                        </GridItem>

                        <GridItem xs={6}>
                            <Button
                                    className={classes.checkoutButton}
                                    onClick={() => {
                                        setConfirmModal(false)
                                        confirmOrderUpdate()
                                    }}
                                >
                                <BsCheckCircleFill style={{ marginRight: "10px" }} /> Confirm
                            </Button>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </Card>
    )
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(ConfirmModal);