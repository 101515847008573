/*eslint-disable*/
import React, { useState, createRef, useEffect } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import actionsForNavigation from "store/actions/navigation";
import AuthActions from 'store/actions/auth';
import { db, firebase, storage } from "firebase/client";
import UserInfo from "./components/UserInfo";
import BillingInfo from "./components/BillingInfo";
import UserInfoMobile from "./components/UserInfoMobile";
import BillingInfoMobile from "./components/BillingInfoMobile";
import { updateUserData, updateUserPhoto, updateUserLogo } from "store/actions/auth.js";
import { deleteUserPhoto } from "store/actions/auth";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { Typography } from "@material-ui/core";



// @material-ui/icons
import { ReactComponent as Edit } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";
import { ReactComponent as Delete } from "assets/img/tuzo/icons/Delete.svg";


//styling 
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";
import tuzoBg from "assets/img/tuzo/carouselBackground.png";

export function UserProfile(props) {
  const {
    classes,
    firstName,
    lastName,
    email,
    cellPhone,
    secondaryPhone,
    shippingAddress,
    birthday,
    companyName,
    uid,
    user,
    userInfo,
    authInfo,
    profilePic,
    usernameState,
    editState
  } = props;

  const currUser = firebase.auth().currentUser
  const [username, setUsername] = useState(
    usernameState ? usernameState : ""
  )
  const [firstNameState, setFirstNameState] = useState(
    userInfo.firstName ? userInfo.firstName : firstName ? firstName : ""
  );
  const [lastNameState, setLastNameState] = useState(
    userInfo.lastName ? userInfo.lastName : lastName ? lastName : ""
  );
  const [emailState, setEmailState] = useState(
    authInfo.email ? authInfo.email : email ? email : ""
  );
  const [copyEmail, setCopyEmail] = useState(
    authInfo.email ? authInfo.email : email ? email : ""
  )
  const [cellPhoneState, setCellPhoneState] = useState(
    userInfo.cellPhone ? userInfo.cellPhone : cellPhone ? cellPhone : ""
  );

  const [secondaryPhoneState, setSecondaryPhoneState] = useState(
    userInfo.secondaryPhone ? userInfo.secondaryPhone : secondaryPhone ? secondaryPhone : ""
  )

  const [shippingAddressState, setShippingAddressState] = useState(
    userInfo.shippingAddress ? userInfo.shippingAddress : shippingAddress ? shippingAddress : ""
  )

  const [companyState, setCompanyState] = useState(
    userInfo.companyName
      ? userInfo.companyName
      : companyName
        ? companyName
        : ""
  );

  const [birthdayState, setBirthdayState] = useState(
    userInfo.birthday ? userInfo.birthday : birthday ? birthday : ""
  )
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [globalEdit, setGlobalEdit] = useState(editState ? editState : false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteMobile, setConfirmDeleteMobile] = useState(false)
  const [billingEdit, setBillingEdit] = useState(false)
  const [cardNumber, setCardNumber] = useState("")
  const [expDate, setExpDate] = useState("")
  const [nameOnCard, setNameOnCard] = useState("")
  const [billingAddress, setBillingAddress] = useState("")
  const [asDefault, setAsDefault] = useState(true)
  const [newPayment, setNewPayment] = useState(false)
  const [typeOfPayment, setTypeOfPayment] = useState({
    card: true,
    ach: false
  })

  useEffect(() => {
    if (progress === 100) {
      setUploading(false);
    }
  }, [progress]);


  function formatPhoneNumber(phoneNumber) {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return "";
  }
  const updateUserInfo = (e) => {
    e.preventDefault();
    var newInfo = {
      username: username,
      firstName: firstNameState,
      lastName: lastNameState,
      companyName: companyState,
      birthday: birthdayState,
      credentials: emailState,
      email: emailState,
      cellPhone: cellPhoneState,
      secondaryPhone: secondaryPhoneState,
      shippingAddress: shippingAddressState,
    };
    if (copyEmail !== emailState) {
      currUser.updateEmail(emailState)
        .then(() => {
          props.updateUserData(uid, newInfo).then(() => {
            setGlobalEdit(false)
          })

        })
    }
    else {
      props.updateUserData(uid, newInfo).then(() => {
        setGlobalEdit(false)
      })
    }
  };

  const handleImageUpload = (event) => {
    var previewUrl = URL.createObjectURL(event.target.files[0]);
    var file = event.target.files[0];
    const storageRef = storage.ref(uid);
    const fileRef = storageRef.child("profilePic");

    var uploadTask = fileRef.put(file);

    // fileRef.put(file).then((snapshot) => {
    setUploading(true)

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      (snapshot) => {
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then((downloadURL) => {
            console.log("downloadURL", downloadURL);
            props.updateUserPhoto(uid, { url: downloadURL, });
          })
          .catch(() => { });
      }
    );
  };

  const getBgImage = () => {
    return tuzoBg;
  }

  const deleteImage = () => {
    props.deleteUserPhoto(uid)
    setConfirmDelete(false)
  }

  return (
    <div>
      <Hidden mdUp implementation='css'>
        <GridContainer style={{ marginTop: "38px" }}>
          <GridItem xs={12} style={{ display: "flex", margin: "12px 0px" }}>
            <Typography variant="h2" className={classes.headerTitle}>
              {firstNameState + " " + lastNameState + "  "}
            </Typography>
          </GridItem>

          <GridItem xs={6} style={{ display: "flex" }}>
            <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column", marginTop: "5px" }}>
              <Typography variant="h2" className={classes.headerDesc}>
                Account Number
              </Typography>
              <Typography variant="h2" className={classes.headerDesc} style={{ fontWeight: 500, fontSize: "18px", lineHeight: "18px", marginTop: "10px" }}>
                {user.merchantId}
              </Typography>
            </div>
          </GridItem>

          <GridItem xs={12} style={{ margin: "10px 0px 0px" }}>
            {billingEdit && !globalEdit ?
              null
              :
              !globalEdit ?
                <Button
                  onClick={() => setGlobalEdit(true)}
                  className={classes.editButtonMobile}
                >
                  <Edit style={{ marginRight: "10px", marginTop: "0px" }} /> Edit
                </Button>
                :

                <div>
                  <Button
                    className={classes.editButtonMobile}
                    style={{ background: "#1A405F", marginRight: "15px" }}
                    onClick={updateUserInfo}
                  >
                    <SaveIcon style={{ marginRight: "10px", marginTop: "-5px" }} /> Save
                  </Button>
                  <Button
                    onClick={() => setGlobalEdit(false)}
                    className={classes.editButtonMobile}
                    style={{ background: "#E2A400" }}
                  >
                    <Delete style={{ marginRight: "10px", marginTop: "-5px" }} /> Cancel
                  </Button>
                </div>
            }
          </GridItem>
        </GridContainer>

        <div
          style={{
            backgroundImage: "url(" + getBgImage() + ")",
            width: "100vw",
            marginLeft: "-30px",
            height: "100%",
            marginTop: "15px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#274b73",
            backgroundPhoto: "no-repeat",
            backgroundSize: "contain",
            padding: "20px"
          }}
        >
          <GridContainer>
            <GridItem xs={12} style={{ textAlign: "center", marginTop: "10px" }}>
              <Typography variant="h2" className={classes.profileBannerText}>
                You have <span className={classes.profileBannerPts}> {Math.trunc(user.tuzoPoints).toLocaleString()} </span> Tuzo points now
                and <span className={classes.profileBannerPts}> {user.approvedPts ? (user.approvedPts).toLocaleString() : 0} </span> pending!
              </Typography>
            </GridItem>

            <GridItem xs={12} style={{ textAlign: "center", marginTop: "10px" }}>
              <Typography variant="h2" className={classes.profileBannerText} style={{ lineHeight: "30px" }}>
                Based on your average monthly processing ({Math.trunc(user.tuzoPoints).toLocaleString()}), you’re on track to have:
              </Typography>
            </GridItem>

            <GridItem xs={12} style={{ marginTop: "10px", display: "flex", justifyContent: "space-evenly", textAlign: "center" }}>

              <div>
                <Typography variant="h2" className={classes.profileBannerDurationText}>
                  1 Month:
                </Typography>
                <Typography variant="h2" className={classes.profileBannerDurationPts}>
                  {Math.trunc(user.tuzoPoints.toLocaleString())}
                </Typography>
              </div>

              <div>
                <Typography variant="h2" className={classes.profileBannerDurationText}>
                  1 Year:
                </Typography>
                <Typography variant="h2" className={classes.profileBannerDurationPts}>
                  {Math.trunc(user.tuzoPoints * 12).toLocaleString()}
                </Typography>
              </div>

              <div>
                <Typography variant="h2" className={classes.profileBannerDurationText}>
                  3 Years:
                </Typography>
                <Typography variant="h2" className={classes.profileBannerDurationPts}>
                  {Math.trunc(user.tuzoPoints * 36).toLocaleString()}
                </Typography>
              </div>
            </GridItem>

          </GridContainer>
        </div>

        <GridContainer >
          <GridItem xs={12} style={{ marginTop: "20px" }}>
            <UserInfoMobile
              username={username}
              setUsername={setUsername}
              user={user}
              profilePic={profilePic}
              firstNameState={firstNameState}
              setFirstNameState={setFirstNameState}
              lastNameState={lastNameState}
              setLastNameState={setLastNameState}
              companyState={companyState}
              setCompanyState={setCompanyState}
              birthdayState={birthdayState}
              setBirthdayState={setBirthdayState}
              emailState={emailState}
              setEmailState={setEmailState}
              cellPhoneState={cellPhoneState}
              setCellPhoneState={setCellPhoneState}
              secondaryPhoneState={secondaryPhoneState}
              setSecondaryPhoneState={setSecondaryPhoneState}
              shippingAddressState={shippingAddressState}
              setShippingAddressState={setShippingAddressState}
              globalEdit={globalEdit}
              handleImageUpload={handleImageUpload}
              uploading={uploading}
              progress={progress}
              deleteImage={deleteImage}
              formatPhoneNumber={formatPhoneNumber}
              confirmDelete={confirmDeleteMobile}
              setConfirmDelete={setConfirmDeleteMobile}
            />
          </GridItem>

          <GridItem xs={12} >
            <BillingInfoMobile
              globalEdit={globalEdit}
              billingEdit={billingEdit}
              setBillingEdit={setBillingEdit}
              cardNumber={cardNumber}
              setCardNumber={setCardNumber}
              expDate={expDate}
              setExpDate={setExpDate}
              nameOnCard={nameOnCard}
              setNameOnCard={setNameOnCard}
              billingAddress={billingAddress}
              setBillingAddress={setBillingAddress}
              asDefault={asDefault}
              setAsDefault={setAsDefault}
              newPayment={newPayment}
              setNewPayment={setNewPayment}
              typeOfPayment={typeOfPayment}
              setTypeOfPayment={setTypeOfPayment}
            />
          </GridItem>

        </GridContainer>
      </Hidden>


      <Hidden smDown implementation="css">
        <GridContainer >
          <GridItem xs={6} style={{ display: "flex" }}>
            <Typography variant="h2" className={classes.headerTitle}>
              {firstNameState + " " + lastNameState + "  "}
            </Typography>


            {billingEdit && !globalEdit ?
              null
              :
              !globalEdit ?
                <Button
                  onClick={() => setGlobalEdit(true)}
                  className={classes.editButton}
                >
                  <Edit style={{ marginRight: "10px", marginTop: "-5px" }} /> Edit
                </Button>
                :
                <div>
                  <Button
                    className={classes.editButton}
                    style={{ background: "#1A405F", width: "128px" }}
                    onClick={updateUserInfo}
                  >
                    <SaveIcon style={{ marginRight: "10px", marginTop: "-5px" }} /> Save
                  </Button>
                  <Button
                    onClick={() => setGlobalEdit(false)}
                    className={classes.editButton}
                    style={{ background: "#E2A400", width: "143px" }}
                  >
                    <Delete style={{ marginRight: "10px", marginTop: "-5px" }} /> Cancel
                  </Button>
                </div>
            }
          </GridItem>

          <GridItem xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>

            <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: "-10px" }}>
              <Typography variant="h2" className={classes.headerDesc}>
                Account Number
              </Typography>
              <Typography variant="h2" className={classes.headerDesc} style={{ fontWeight: 500, fontSize: "18px", lineHeight: "18px", marginTop: "10px" }}>
                {user.merchantId}
              </Typography>
            </div>

          </GridItem>
        </GridContainer>

        <div
          style={{
            backgroundImage: "url(" + getBgImage() + ")",
            width: "100vw",
            marginLeft: "-30px",
            height: "130px",
            marginTop: "15px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#274b73",
            backgroundPhoto: "no-repeat",
            backgroundSize: "contain"
          }}
        >
          <GridContainer style={{ alignItems: "center" }}>
            <GridItem xs={4} style={{ textAlign: "center", marginTop: "10px" }}>
              <Typography variant="h2" className={classes.profileBannerText}>
                You have <span className={classes.profileBannerPts}> {user.approvedPts ? Math.trunc(user.approvedPts).toLocaleString() : 0} </span> Tuzo points now
                and <span className={classes.profileBannerPts}> {user.tuzoPoints ? (user.tuzoPoints).toLocaleString() : 0} </span> pending!
              </Typography>
            </GridItem>

            <GridItem xs={4} style={{ textAlign: "center" }}>
              <Typography variant="h2" className={classes.profileBannerText} style={{ textAlign: "left", lineHeight: "30px" }}>
                Based on your average monthly processing ({user.approvedPts ? Math.trunc(user.approvedPts).toLocaleString() : 0}), you’re on track to have:
              </Typography>
            </GridItem>

            <GridItem xs={4} style={{ display: "flex", justifyContent: "space-evenly", textAlign: "center" }}>

              <div>
                <Typography variant="h2" className={classes.profileBannerDurationText}>
                  1 Month:
                </Typography>
                <Typography variant="h2" className={classes.profileBannerDurationPts}>
                  {user.approvedPts ? Math.trunc(user.approvedPts).toLocaleString() : 0}
                </Typography>
              </div>

              <div>
                <Typography variant="h2" className={classes.profileBannerDurationText}>
                  1 Year:
                </Typography>
                <Typography variant="h2" className={classes.profileBannerDurationPts}>
                  {user.approvedPts ? Math.trunc(user.approvedPts * 12).toLocaleString() : 0}
                </Typography>
              </div>

              <div>
                <Typography variant="h2" className={classes.profileBannerDurationText}>
                  3 Years:
                </Typography>
                <Typography variant="h2" className={classes.profileBannerDurationPts}>
                  {user.approvedPts ? Math.trunc(user.approvedPts * 36).toLocaleString() : 0}
                </Typography>
              </div>
            </GridItem>

          </GridContainer>
        </div>

        <GridContainer >
          <GridItem xs={12} style={{ marginTop: "20px" }}>
            <UserInfo
              username={username}
              setUsername={setUsername}
              user={user}
              profilePic={profilePic}
              firstNameState={firstNameState}
              setFirstNameState={setFirstNameState}
              lastNameState={lastNameState}
              setLastNameState={setLastNameState}
              companyState={companyState}
              setCompanyState={setCompanyState}
              birthdayState={birthdayState}
              setBirthdayState={setBirthdayState}
              emailState={emailState}
              setEmailState={setEmailState}
              cellPhoneState={cellPhoneState}
              setCellPhoneState={setCellPhoneState}
              secondaryPhoneState={secondaryPhoneState}
              setSecondaryPhoneState={setSecondaryPhoneState}
              shippingAddressState={shippingAddressState}
              setShippingAddressState={setShippingAddressState}
              globalEdit={globalEdit}
              handleImageUpload={handleImageUpload}
              uploading={uploading}
              progress={progress}
              deleteImage={deleteImage}
              formatPhoneNumber={formatPhoneNumber}
              confirmDelete={confirmDelete}
              setConfirmDelete={setConfirmDelete}
            />
          </GridItem>

          <GridItem xs={12} >
            <BillingInfo
              globalEdit={globalEdit}
              billingEdit={billingEdit}
              setBillingEdit={setBillingEdit}
              cardNumber={cardNumber}
              setCardNumber={setCardNumber}
              expDate={expDate}
              setExpDate={setExpDate}
              nameOnCard={nameOnCard}
              setNameOnCard={setNameOnCard}
              billingAddress={billingAddress}
              setBillingAddress={setBillingAddress}
              asDefault={asDefault}
              setAsDefault={setAsDefault}
              newPayment={newPayment}
              setNewPayment={setNewPayment}
              typeOfPayment={typeOfPayment}
              setTypeOfPayment={setTypeOfPayment}
            />
          </GridItem>
        </GridContainer>
      </Hidden>
    </div>
  );
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, location) {
  return {
    usernameState: state.auth.user.username,
    authInfo: state.form.registration.steps.basicDetails.fields,
    userInfo: state.form.registration.steps.commonProfile
      ? state.form.registration.steps.commonProfile.fields
      : {},
    profilePic: state.auth.profilePic
      ? state.auth.profilePic
      : state.auth.user.profilePic
        ? state.auth.user.profilePic
        : false,
    uid: state.auth.user.uid,
    firstName: state.auth.firstName
      ? state.auth.firstName
      : state.auth.user.firstName,
    lastName: state.auth.lastName
      ? state.auth.lastName
      : state.auth.user.lastName,
    userType:
      state.auth.userType !== ""
        ? state.auth.userType
        : state.auth.user.userType,
    email: state.auth.email ? state.auth.email : state.auth.user.email,
    dateOfBirth: state.auth.dateOfBirth
      ? state.auth.dateOfBirth
      : state.auth.user.dateOfBirth,
    phoneNumber: state.auth.user && state.auth.user.phoneNumber,
    cellPhone: state.auth.cellPhone
      ? state.auth.cellPhone
      : state.auth.user.cellPhone,
    secondaryPhone: state.auth.secondaryPhone
      ? state.auth.secondaryPhone
      : state.auth.user.secondaryPhone,
    officePhone: state.auth.officePhone
      ? state.auth.officePhone
      : state.auth.user.officePhone,
    companyName: state.auth.companyName
      ? state.auth.companyName
      : state.auth.user.companyName,
    shippingAddress: state.auth.shippingAddress
      ? state.auth.shippingAddress
      : state.auth.user.shippingAddress,
    birthday: state.auth.birthday
      ? state.auth.birthday
      : state.auth.user.birthday,
    about: state.auth.about ? state.auth.about : state.auth.user.about,
    user: state.auth.user,
    userSet: state.auth.user.userSet,
    editState: location.history.location.state?.editState
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    edit: (key) =>
      dispatch(
        actionsForNavigation.openChild("Profile Editor", `/admin/user/editor`)
      ),
    updateUserData: (uid, newInfo) => dispatch(updateUserData(uid, newInfo)),
    updateUserPhoto: (id, document) => dispatch(updateUserPhoto(id, document)),
    deleteUserPhoto: (id) => dispatch(deleteUserPhoto(id)),
    updateUserLogo: (id, document) => dispatch(updateUserLogo(id, document)),
    logout: () => dispatch(AuthActions.startLogout()),
  });
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(UserProfile);
