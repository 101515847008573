import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { compose } from "redux";

// core components
import { Button, Hidden, InputAdornment, TextField, Typography } from "@material-ui/core";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import Autocomplete from "@material-ui/lab/Autocomplete";

// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/assignRewardStyle';
import withStyles from '@material-ui/core/styles/withStyles';

export function ItemInfo(props) {
    const {
        classes,
        data
    } = props;

    return (
        <div>
            <GridContainer>
                <GridItem xs={12}>
                        <GridContainer>
                            <GridItem xs={5}>
                                <img
                                    className={classes.cardListImg}
                                    src={data.mainImage}
                                    alt="img here"
                                />
                            </GridItem>

                            <GridItem xs={7}>
                                <Typography className={classes.rewardDetailName}>
                                    {data.rewardName}
                                </Typography>

                                <Typography className={classes.rewardDetailDescription}>
                                    {data.topDescription.length > 8 ? <span dangerouslySetInnerHTML={{ __html: data.topDescription }}></span> : "No Description"}
                                </Typography>
                            </GridItem>

                            <GridItem xs={5}>
                                <div style={{ display: "flex", marginTop: "15px" }}>
                                    <Typography className={classes.detailDesc}>
                                        SKU: <span style={{ fontWeight: 400 }}>
                                            {data.sku}
                                        </span>
                                    </Typography>

                                    <Typography className={classes.detailDesc}>
                                        Category:
                                        <span style={{ fontWeight: 400 }}>
                                            {data.categories.electronics === true ? " Electronics"
                                                :
                                                data.categories.sportsOutdoors === true ? " Sports/Outdoors"
                                                    :
                                                    data.categories.giftCards === true ? " Gift Cards"
                                                        :
                                                        data.categories.luxuryGoods === true ? " Luxury Goods"
                                                            :
                                                            data.categories.homeKitchen === true ? " Home/Kitchen"
                                                                :
                                                                " Travel"}
                                        </span>
                                    </Typography>
                                </div>
                            </GridItem>
                        </GridContainer>
                </GridItem>

            </GridContainer>
        </div>
    )
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(ItemInfo);