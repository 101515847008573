import React, { useEffect } from "react";
import { useState } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { db } from "firebase/client";
import { addProduct } from "store/actions/cart";
import Card from "components/Card/Card";
import Quantity from "./components/Quantity";
import Datetime from "react-datetime";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { sendTripRequest } from "firebase/client";
import DialogLoader from "components/Loader/DialogLoader";

// mui
import {
  Button,
  Dialog,
  FormControl,
  Hidden,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Loader from "components/Loader/Loader";

// stylings
import Style from "assets/jss/material-dashboard-pro-react/views/rewardsStyle";
import withStyles from "@material-ui/core/styles/withStyles";

// icons
import { ReactComponent as Add } from "assets/img/tuzo/icons/AddCircle.svg";
import { Cancel, CheckCircle } from "@material-ui/icons";
import { ReactComponent as CalendarIcon } from "assets/img/tuzo/icons/calendarIconWhite.svg";

const quantityArr = ["1", "2", "3", "4", "5"];
const denominationArr = [100];

const yesterday = moment().subtract(1, "day");
const disablePastDt = (current) => {
  return current.isAfter(yesterday);
};

export function RewardsDetail(props) {
  const { classes, history, rewardData, addProduct, cart, user } = props;

  const docId = window.location.pathname.split("/")[3];
  const [denomination, setDenomination] = useState(denominationArr[0]);
  const [giftCardType, setGiftCardType] = useState(
    rewardData && rewardData.giftCardType ? rewardData.giftCardType[0] : ""
  );
  const [quantity, setQuantity] = useState("1");
  const [price, setPrice] = useState("");
  const [data, setData] = useState(rewardData ? rewardData : false);
  const [address, setAddress] = useState("")
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
  const [adults, setAdults] = useState("")
  const [children, setChildren] = useState("")
  const [contactModal, setContactModal] = useState(false)
  const [city, setCity] = useState("")
  const [state, setState] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [tripData, setTripData] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [errors, setErrors] = useState({
    origin: false,
    fromDate: false,
    toDate: false,
    adults: false
  })

  const updateQuantity = (quantity, rewardsInfo) => {
    let toNumQty = Number(quantity);
    let toNumDenom = rewardsInfo.giftCardType ? Number(denomination) : rewardsInfo.salePrice;
    const newTotal = toNumQty * toNumDenom;
    // let newData = { ...rewardsInfo };
    // newData["salePrice"] = newTotal;
    // newData["salePricePoints"] = newTotal * 100;
    // newData["quantity"] = quantity;

    setPrice(newTotal);
    // setData(newData);
  };

  const checkedAdded = () => {
    let newObj = { ...data };
    newObj["added"] = true;
    setData(newObj);
  };

  // useEffect(() => {
  //     if (data) {
  //         if (newObj.topDescription) {
  //             let str = newObj.topDescription
  //             let values = str.split(";")
  //             if (str.includes("font-family")) {
  //                 for (let i = 0; i < values.length; i++) {
  //                     if (values[i].includes("font-family")) {
  //                         values.splice(i, 1)
  //                     }
  //                 }
  //                 let stringValues = values.toString()
  //                 let newLine = stringValues.replace(/&nbsp,/g, ' ')
  //                 newObj.topDescription = newLine
  //             }
  //         }
  //         setData(newObj)
  //     }
  // }, [])

  const reset = () => {
    setContactModal(false)
    setAddress("")
    setFromDate("")
    setToDate("")
    setAdults("")
    setChildren("")
    setErrors({
      origin: false,
      fromDate: false,
      toDate: false,
      adults: false
    })
  }

  const handleSelect = (address, placeholder) => {
    geocodeByAddress(address).then((results) => {
      const lat = results[0].geometry.location.lat();
      const lng = results[0].geometry.location.lng();
      if (placeholder === "Going from...") {
        const addressResults = results[0];
        setAddress(addressResults.formatted_address);
        addressResults.address_components.forEach((r) => {
          if (r.types.includes("locality")) {
            setCity(r.long_name);
          }
          if (r.types.includes("administrative_area_level_1")) {
            setState(r.short_name);
          }
          if (r.types.includes("postal_code")) {
            setZipCode(r.short_name);
          }
        });

      }
    });
  };

  const submitRequest = async () => {
    if (!address || !fromDate || !adults) {
      let newErrors = {
        origin: false,
        fromDate: false,
        // toDate: false,
        adults: false
      }
      if (!address) {
        newErrors = { ...newErrors, origin: true }
      }
      if (!fromDate) {
        newErrors = { ...newErrors, fromDate: true }
      }
      if (!adults) {
        newErrors = { ...newErrors, adults: true }
      }
      setErrors({ ...newErrors })
    } else {
      setShowModal(true)
      let newObj = {
        user,
        tripData: data,
        address,
        fromDate,
        adults,
        children
      }
      await sendTripRequest(newObj).then(() => {
        setShowModal(false)
        setContactModal(false)
        setConfirmModal(true)
      })
    }

  }

  const updateDesc = (topStr) => {
    let str = topStr;
    let values = str.split(";");
    if (str.includes("font-family")) {
      for (let i = 0; i < values.length; i++) {
        if (values[i].includes("font-family")) {
          values.splice(i, 1);
        }
      }
      let stringValues = values.toString();
      let newLine = stringValues.replace(/&nbsp,/g, " ");

      return newLine;
    } else {
      return str;
    }
  };

  const getRewardData = () => {
    let newObj = {};
    db.collection("rewards-list-test")
      .doc(docId)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          snapshot = snapshot.data();
          newObj = snapshot;
          setData(snapshot);
        }
      })
      .then(() => {
        updateQuantity(quantity, newObj);
      });
  };

  useEffect(() => {
    if (data) {
      let newObj = { ...data };
      if (JSON.stringify(cart).includes(newObj.rewardId)) {
        newObj.added = true;
      }
      updateQuantity(quantity, data);
    } else {
      getRewardData();
    }
  }, [rewardData]);

  if (!data && !rewardData) return <Loader />;

  return (
    <div>
      <Hidden mdUp implementation="css">
        <div style={{ marginTop: "50px" }}>
          <GridContainer>
            <GridItem xs={12}>
              <Typography variant="h2" className={classes.title}>
                Reward Detail
              </Typography>
            </GridItem>

            <GridItem xs={12}>
              {data.categories.travel == true && user.userType.includes("Merchants") ?
                <>
                  <Tooltip
                    id="tooltip-top"
                    title="Contact us to book with one of our concierge."
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      onClick={() => setContactModal(true)}
                      className={classes.addToCartButtonDetailMobile}
                    >
                      <Add className={classes.icon} /> Contact Us
                    </Button>
                  </Tooltip>
                </>
                :
                data.categories.travel == false && user.userType.includes("Merchants") ? (
                  !data.added ? (
                    <Button
                      onClick={() => {
                        checkedAdded();
                        for (let i = 0; i < quantity; i++) {
                          addProduct(data);
                        }
                      }}
                      className={classes.addToCartButtonDetailMobile}
                    >
                      <Add className={classes.icon} /> Add to Cart
                    </Button>
                  ) : (
                    <Button
                      style={{ background: "#06667C" }}
                      className={classes.addToCartButtonDetailMobile}
                    >
                      {/* <Add className={classes.icon} />  */}
                      Added to Cart
                    </Button>
                  )
                ) : null}
            </GridItem>

            {data.categories.travel == false && user.userType.includes("Merchants") ? (
              <GridItem xs={12}>
                <Button
                  onClick={() => {
                    checkedAdded();
                    addProduct(data);
                    if (user.IsoAdminCompanyName) {
                      history.push(
                        `/iso-merchant/${user.IsoAdminCompanyName.replace(
                          / /g,
                          ""
                        ).toLowerCase()}/${user.companyName
                          .replace(/ /g, "")
                          .toLowerCase()}/cart`
                      );
                    } else {
                      history.push("/merchant/cart");
                    }
                  }}
                  className={classes.addToCartButtonDetailMobile}
                  style={{ background: "#56CA93" }}
                >
                  <Add className={classes.icon} /> Redeem Now
                </Button>
              </GridItem>
            ) : null}

            <GridItem xs={12}>
              <Card className={classes.detailCard}>
                <GridContainer>
                  <GridItem xs={5}>
                    <img
                      className={classes.cardListImg}
                      src={data.mainImage}
                      alt="img here"
                    />
                  </GridItem>

                  <GridItem xs={12}>
                    <Typography className={classes.rewardDetailName}>
                      {data.rewardName}
                    </Typography>

                    <Typography className={classes.rewardDetailDescription}>
                      {data.topDescription.length > 8 ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: updateDesc(data.topDescription),
                          }}
                        ></span>
                      ) : (
                        "No Description"
                      )}
                    </Typography>
                  </GridItem>

                  <GridItem xs={12}>
                    <div style={{ display: "flex", marginTop: "15px" }}>
                      <Typography className={classes.detailDesc}>
                        SKU: <span style={{ fontWeight: 400 }}>{data.sku}</span>
                      </Typography>

                      <Typography className={classes.detailDesc}>
                        Category:
                        <span style={{ fontWeight: 400 }}>
                          {data.categories.electronics === true
                            ? " Electronics"
                            : data.categories.sportsOutdoors === true
                              ? " Sports/Outdoors"
                              : data.categories.giftCards === true
                                ? " Gift Cards"
                                : data.categories.luxuryGoods === true
                                  ? " Luxury Goods"
                                  : data.categories.homeKitchen === true
                                    ? " Home/Kitchen"
                                    : " Travel"}
                        </span>
                      </Typography>
                    </div>
                  </GridItem>

                  {user.userType.includes("Merchants") ? (
                    <GridItem xs={12}>
                      {data.giftCardType ? (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            className={classes.rewardDetailDescriptionDropdown}
                          >
                            Denomination
                          </Typography>
                          <Select
                            style={{ width: "100%" }}
                            className={classes.selectMenuSortDetailRewardsPage}
                            value={denomination}
                            disableUnderline
                            classes={{
                              root: classes.selectText,
                            }}
                            onChange={(e) => {
                              setDenomination(e.target.value);
                            }}
                          >
                            {denominationArr.map((o, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  value={o}
                                  classes={{
                                    root: classes.selectMenuItem,
                                  }}
                                >
                                  {o}
                                </MenuItem>
                              );
                            })}
                          </Select>

                          <Typography
                            className={classes.rewardDetailDescriptionDropdown}
                          >
                            Type
                          </Typography>

                          <Select
                            style={{ width: "100%" }}
                            className={classes.selectMenuSortDetailRewardsPage}
                            value={giftCardType}
                            disableUnderline
                            classes={{
                              root: classes.selectText,
                            }}
                            onChange={(e) => {
                              setGiftCardType(e.target.value);
                            }}
                          >
                            {data.giftCardType.map((o, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  value={o}
                                  classes={{
                                    root: classes.selectMenuItem,
                                  }}
                                >
                                  {o}
                                </MenuItem>
                              );
                            })}
                          </Select>

                          <Typography
                            className={classes.rewardDetailDescriptionDropdown}
                          >
                            Quantity
                          </Typography>
                          <Select
                            style={{ width: "100%" }}
                            className={classes.selectMenuSortDetailRewardsPage}
                            value={quantity}
                            disableUnderline
                            classes={{
                              root: classes.selectText,
                            }}
                            onChange={(e) => {
                              setQuantity(e.target.value);
                              updateQuantity(e.target.value, data);
                            }}
                          >
                            {quantityArr.map((o, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  value={o}
                                  classes={{
                                    root: classes.selectMenuItem,
                                  }}
                                >
                                  {o}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      ) : (
                        <div style={{ display: "flex" }}>
                          <Select
                            className={classes.selectMenuSortDetailRewardsPage}
                            value={quantity}
                            disableUnderline
                            classes={{
                              root: classes.selectText,
                            }}
                            onChange={(e) => {
                              setQuantity(e.target.value);
                              updateQuantity(e.target.value, data);
                            }}
                          >
                            {quantityArr.map((o, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  value={o}
                                  classes={{
                                    root: classes.selectMenuItem,
                                  }}
                                >
                                  {o}
                                </MenuItem>
                              );
                            })}
                          </Select>

                          <Typography
                            className={classes.rewardDetailDescriptionDropdown}
                          >
                            Quantity
                          </Typography>
                        </div>
                      )}
                    </GridItem>
                  ) : null}
                </GridContainer>
              </Card>
            </GridItem>

            <GridItem xs={12} style={{ marginTop: "-30px" }}>
              <Quantity
                user={user}
                quantity={quantity}
                price={price}
                giftCardType={giftCardType}
                addProduct={addProduct}
                data={data}
                denomination={denomination}
                checkAdded={checkedAdded}
                setContactModal={setContactModal}
              />
            </GridItem>
          </GridContainer>
        </div>
      </Hidden>

      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h2" className={classes.title}>
              Reward Detail
            </Typography>


            {data.categories.travel == true && user.userType.includes("Merchants") ?
              <>
                <Tooltip
                  id="tooltip-top"
                  title="Contact us to book with one of our concierge."
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    onClick={() => setContactModal(true)}
                    className={classes.addToCartButtonDetail}
                  >
                    <Add className={classes.icon} /> Contact Us
                  </Button>
                </Tooltip>
              </>
              :
              data.categories.travel == false && user.userType.includes("Merchants") ? (
                !data.added ? (
                  <Button
                    onClick={() => {
                      checkedAdded();
                      for (let i = 0; i < quantity; i++) {
                        addProduct(data);
                      }
                    }}
                    className={classes.addToCartButtonDetail}
                  >
                    <Add className={classes.icon} /> Add to Cart
                  </Button>
                ) : (
                  <Button
                    style={{ background: "#06667C" }}
                    className={classes.addToCartButtonDetail}
                  >
                    {/* <Add className={classes.icon} />  */}
                    Added to Cart
                  </Button>
                )
              ) : null}

            {data.categories.travel == false && user.userType.includes("Merchants") ? (
              <Button
                onClick={() => {
                  checkedAdded();
                  addProduct(data);
                  if (user.IsoAdminCompanyName) {
                    history.push(
                      `/iso-merchant/${user.IsoAdminCompanyName.replace(
                        / /g,
                        ""
                      ).toLowerCase()}/${user.companyName
                        .replace(/ /g, "")
                        .toLowerCase()}/cart`
                    );
                  } else {
                    history.push("/merchant/cart");
                  }
                }}
                className={classes.addToCartButtonDetail}
                style={{ background: "#56CA93" }}
              >
                <Add className={classes.icon} /> Redeem Now
              </Button>
            ) : null}
          </GridItem>

          <GridItem xs={8}>
            <Card className={classes.detailCard}>
              <GridContainer>
                <GridItem xs={5}>
                  <img
                    className={classes.cardListImg}
                    src={data.mainImage}
                    alt="img here"
                  />
                </GridItem>

                <GridItem xs={7}>
                  <Typography className={classes.rewardDetailName}>
                    {data.rewardName}
                  </Typography>

                  <Typography className={classes.rewardDetailDescription}>
                    {data.topDescription.length > 8 ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: updateDesc(data.topDescription),
                        }}
                      ></span>
                    ) : (
                      "No Description"
                    )}
                  </Typography>
                </GridItem>

                <GridItem xs={5}>
                  <div style={{ display: "flex", marginTop: "15px" }}>
                    <Typography className={classes.detailDesc}>
                      SKU: <span style={{ fontWeight: 400 }}>{data.sku}</span>
                    </Typography>

                    <Typography className={classes.detailDesc}>
                      Category:
                      <span style={{ fontWeight: 400 }}>
                        {data.categories.electronics === true
                          ? " Electronics"
                          : data.categories.sportsOutdoors === true
                            ? " Sports/Outdoors"
                            : data.categories.giftCards === true
                              ? " Gift Cards"
                              : data.categories.luxuryGoods === true
                                ? " Luxury Goods"
                                : data.categories.homeKitchen === true
                                  ? " Home/Kitchen"
                                  : " Travel"}
                      </span>
                    </Typography>
                  </div>
                </GridItem>

                {user.userType.includes("Merchants") ? (
                  <GridItem xs={7}>
                    {data.giftCardType ? (
                      <div style={{ display: "flex" }}>
                        <Select
                          className={classes.selectMenuSortDetailRewardsPage}
                          value={denomination}
                          disableUnderline
                          classes={{
                            root: classes.selectText,
                          }}
                          onChange={(e) => {
                            setDenomination(e.target.value);
                          }}
                        >
                          {denominationArr.map((o, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={o}
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                              >
                                {o}
                              </MenuItem>
                            );
                          })}
                        </Select>

                        <Typography
                          className={classes.rewardDetailDescriptionDropdown}
                        >
                          Denomination
                        </Typography>

                        <Select
                          style={{ marginLeft: "10px" }}
                          className={classes.selectMenuSortDetailRewardsPage}
                          value={giftCardType}
                          disableUnderline
                          classes={{
                            root: classes.selectText,
                          }}
                          onChange={(e) => {
                            setGiftCardType(e.target.value);
                          }}
                        >
                          {data.giftCardType.map((o, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={o}
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                              >
                                {o}
                              </MenuItem>
                            );
                          })}
                        </Select>

                        <Typography
                          className={classes.rewardDetailDescriptionDropdown}
                        >
                          Type
                        </Typography>

                        <Select
                          style={{ marginLeft: "10px", width: "50px" }}
                          className={classes.selectMenuSortDetailRewardsPage}
                          value={quantity}
                          disableUnderline
                          classes={{
                            root: classes.selectText,
                          }}
                          onChange={(e) => {
                            setQuantity(e.target.value);
                            updateQuantity(e.target.value, data);
                          }}
                        >
                          {quantityArr.map((o, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={o}
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                              >
                                {o}
                              </MenuItem>
                            );
                          })}
                        </Select>

                        <Typography
                          className={classes.rewardDetailDescriptionDropdown}
                          style={{ marginLeft: "10px" }}
                        >
                          Quantity
                        </Typography>
                      </div>
                    ) : (
                      <div style={{ display: "flex" }}>
                        <Select
                          style={{ width: "50px" }}
                          className={classes.selectMenuSortDetailRewardsPage}
                          value={quantity}
                          disableUnderline
                          classes={{
                            root: classes.selectText,
                          }}
                          onChange={(e) => {
                            setQuantity(e.target.value);
                            updateQuantity(e.target.value, data);
                          }}
                        >
                          {quantityArr.map((o, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={o}
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                              >
                                {o}
                              </MenuItem>
                            );
                          })}
                        </Select>

                        <Typography
                          className={classes.rewardDetailDescriptionDropdown}
                          style={{ marginLeft: "10px" }}
                        >
                          Quantity
                        </Typography>
                      </div>
                    )}
                  </GridItem>
                ) : null}
              </GridContainer>
            </Card>
          </GridItem>

          <GridItem xs={4}>
            <Quantity
              user={user}
              quantity={quantity}
              price={price}
              giftCardType={giftCardType}
              addProduct={addProduct}
              data={data}
              denomination={denomination}
              checkAdded={checkedAdded}
              setContactModal={setContactModal}
            />
          </GridItem>
        </GridContainer>
      </Hidden>

      <Dialog
        classes={{
          paper: classes.contactModal
        }}
        open={contactModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <Card className={classes.modalCard}>
          <GridContainer>
            <GridItem xs={12}>
              <h5 className={classes.cardHeaderModal}>Contact Concierge About: {data.rewardName}</h5>
            </GridItem>

            <GridItem xs={5} className={classes.gridItemMarginTop}>
              <Typography
                style={errors.origin ? { marginTop: "30px" } : {}}
                className={classes.modalCardDesc}>
                Origin
              </Typography>
            </GridItem>

            <GridItem xs={7} className={classes.gridItemMarginTop}>
              <PlacesAutocomplete
                value={address}
                onChange={(e) => {
                  setAddress(e)
                }}
                onSelect={(e) => handleSelect(e, "Going from...")}
                googleCallbackName={"initOne"}
                searchOptions={{
                  // componentRestrictions: { country: "us" },
                }}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <FormControl style={{ width: "100%" }}>
                      {errors.origin ? (
                        <span className={classes.errText}> Please fill out field</span>
                      ) : null}
                      <TextField
                        // className={classes.modalTextField}
                        value={address}
                        InputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                          disableUnderline: true,
                          classes: {
                            root: classes.modalTextField,
                            adornedEnd: classes.adournedEnd,
                            input: classes.input
                          },
                        }}
                        {...getInputProps({
                          placeholder: "Going from...",
                          className: "location-search-input",
                        })}
                      />
                      <div className="autocomplete-dropdown-container" style={{ zIndex: "99" }}>
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                              padding: "10px"
                            }
                            : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                              padding: "10px",
                            };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </FormControl>
                  </div>
                )}
              </PlacesAutocomplete>
            </GridItem>

            <GridItem xs={5} className={classes.gridItemMarginTop}>
              <Typography
                style={errors.fromDate ? { marginTop: "30px" } : {}}
                className={classes.modalCardDesc}>
                From Date
              </Typography>
            </GridItem>

            <GridItem xs={7} className={classes.gridItemMarginTop}>
              <div>
                {errors.fromDate ? (
                  <span className={classes.errText}>Please fill out the field</span>
                ) : null}
                <Datetime
                  isValidDate={disablePastDt}
                  className='travel'
                  selected={fromDate}
                  dateFormat="MM/DD/YY"
                  onChange={(date, e) => {
                    setFromDate(date.format("MM/DD/YY"));
                  }}
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside
                  renderInput={(props, openCalendar, closeCalendar) => (
                    <div onClick={openCalendar}>
                      <TextField
                        style={{ marginTop: "5px" }}
                        className={classes.datetime}
                        value={fromDate}
                        variant="outlined"
                        InputProps={{
                          classes: {
                            adornedEnd: classes.adournedEnd,
                            input: classes.input
                          },
                          endAdornment: (
                            <InputAdornment
                              className={classes.adournedEnd}
                              position="end"
                            >
                              <Button className={classes.datetimeButton}>
                                <CalendarIcon
                                  style={{ height: "18px", marginTop: "-2px" }}
                                  onClick={openCalendar}
                                />
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  )}
                />
              </div>
            </GridItem>

            <GridItem xs={5} className={classes.gridItemMarginTop}>
              <Typography
                style={errors.adults ? { marginTop: "30px" } : {}}
                className={classes.modalCardDesc}>
                Adult(s)
              </Typography>
            </GridItem>

            <GridItem xs={7} className={classes.gridItemMarginTop}>
              <div>
                {errors.adults ? (
                  <span className={classes.errText}> Please fill out the field</span>
                ) : null}
                <TextField
                  placeholder="0"
                  type="number"
                  className={classes.modalTextField}
                  value={adults}
                  onChange={(e) => setAdults(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input
                    },
                    inputProps: {
                      min: 0
                    }
                  }}
                />
              </div>
            </GridItem>

            <GridItem xs={5} className={classes.gridItemMarginTop}>
              <Typography
                // style={errors.adult ? { marginTop: "30px" } : {}}
                className={classes.modalCardDesc}>
                Children(s)
              </Typography>
            </GridItem>

            <GridItem xs={7} className={classes.gridItemMarginTop}>
              <TextField
                placeholder="0"
                type="number"
                className={classes.modalTextField}
                value={children}
                onChange={(e) => setChildren(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: classes.input
                  },
                  inputProps: {
                    min: 0
                  }
                }}
              />
            </GridItem>

            <GridItem xs={12} style={{ display: "flex", justifyContent: "space-evenly", marginTop: "15px" }}>
              <Button
                onClick={() => reset()}
                className={classes.modalButton}>
                <Cancel style={{ marginRight: "10px", marginTop: "-5px" }} /> Cancel
              </Button>
              <Button
                onClick={() => submitRequest()}
                className={classes.modalButton}
                style={{ background: "#1A405F", }}>
                <CheckCircle style={{ marginRight: "10px", marginTop: "-5px" }} /> Send
              </Button>
            </GridItem>
          </GridContainer>
        </Card>
      </Dialog>

      <Dialog
        classes={{
          paper: classes.contactModal
        }}
        open={confirmModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <Card className={classes.modalCard}>
          <GridContainer>
            <GridItem xs={12}>
              <h5 className={classes.cardHeaderModal} style={{ fontSize: "22px", display: "flex", justifyContent: "center" }}>Request Sent!</h5>
            </GridItem>
            <GridItem xs={12}>
              <Typography className={classes.modalCardDesc} style={{ fontSize: "18px" }}>
                Thanks for requesting a trip package! A travel concierge will reach out shortly to assist in your booking.
              </Typography>
            </GridItem>
            <GridItem xs={12} style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
              <Button
                onClick={() => setConfirmModal(false)}
                className={classes.modalButton}
                style={{ background: "#1A405F", }}>
                <Cancel style={{ marginRight: "10px", marginTop: "-5px" }} /> Close
              </Button>
            </GridItem>
          </GridContainer>

        </Card>
      </Dialog>
      <DialogLoader showModal={showModal} />
    </div>
  );
}

function mapStateToProps(state, location) {
  return {
    rewardData: location.history.location.state?.rewardData,
    cart: state.cart.cart,
    user: state.auth.user,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    addProduct: (product) => dispatch(addProduct(product)),
  });
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
  )(RewardsDetail)
);
