import React, { useEffect, useState } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import ItemInfo from "./components/ItemInfo";
import Summary from "./components/Summary";
import { db, firebase } from "firebase/client";
import MerchantInfo from "./components/MerchantInfo";
import Loader from "components/Loader/Loader";
import moment from "moment"
import { sendMerchantOrderUpdateEmail, sendMerchantOrderVoidEmail } from "firebase/client";

// styling
import Style from "assets/jss/material-dashboard-pro-react/views/cartStyle";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import { Button, Dialog, Hidden, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// icons
import { ReactComponent as Edit } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as Trash } from "assets/img/tuzo/icons/TrashIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";
import { ReactComponent as Delete } from "assets/img/tuzo/icons/Delete.svg";
import { ConfirmModal } from "./components/ConfirmModal";
import DialogLoader from "components/Loader/DialogLoader";
import VoidModal from "./components/VoidModal";


export function AdminOrdersDetails(props){
    const { 
        classes,
        history,
        editState 
    } = props

    const orderId = window.location.pathname.split("/")[3];
    const [totalAmount, setTotalAmount] = useState(0);
    const [copyArr, setCopyArr] = useState([]);
    const [orderData, setOrderData] = useState(false);
    const [userData, setUserData] = useState(false)
    const [paymentType, setPaymentType] = useState("");
    const [edit, setEdit] = useState(editState? editState : false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [returnPoints, setReturnPoints] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [orderStatus, setOrderStatus] = useState("")
    const [voidModal, setVoidModal] = useState(false)

    const numberFormatterDecimals = new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    
    const numberFormatter = new Intl.NumberFormat("en-US", {});

    useEffect(() => {
        if (orderData) {
            const total = orderData.reduce(
            (partialSum, a) => partialSum + Number(a.salePrice),
            0
            );
            setTotalAmount(total);
        }
    }, [orderData]);

    const deleteProduct = (index) => {
        let newOrder = JSON.parse(JSON.stringify(orderData));
        let splicedProduct = newOrder.splice(index,1)
        setReturnPoints((prev) => prev+splicedProduct[0].salePricePoints)
        setOrderData(newOrder)
    }
    
    const confirmOrderUpdate = async () => {
        setShowModal(true)
        let emailData = JSON.parse(JSON.stringify(orderData));
        for (let i = 0; i < emailData.length; i++) {
            emailData[i].orderStatus = "Pending"
            emailData[i].firstName = userData.firstName
            emailData[i].lastName = userData.lastName
            emailData[i].uid = userData.uid
            emailData[i].salePricePoints = numberFormatter.format(emailData[i].salePricePoints)
        }
        await db.collection('orders').doc(orderId).update({
            orderData: orderData
        })
        await db.collection('users').doc(userData.uid).collection('orders').doc(orderId).update({
            orderData: orderData
        })
        await db.collection('users').doc(userData.uid).update({
            approvedPts: Number(userData.approvedPts) + Number(returnPoints)
        })
        .then( async () => {
            let merchantObj = {
                orderData: emailData,
                paymentType: "Points",
                orderId: orderId,
                orderDate: moment.unix((firebase.firestore.Timestamp.fromDate(new Date())).seconds).format("MM/DD/YYYY"),
                totalPts:  numberFormatter.format(totalAmount * 100),
                shippingAddress: userData.shippingAddress,
                headerImg: userData.branding !== "Default" && userData.logo? userData.logo : "https://firebasestorage.googleapis.com/v0/b/tuzo-379415.appspot.com/o/EmailImg%2FEmailHeader.png?alt=media&token=f03aab60-d827-408c-9679-01688999f5dd",
                footerImg: userData.branding === "Priority" && userData.logo? userData.logo : "https://firebasestorage.googleapis.com/v0/b/tuzo-379415.appspot.com/o/EmailImg%2FtuzoHorizontal.png?alt=media&token=f161b013-c40c-49f2-b6d7-468beae5a488",
                partnerImage: userData.branding !== false && userData.logo? userData.logo : "https://firebasestorage.googleapis.com/v0/b/tuzo-379415.appspot.com/o/EmailImg%2FbaseImage.png?alt=media&token=69efb148-4c25-4a49-b2b3-328d314e3ae5",
                isoName: userData.IsoAdminCompanyName ? userData.IsoAdminCompanyName : userData.companyName ? userData.companyName : "Your ISO company",
                domain: window.location.host === "www.wpirewards.com" || userData.companyName === "Wholesale Payments" || userData.IsoAdminCompanyName === "Wholesale Payments" ? "notifications@wpirewards.com" : "notifications@tuzorewards.com",
                emailName: window.location.host === "www.wpirewards.com" || userData.companyName === "Wholesale Payments" || userData.IsoAdminCompanyName === "Wholesale Payments" ? "WPI" : "Tuzo",
                supportEmail: window.location.host === "www.wpirewards.com" || userData.companyName === "Wholesale Payments" || userData.IsoAdminCompanyName === "Wholesale Payments" ? "info@wpirewards.com" : "orders@tuzorewards.com",
                email: userData.email,
                emailTitle: window.location.host === "www.wpirewards.com" || userData.companyName === "Wholesale Payments" || userData.IsoAdminCompanyName === "Wholesale Payments" ? "WPI Rewards" : "Tuzo Rewards",
            }
            await sendMerchantOrderUpdateEmail(merchantObj)
        })
        .then(() => {
            setShowModal(false)
            window.location.reload()
        })
    }

    const voidOrderUpdate = async () => {
        setShowModal(true)
        let emailData = JSON.parse(JSON.stringify(orderData));
        for (let i = 0; i < emailData.length; i++) {
            emailData[i].orderStatus = "Canceled"
            emailData[i].firstName = userData.firstName
            emailData[i].lastName = userData.lastName
            emailData[i].uid = userData.uid
            emailData[i].salePricePoints = numberFormatter.format(emailData[i].salePricePoints)
        }
        await db.collection('orders').doc(orderId).update({
            orderStatus: "Canceled"
        })
        await db.collection('users').doc(userData.uid).collection('orders').doc(orderId).update({
            orderStatus: "Canceled"
        })
        await db.collection('users').doc(userData.uid).update({
            approvedPts: Number(userData.approvedPts) + Number(totalAmount * 100)
        })
        .then( async () => {
            let merchantObj = {
                orderData: emailData,
                paymentType: "Points",
                orderId: orderId,
                orderDate: moment.unix((firebase.firestore.Timestamp.fromDate(new Date())).seconds).format("MM/DD/YYYY"),
                totalPts:  numberFormatter.format(totalAmount * 100),
                shippingAddress: userData.shippingAddress,
                headerImg: userData.branding !== "Default" && userData.logo? userData.logo : "https://firebasestorage.googleapis.com/v0/b/tuzo-379415.appspot.com/o/EmailImg%2FEmailHeader.png?alt=media&token=f03aab60-d827-408c-9679-01688999f5dd",
                footerImg: userData.branding === "Priority" && userData.logo? userData.logo : "https://firebasestorage.googleapis.com/v0/b/tuzo-379415.appspot.com/o/EmailImg%2FtuzoHorizontal.png?alt=media&token=f161b013-c40c-49f2-b6d7-468beae5a488",
                partnerImage: userData.branding !== false && userData.logo? userData.logo : "https://firebasestorage.googleapis.com/v0/b/tuzo-379415.appspot.com/o/EmailImg%2FbaseImage.png?alt=media&token=69efb148-4c25-4a49-b2b3-328d314e3ae5",
                isoName: userData.IsoAdminCompanyName ? userData.IsoAdminCompanyName : userData.companyName ? userData.companyName : "Your ISO company",
                domain: window.location.host === "www.wpirewards.com" || userData.companyName === "Wholesale Payments" || userData.IsoAdminCompanyName === "Wholesale Payments" ? "notifications@wpirewards.com" : "notifications@tuzorewards.com",
                emailName: window.location.host === "www.wpirewards.com" || userData.companyName === "Wholesale Payments" || userData.IsoAdminCompanyName === "Wholesale Payments" ? "WPI" : "Tuzo",
                supportEmail: window.location.host === "www.wpirewards.com" || userData.companyName === "Wholesale Payments" || userData.IsoAdminCompanyName === "Wholesale Payments" ? "info@wpirewards.com" : "orders@tuzorewards.com",
                email: userData.email,
                emailTitle: window.location.host === "www.wpirewards.com" || userData.companyName === "Wholesale Payments" || userData.IsoAdminCompanyName === "Wholesale Payments" ? "WPI Rewards" : "Tuzo Rewards",
            }
            await sendMerchantOrderVoidEmail(merchantObj)
        })
        .then(() => {
            setShowModal(false)
            window.location.reload()
        })
    }

    const getData = () => {
        let userId = ""
        return db
        .collection("orders")
        .doc(orderId)
        .get()
        .then((snapshots) => {
            setOrderStatus(snapshots.data().orderStatus)
            setPaymentType(snapshots.data().paymentType);
            setOrderData(snapshots.data().orderData);
            setCopyArr(snapshots.data().orderData)
            userId = snapshots.data().orderData[0].uid
        })
        .then(() => {
            db
            .collection('users')
            .doc(userId)
            .get()
            .then((doc) => {
                setUserData(doc.data())
            })
        })
    };

    useEffect(() => {
        if (!orderData) {
          getData();
        }
      }, []);

    if (!orderData || !paymentType || !userData || orderStatus === "") {
        return <Loader />;
    }

    console.log("checking data", orderStatus)

    return (
        <div>
            <Hidden mdUp implementation="css">
                <GridContainer style={{marginTop:"60px"}}>
                    <GridItem 
                        xs={12}
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <Typography
                            className={classes.cartTitle}
                            style={{ fontSize: "24px" }}
                        >
                            Order # {orderId} {orderStatus.includes("Canceled")? "(Voided)" : null}
                        </Typography>
                    </GridItem>

                    <GridItem xs={12}>
                        <Button
                            onClick={() => {
                                
                            }}
                            className={classes.checkoutButton}
                            style={{ width: "65%" }}
                        >
                            Edit
                        </Button>
                    </GridItem>

                    <GridItem xs={12}>
                        <ItemInfo
                            totalAmount={totalAmount}
                            numberFormatterDecimals={numberFormatterDecimals}
                            history={history}
                            orderData={orderData}
                            edit={edit}
                            deleteProduct={deleteProduct}
                        />
                    </GridItem>
            
                    <GridItem xs={12} style={{ marginTop: "-30px" }}>
                        <Summary
                            history={history}
                            orderData={orderData}
                            totalAmount={totalAmount}
                            numberFormatterDecimals={numberFormatterDecimals}
                            numberFormatter={numberFormatter}
                            paymentType={paymentType}
                        />
                    </GridItem>
                </GridContainer>
            </Hidden>

            <Hidden smDown implementation="css">
                <GridContainer>
                    <GridItem xs={12} style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                            variant="h2"
                            className={classes.cartTitle}
                            style={{ fontSize: "24px" }}
                        >
                            Order # {orderId} {orderStatus.includes("Canceled")? "(VOIDED)" : null}
                        </Typography>
        
                    {!edit?
                    <>
                        <Button
                            onClick={() => {
                                setEdit(true)
                            }}
                            className={classes.checkoutButton}
                            style={{ width: "200px", marginTop: "0px", marginLeft: "20px", background: "#FECD2C" }}
                        >
                            <Edit style={{ marginRight:"10px" }} /> Edit
                        </Button>

                    {orderStatus.includes("Canceled")?
                            null
                        :
                        <>
                            <Button
                            onClick={() => {
                                setVoidModal(true)
                            }}
                            className={classes.checkoutButton}
                            style={{ width: "200px", marginTop: "0px", marginLeft: "20px", background: "#56CA93" }}
                        >
                                <Trash style={{ marginRight:"10px" }} /> Void
                            </Button>
                        </>
                }
                    </>
                    :
                    <>
                        <Button
                            onClick={() => {
                                setConfirmModal(true)
                            }}
                            className={classes.checkoutButton}
                            style={{ width: "200px", marginTop: "0px", marginLeft: "20px" }}
                        >
                            <SaveIcon style={{ marginRight:"10px" }} /> Save
                        </Button>

                        <Button
                            onClick={() => {
                                setOrderData(copyArr)
                                setReturnPoints(0)
                                setEdit(false)
                            }}
                            className={classes.checkoutButton}
                            style={{ width: "200px", marginTop: "0px", marginLeft: "20px", background: "#E2A400" }}
                        >
                            <Delete style={{ marginRight:"10px" }} /> Cancel
                        </Button>
                    </>
                    }
                    </GridItem>
            
                    <GridItem xs={8}>
                        <ItemInfo
                            totalAmount={totalAmount}
                            numberFormatterDecimals={numberFormatterDecimals}
                            history={history}
                            orderData={orderData}
                            edit={edit}
                            deleteProduct={deleteProduct}
                        />
                    </GridItem>
            
                    <GridItem xs={4}>
                        <Summary
                            history={history}
                            orderData={orderData}
                            totalAmount={totalAmount}
                            numberFormatterDecimals={numberFormatterDecimals}
                            numberFormatter={numberFormatter}
                            paymentType={paymentType}
                            userData={userData}
                        />

                        <MerchantInfo
                            userData={userData}
                            history={history}
                        />
                    </GridItem>
                </GridContainer>
            </Hidden>
            <DialogLoader showModal={showModal} />
            
            <Dialog
                className={classes.requestModal}
                open={confirmModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <ConfirmModal
                    classes={classes}
                    setConfirmModal={setConfirmModal}
                    Trash={Trash}
                    confirmOrderUpdate={confirmOrderUpdate}
                />
            </Dialog>

            <Dialog
                className={classes.requestModal}
                open={voidModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <VoidModal
                    classes={classes}
                    setConfirmModal={setVoidModal}
                    Trash={Trash}
                    voidOrderUpdate={voidOrderUpdate}
                />
            </Dialog>
            
        </div>
    )
}

function mapStateToProps(state, location) {
  return {
    editState: location.history.location.state?.editState
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({});
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(AdminOrdersDetails);