import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { compose } from "redux";

// core components
import { Button, Typography } from "@material-ui/core";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';

// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/assignRewardStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import { CheckCircle } from '@material-ui/icons';

export function Summary(props) {
    const {
        classes,
        data,
        numberFormatter,
        userData,
        redeemPoints,
        total,
        ptsRemaining,
        option,
        dollarAmt,
        setDollarAmt,
        numberFormatterDecimals,
        handleEmail
    } = props

    return (
        <div>
            <Card className={classes.card}>
                <GridContainer>
                    <GridItem xs={12}>
                        <Typography className={classes.totalCost}>
                            Payment Summary
                        </Typography>
                    </GridItem>
                </GridContainer>
                <div className={classes.borderDiv}>
                    <GridContainer>
                        <GridItem xs={8}>
                            <Typography className={classes.itemSummary}>
                                {data ? data.rewardName : "Reward"}
                            </Typography>
                        </GridItem>

                        <GridItem xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Typography className={classes.totalCostPts}>
                                {data ? numberFormatter.format(data.salePrice * 100) : 0} points
                            </Typography>
                        </GridItem>
                    </GridContainer>
                </div>
                <div className={classes.borderDiv}>
                    <GridContainer>
                        <GridItem xs={8}>
                            <Typography className={classes.tripTotal}>
                                Total
                            </Typography>

                        </GridItem>

                        <GridItem xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Typography className={classes.totalCostPts} style={{ whiteSpace: "nowrap" }}>
                                {data ? numberFormatter.format(data.salePrice * 100) : 0} points
                            </Typography>
                        </GridItem>
                    </GridContainer>
                </div>

                <GridContainer style={{ marginTop: "25px" }}>
                    <GridItem xs={4}>
                        <Typography className={classes.tripTotal}>
                            User will pay
                        </Typography>
                    </GridItem>

                    <GridItem xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Typography className={classes.totalCostPts}>
                            {data && option === "Only" ? numberFormatter.format(total) + " points"
                                :
                                data && option === "Partial" ? numberFormatter.format(total - redeemPoints) + " + $" + numberFormatterDecimals.format(dollarAmt)
                                    :
                                    data && option === "None" ? "$ " + numberFormatterDecimals.format(dollarAmt)

                                        : "0 points"}
                        </Typography>
                    </GridItem>


                    <GridItem xs={6} style={{ marginTop: "15px" }}>
                        <Typography className={classes.tripTotal}>
                            Remaining Points
                        </Typography>
                    </GridItem>

                    <GridItem xs={6} style={{ display: "flex", justifyContent: "flex-end", marginTop: "15px" }}>
                        <Typography className={classes.remainingPts}>
                            {data && userData ? (Math.trunc(ptsRemaining)).toLocaleString() : "N/A"} points
                        </Typography>
                    </GridItem>

                    <GridItem xs={12}>
                        <Button
                            className={classes.sendEmailBtn}
                            style={{ width: "100%" }}
                            disabled={!data}
                            onClick={() => handleEmail()}
                        >
                            <CheckCircle style={{ marginRight: "10px" }} />  Send Authorization Email
                        </Button>
                    </GridItem>
                </GridContainer>
            </Card>
        </div>
    )
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Summary);