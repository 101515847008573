import { firebase } from "@firebase/app";
import "@firebase/firestore";
import "@firebase/storage";
import dotenv from 'dotenv';
import "firebase/functions"

// ensure config is read
dotenv.config();
// Initialize Firebase from .env files
const projectId = process.env.REACT_APP_PROJECT_ID;

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: `${projectId}.firebaseapp.com`,
  projectId: projectId,
  storageBucket: `${projectId}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

firebase.initializeApp(config);
firebase.firestore().settings({});

const db = firebase.firestore();
const storage = firebase.storage();
const functions = firebase.functions();
// var sendCustomerNotificationEmail = functions.httpsCallable('sendCustomerNotificationEmail');
var sendTempPassword = functions.httpsCallable('sendTempPassword');
var setMerchantInfo = functions.httpsCallable('setMerchantInfo');
var sendCloudFunction = functions.httpsCallable('sendCloudFunction');
var sendIrisCloudFunction = functions.httpsCallable('sendIrisCloudFunction')
var sendMavCloudFunction = functions.httpsCallable('sendMavCloudFunction')
var sendTestIrisConnection = functions.httpsCallable('sendTestIrisConnection')
var sendTestAccessOneConnection = functions.httpsCallable('sendTestAccessOneConnection')
var sendTestMavConnection = functions.httpsCallable('sendTestMavConnection')
var sendSabreFunction = functions.httpsCallable('sendSabreFunction');
var sendSabreCarRental = functions.httpsCallable('sendSabreCarRental');
var sendSignUpLink = functions.httpsCallable('sendSignUpLink');
var sendSignUpLinkLive = functions.httpsCallable('sendSignUpLinkLive')
var sendAdminSignUpLinkLive = functions.httpsCallable('sendAdminSignUpLinkLive')
var sendIsoSignUpLinkLive = functions.httpsCallable('sendIsoSignUpLinkLive')
var sendIsoAdminSignUpLinkLive = functions.httpsCallable('sendIsoAdminSignUpLinkLive')
var sendRequest = functions.httpsCallable('sendRequest')
var sendIrisGetFunction = functions.httpsCallable('sendIrisGetFunction')
var sendReferral = functions.httpsCallable('sendReferral')
var sendAssignReward = functions.httpsCallable('sendAssignReward')
var sendMerchantOrderEmail = functions.httpsCallable('sendMerchantOrderEmail')
var sendAdminOrderEmail = functions.httpsCallable('sendAdminOrderEmail')
var sendMerchantOrderUpdateEmail = functions.httpsCallable('sendMerchantOrderUpdateEmail')
var sendMerchantOrderVoidEmail = functions.httpsCallable('sendMerchantOrderVoidEmail')
var sendAPIAutofill = functions.httpsCallable('sendAPIAutofill');
var userEmailUpdate = functions.httpsCallable('userEmailUpdate')
var sendTripRequest = functions.httpsCallable('sendTripRequest')
var sendTransferNotification = functions.httpsCallable('sendTransferNotification')
var sendOnboardNotification = functions.httpsCallable('sendOnboardNotification')
// var sendSMS = functions.httpsCallable('sendSMS');
// var tokenExchange = functions.httpsCallable('tokenExchange');

// var updateToken = functions.httpsCallable('update_token');
export {
  db,
  storage,
  firebase,
  functions,
  // sendCustomerNotificationEmail,
  sendCloudFunction,
  sendIrisCloudFunction,
  sendMavCloudFunction,
  sendSabreFunction,
  sendTestIrisConnection,
  sendTestAccessOneConnection,
  sendTestMavConnection,
  sendSabreCarRental,
  setMerchantInfo,
  sendTempPassword,
  sendSignUpLink,
  sendRequest,
  sendReferral,
  sendSignUpLinkLive,
  sendAdminSignUpLinkLive,
  sendIsoSignUpLinkLive,
  sendIsoAdminSignUpLinkLive,
  sendIrisGetFunction,
  sendAssignReward,
  sendMerchantOrderEmail,
  sendAdminOrderEmail,
  sendMerchantOrderUpdateEmail,
  sendMerchantOrderVoidEmail,
  sendAPIAutofill,
  userEmailUpdate,
  sendTripRequest,
  sendTransferNotification,
  sendOnboardNotification,
  // sendSMS,
  // tokenExchange,
  // updateToken,
  firebase as default
};