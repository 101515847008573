import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import compose from 'recompose/compose';

// styling
import { Button, Checkbox, FormControlLabel, withStyles } from "@material-ui/core";
import Style from "assets/jss/material-dashboard-pro-react/views/cartStyle";

// core components
import { Typography } from "@material-ui/core";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import { Info } from '@material-ui/icons';

export function SummaryPage(props) {
    const {
        classes,
        cart,
        totalAmount,
        numberFormatterDecimals,
        numberFormatter,
        agree,
        setAgree,
        redeemPoints,
        user,
        originalAmount,
        completeOrder
    } = props;

    const [check, setCheck] = useState(false)

    useEffect(() => {
        if(user.approvedPts && user.approvedPts >= (originalAmount * 100)){
            setCheck(true)
        }
    },[redeemPoints])

    return (
        <Card className={classes.card}>
            <GridContainer>
                <GridItem xs={12}>
                    <Typography className={classes.totalCost}>
                        Payment Summary
                    </Typography>
                </GridItem>
            </GridContainer>

            {cart.map((data, index) => (
                data.checked === true ?
                    <div className={classes.borderDiv} key={index}>
                        <GridContainer>
                            <GridItem xs={8}>
                                <Typography className={classes.itemSummary}>
                                    {data.rewardName}
                                </Typography>
                            </GridItem>

                            <GridItem xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Typography className={classes.itemPriceSummary}>
                                    {numberFormatter.format(data.salePrice * 100)}
                                </Typography>
                            </GridItem>
                        </GridContainer>
                    </div>
                    : null
            ))}

            <div className={classes.borderDiv}>
                <GridContainer>
                    <GridItem xs={8}>
                        <Typography className={classes.tripTotal}>
                            Total
                        </Typography>

                    </GridItem>

                    {/* <GridItem xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Typography className={classes.totalCost}>
                            ${numberFormatterDecimals.format(totalAmount)}
                        </Typography>
                    </GridItem>

                    <GridItem xs={8} /> */}

                    <GridItem xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Typography className={classes.totalCostPts} style={{ whiteSpace: "nowrap" }}>
                            {originalAmount ? numberFormatter.format(originalAmount * 100) : "0"} points
                        </Typography>
                    </GridItem>
                </GridContainer>
            </div>

            <GridContainer style={{ marginTop: "25px" }}>
                {/* {cart.length > 0 ?
                    <>
                        <GridItem xs={4}>
                            <Typography className={classes.tripTotal}>
                                You will pay
                            </Typography>
                        </GridItem>

                        <GridItem xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Typography className={classes.totalCost}>
                                {numberFormatter.format(redeemPoints)} points + ${numberFormatterDecimals.format(totalAmount)}
                            </Typography>
                        </GridItem>
                    </>
                    :
                    null} */}


                <GridItem xs={6} style={{ marginTop: "15px" }}>
                    <Typography className={classes.remainingPts}>
                        Remaining Points
                    </Typography>
                </GridItem>

                <GridItem xs={6} style={{ display: "flex", justifyContent: "flex-end", marginTop: "15px" }}>
                    <Typography className={classes.remainingPts}>
                        {user.approvedPts && user.approvedPts >= redeemPoints ? numberFormatter.format((Math.trunc(Number(user.approvedPts)) - redeemPoints)) : 0} Points
                    </Typography>
                </GridItem>

                {!agree ?
                    <GridItem xs={12} style={{ marginTop: "15px" }}>
                        <div className={classes.warningBorder}>
                            <Typography className={classes.warningText} style={{ color: "" }}>
                                <Info style={{ color: "#B3140C", marginBottom: "-5px", marginRight: "10px" }} />
                                Please confirm that you have read and understood our Policies.
                            </Typography>
                        </div>
                    </GridItem>
                    :
                    null
                }


                <GridItem xs={12} style={{ display: "flex", marginTop: "10px" }}>
                    <FormControlLabel
                        className={classes.checkboxes}
                        control={
                            <Checkbox
                                style={{ color: "#56CA93", marginTop: "-10px", marginRight: "-10px" }}
                                checked={agree}
                                onClick={() => setAgree(!agree)}
                            />
                        }
                    />
                    <Typography className={classes.remainingPts}>
                        I have read and agreed to the {" "}
                        <span
                            className={classes.cardPolicy}
                            style={{ fontWeight: 700 }}
                            onClick={() => {
                                window.open("https://www.tuzorewards.com/contact/terms-of-use/",
                                    '_blank',
                                    'noopener,noreferrer'
                                )
                            }}
                        >
                            Policies.
                        </span>
                    </Typography>
                </GridItem>

                {!check ?
                    <GridItem xs={12} style={{ marginTop: "15px" }}>
                        <div className={classes.warningBorder}>
                            <Typography className={classes.warningText} style={{ color: "" }}>
                                <Info style={{ color: "#B3140C", marginBottom: "-5px", marginRight: "10px" }} />
                                You do not you have enough points for the reward(s).
                            </Typography>
                        </div>
                    </GridItem>
                    :
                    null
                }
                <GridItem xs={12}>
                    <Button
                        onClick={() => completeOrder()}
                        disabled={!check || !agree? true : false}
                        className={classes.checkoutButton}>
                        Complete Order
                    </Button>
                </GridItem>
            </GridContainer>
        </Card>

    )
}

function mapStateToProps(state) {
    return {
        user: state.auth.user
    }
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(SummaryPage);

