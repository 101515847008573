import React, { useState, createRef, useEffect } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import actionsForNavigation from "store/actions/navigation";
import AuthActions from "store/actions/auth";
import { db, firebase, storage } from "firebase/client";
import MerchantInfo from "./components/MerchantInfo";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { Card, Dialog, MenuItem, Select, TextField, Typography } from "@material-ui/core";

import {
  updateUserData,
  updateUserPhoto,
  updateUserLogo,
} from "store/actions/auth.js";
import { geocodeByAddress } from "react-places-autocomplete";

// @material-ui/icons
import { Add, Close } from "@material-ui/icons";
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";
import { ReactComponent as Edit } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";
import { ReactComponent as Delete } from "assets/img/tuzo/icons/Delete.svg";

//styling
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";
import tuzoBg from "assets/img/tuzo/carouselBackground.png";
import { BsCheckCircleFill } from "react-icons/bs";
import Loader from "components/Loader/Loader";
import { sendIsoAdminSignUpLinkLive } from "firebase/client";
import { sendIrisCloudFunction } from "firebase/client";
import { sendMavCloudFunction } from "firebase/client";
import { sendCloudFunction } from "firebase/client";
import { sendIsoSignUpLinkLive } from "firebase/client";
import { ptsFormula } from "globalCalculationFunction/globalCalculationFunction";
import Gateway from "./components/Gateway";
import WishListTable from "./components/WishListTable";
import DateRange from "./components/DateRange";
import Filter from "./components/Filter";
import Table from "./components/Table";
import AddModal from "./AddModal";
import DialogLoader from "components/Loader/DialogLoader";
import { sendOnboardNotification } from "firebase/client";
const modalTypeArr = ["Merchants", "Staff"];

const ascArr = ["Asc", "Desc"];

const statusArr = ["Total Points", "Name", "Joined"];

const headersISO = [
  { label: "Points", key: "pts" },
  { label: "Name", key: "name" },
  { label: "Company Name", key: "companyName" },
  { label: "Email", key: "email" },
  { label: "Status", key: "status" },
];

const intervalArr = ["60", "90", "120", "180"];

const entriesArr = [10, 15, 20, 50, 100];
export function UserProfile(props) {
  const { classes, history, logo, user, userCompany, userAutoApprove, editState, userLock } = props;
  const merchantId = window.location.pathname.split("/")[4];
  const [merchantData, setMerchantData] = useState(false);
  const [wishListData, setWishListData] = useState(false);
  const [rewardsData, setRewardsData] = useState(false);
  const itemsPerPage = 2;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPage);
  const [edit, setEdit] = useState(editState ? editState : false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [contactFirstName, setContactFirstName] = useState("")
  const [contactLastName, setContactLastName] = useState("")
  const [cellPhone, setCellPhone] = useState("")
  const [secondaryPhone, setSecondaryPhone] = useState("")
  const [shippingAddress, setShippingAddress] = useState("")
  const [userBasisPts, setUserBasisPts] = useState("")
  const [signUpBonus, setSignUpBonus] = useState("")
  const [autoApprove, setAutoApprove] = useState(userAutoApprove ? userAutoApprove : false)
  const [interval, setInterval] = useState("60")
  const [emailSent, setEmailSent] = useState(false)
  const [gateway, setGateway] = useState("Fiserv AccessOne")
  const [apiInfo, setApiInfo] = useState([
    {
      apiType: "Fiserv AccessOne",
      apiKey: "",
      apiSecret: "",
      status: "Unused",
      id: 0
    },
    {
      apiType: "IRIS",
      apiKey: "",
      apiSecret: "",
      status: "Unused",
      id: 1
    },
    {
      apiType: "Maverick",
      apiKey: "",
      apiSecret: "",
      status: "Unused",
      id: 2
    },

  ])
  const [showModal, setShowModal] = useState(false)
  const [docFile, setDocfile] = useState([]);
  const [profilePic, setProfilePic] = useState("");
  const [demoAcc, setDemoAcc] = useState(false);
  const EmailValidation = require("emailvalid");
  const ev = new EmailValidation({ allowFreemail: true, allowDisposable: true, whitelist: ['att.net', 'ymail.com'] });
  const [modalType, setModalType] = useState("Merchants");
  const [addModal, setAddModal] = useState(false);
  const [modalUserBasisPts, setModalUserBasisPts] = useState("");
  const [modalSignUpBonus, setModalSignUpBonus] = useState("");
  const [modalMerchantId, setModalMerchantId] = useState("");
  const [modalUsername, setModalUsername] = useState("");
  const [modalFirstName, setModalFirstName] = useState("");
  const [modalLastName, setModalLastName] = useState("");
  const [modalEmail, setModalEmail] = useState("");
  const [modalCellPhone, setModalCellPhone] = useState("");
  const [modalSecondaryPhone, setModalSecondaryPhone] = useState("");
  const [modalAddressState, setModalAddressState] = useState("");
  const [modalCity, setModalCity] = useState("");
  const [modalState, setModalState] = useState("");
  const [modalZipCode, setModalZipCode] = useState("");
  const [modalCompanyName, setModalCompanyName] = useState("");
  const [modalEin, setModalEin] = useState("");
  const [modalNotes, setModalNotes] = useState("");
  const [modalProcessorName, setModalProcessorName] = useState(
    "Fiserv AccessOne"
  );
  const [modalProcessorEmail, setModalProcessorEmail] = useState("");
  const [modalApiUserName, setModalApiUsername] = useState("");
  const [modalApiPassword, setModalApiPassword] = useState("");
  const [accountId, setAccountId] = useState("");
  const [modalProcessorPhone, setModalProcessorPhone] = useState("");
  const [modalprocessorMid, setModalProcessorMid] = useState("");
  const [modalErrors, setModalErrors] = useState({
    points: false,
    processor: false,
    dba: false,
    email: false,
    emailTypo: false,
    id: false,
    username: false,
    firstName: false,
    lastName: false,
    apiUsername: false,
    apiPassword: false,
    accountId: false,
  });

  const [merchantDataArr, setMerchantDataArr] = useState(false);
  const [copyArr, setCopyArr] = useState(false);
  const [userTypeArrFilter, setUserTypeArrFilter] = useState([]);
  const [statusDropdown, setStatusDropdown] = useState("Total Points");
  const [ascDropdown, setAscDropdown] = useState("Asc");
  const [searchString, setSearchString] = useState("");
  const [userIndex, setUserIndex] = useState(0)
  const [userItemsPerPage, setUserItemsPerPage] = useState(10);
  const [userEndIndex, setUserEndIndex] = useState(userItemsPerPage)
  const [userPageNumber, setUserPageNumber] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isoAdminReportData, setIsoAdminReportData] = useState(false);
  const csvLinkRef = React.createRef();
  const [emptyData, setEmptyData] = useState(false);

  const resetPageFilterISO = () => {
    setUserIndex(0);
    setUserEndIndex(itemsPerPage);
    setUserPageNumber(0);
  };


  const resetState = () => {
    setModalUserBasisPts("");
    setModalSignUpBonus("");
    setModalMerchantId("");
    setModalUsername("");
    setModalFirstName("");
    setModalLastName("");
    setModalEmail("");
    setModalCellPhone("");
    setModalSecondaryPhone("");
    setModalAddressState("");
    setModalCity("");
    setModalState("");
    setModalZipCode("");
    setModalCompanyName("");
    setModalEin("");
    setModalProcessorPhone("");
    setModalProcessorEmail("");
    setModalProcessorMid("");
    setModalNotes("");
    setModalApiUsername("");
    setModalApiPassword("");
    setAccountId("");
  };

  const resetEmptyData = () => {
    setEmptyData(false);
    setSearchString("");
    setMerchantDataArr(copyArr);
  };

  const resetPageFilter = (filterArr) => {
    setIndex(0);
    setEndIndex(itemsPerPage);
    setPageNumber(0);
  };

  const handleUserTypeFilter = (event) => {
    let userArr = [];
    const {
      target: { value },
    } = event;
    setUserTypeArrFilter(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    if (value.length === 0) {
      setMerchantDataArr(copyArr);
    } else {
      copyArr.map((data) => {
        if (
          data.userType.includes("Merchants") &&
          value.includes("Merchants")
        ) {
          userArr.push(data);
        } else {
          if (value.includes(data.userType)) {
            userArr.push(data);
          }
        }
      });
      if (ascDropdown === "Asc") {
        userArr.sort(function (a, b) {
          if (a.userType < b.userType) {
            return -1;
          }
          if (a.userType > b.userType) {
            return 1;
          }
          return 0;
        });
        resetPageFilterISO();
        setMerchantDataArr(userArr);
      }
      if (ascDropdown === "Desc") {
        userArr.sort(function (a, b) {
          if (a[0].userType > b[0].userType) {
            return -1;
          }
          if (a[0].userType < b[0].userType) {
            return 1;
          }
          return 0;
        });
        resetPageFilterISO();
        setMerchantDataArr(userArr);
      }
    }
  };

  const handleSearch = () => {
    if (searchString) {
      const filtered = copyArr.filter((f) => {
        if (
          f.firstName.toLowerCase().includes(searchString.toLowerCase()) ||
          f.lastName.toLowerCase().includes(searchString.toLowerCase()) ||
          f.companyName.toLowerCase().includes(searchString.toLowerCase()) ||
          f.email.toLowerCase().includes(searchString.toLowerCase())
        ) {
          return true;
        }
      });
      if (filtered.length === 0) {
        setEmptyData(true);
      }
      setMerchantDataArr(filtered);
    } else {
      setMerchantDataArr(copyArr);
    }
  };

  const handleFilter = (ascValue, sortValue) => {
    if (ascValue === "Asc") {
      if (sortValue === "Total Points") {
        const filtered = merchantDataArr.sort(
          (a, b) =>
            (a.tuzoPoints != null ? a.tuzoPoints : 0) -
            (b.tuzoPoints != null ? b.tuzoPoints : 0)
        );
        resetPageFilter();
      }
      if (sortValue === "Name") {
        const filtered = merchantDataArr.sort(function (a, b) {
          if (a.contact) {
            if (a.contact < b.contact) {
              return -1;
            }
            if (a.contact > b.contact) {
              return 1;
            }
            return 0;
          } else {
            if (a.lastName < b.lastName) {
              return -1;
            }
            if (a.lastName > b.lastName) {
              return 1;
            }
            return 0;
          }
        });
        resetPageFilter();
      }
      if (sortValue === "Joined") {
        const filtered = merchantDataArr.sort(function (a, b) {
          return (
            (a.timestamp != null ? a.timestamp.seconds : 0) -
            (b.timestamp != null ? b.timestamp.seconds : 0)
          );
        });
        resetPageFilter();
      }
    }
    if (ascValue === "Desc") {
      if (sortValue === "Total Points") {
        const filtered = merchantDataArr.sort(
          (a, b) =>
            (b.approvedPts != null ? b.approvedPts : 0) -
            (a.approvedPts != null ? a.approvedPts : 0)
        );
        resetPageFilter();
      }
      if (sortValue === "Name") {
        const filtered = merchantDataArr.sort(function (a, b) {
          if (a.contact) {
            if (a.contact > b.contact) {
              return -1;
            }
            if (a.contact < b.contact) {
              return 1;
            }
            return 0;
          } else {
            if (a.lastName > b.lastName) {
              return -1;
            }
            if (a.lastName < b.lastName) {
              return 1;
            }
            return 0;
          }
        });
        resetPageFilter();
      }
      if (sortValue === "Joined") {
        const filtered = merchantDataArr.sort(function (a, b) {
          return (
            (b.timestamp != null ? b.timestamp.seconds : 0) -
            (a.timestamp != null ? a.timestamp.seconds : 0)
          );
        });
        resetPageFilter();
      }
    }
  };

  const sendCustomerEmail = async (e) => {
    e.preventDefault();
    const emailCheck = ev.check(modalEmail);
    if (
      modalType === "Merchants" &&
      modalProcessorName.includes("Fiserv AccessOne") &&
      (!modalUserBasisPts ||
        !modalCompanyName ||
        !modalEmail ||
        !modalMerchantId ||
        !modalUsername ||
        !modalApiUserName ||
        !modalApiPassword | !modalFirstName ||
        !modalLastName ||
        emailCheck.valid === false)
    ) {
      let newErrs = {
        points: false,
        processor: false,
        dba: false,
        email: false,
        id: false,
        username: false,
        firstName: false,
        lastName: false,
        emailTypo: false,
        apiUsername: false,
        apiPassword: false,
      };
      if (!modalUserBasisPts) {
        newErrs = { ...newErrs, points: true };
      }
      if (!modalCompanyName) {
        newErrs = { ...newErrs, dba: true };
      }
      if (!modalEmail) {
        newErrs = { ...newErrs, email: true };
      }
      if (emailCheck.valid === false) {
        newErrs = { ...newErrs, emailTypo: true };
      }
      if (!modalMerchantId) {
        newErrs = { ...newErrs, id: true };
      }
      if (!modalUsername) {
        newErrs = { ...newErrs, username: true };
      }
      if (!modalApiUserName) {
        newErrs = { ...newErrs, apiUsername: true };
      }
      if (!modalApiPassword) {
        newErrs = { ...newErrs, apiPassword: true };
      }
      if (!modalFirstName) {
        newErrs = { ...newErrs, firstName: true };
      }
      if (!modalLastName) {
        newErrs = { ...newErrs, lastName: true };
      }
      setModalErrors({ ...newErrs });
    } else if (
      modalType === "Merchants" &&
      modalProcessorName.includes("IRIS") &&
      (!modalUserBasisPts ||
        !modalCompanyName ||
        !modalEmail ||
        !modalMerchantId ||
        !modalUsername ||
        !modalApiUserName ||
        !modalFirstName ||
        !modalLastName ||
        emailCheck.valid === false)
    ) {
      let newErrs = {
        points: false,
        processor: false,
        dba: false,
        email: false,
        id: false,
        username: false,
        firstName: false,
        lastName: false,
        emailTypo: false,
        apiUsername: false,
      };
      if (!modalUserBasisPts) {
        newErrs = { ...newErrs, points: true };
      }
      if (!modalCompanyName) {
        newErrs = { ...newErrs, dba: true };
      }
      if (!modalEmail) {
        newErrs = { ...newErrs, email: true };
      }
      if (emailCheck.valid === false) {
        newErrs = { ...newErrs, emailTypo: true };
      }
      if (!modalMerchantId) {
        newErrs = { ...newErrs, id: true };
      }
      if (!modalUsername) {
        newErrs = { ...newErrs, username: true };
      }
      if (!modalFirstName) {
        newErrs = { ...newErrs, firstName: true };
      }
      if (!modalLastName) {
        newErrs = { ...newErrs, lastName: true };
      }
      if (!modalApiUserName) {
        newErrs = { ...newErrs, apiUsername: true };
      }
      setModalErrors({ ...newErrs });
    } else if (
      modalType === "Merchants" &&
      modalProcessorName.includes("Maverick") &&
      (!modalUserBasisPts ||
        !modalCompanyName ||
        !modalEmail ||
        !modalMerchantId ||
        !modalUsername ||
        !modalApiUserName ||
        !accountId | !modalFirstName ||
        !modalLastName ||
        emailCheck.valid === false)
    ) {
      let newErrs = {
        points: false,
        processor: false,
        dba: false,
        email: false,
        id: false,
        username: false,
        firstName: false,
        lastName: false,
        emailTypo: false,
        apiUsername: false,
        accountId: false,
      };
      if (!modalUserBasisPts) {
        newErrs = { ...newErrs, points: true };
      }
      if (!modalCompanyName) {
        newErrs = { ...newErrs, dba: true };
      }
      if (!modalEmail) {
        newErrs = { ...newErrs, email: true };
      }
      if (emailCheck.valid === false) {
        newErrs = { ...newErrs, emailTypo: true };
      }
      if (!modalMerchantId) {
        newErrs = { ...newErrs, id: true };
      }
      if (!modalUsername) {
        newErrs = { ...newErrs, username: true };
      }
      if (!modalFirstName) {
        newErrs = { ...newErrs, firstName: true };
      }
      if (!modalLastName) {
        newErrs = { ...newErrs, lastName: true };
      }
      if (!modalApiUserName) {
        newErrs = { ...newErrs, apiUsername: true };
      }
      if (!accountId) {
        newErrs = { ...newErrs, accountId: true };
      }
      setModalErrors({ ...newErrs });
    } else if (
      modalType === "Staff" &&
      (!modalEmail ||
        !modalUsername ||
        !modalFirstName ||
        !modalLastName ||
        emailCheck.valid === false)
    ) {
      let newErrs = {
        email: false,
        username: false,
        firstName: false,
        lastName: false,
        emailTypo: false,
      };
      if (!modalEmail) {
        newErrs = { ...newErrs, email: true };
      }
      if (emailCheck.valid === false) {
        newErrs = { ...newErrs, emailTypo: true };
      }
      if (!modalUsername) {
        newErrs = { ...newErrs, username: true };
      }
      if (!modalFirstName) {
        newErrs = { ...newErrs, firstName: true };
      }
      if (!modalLastName) {
        newErrs = { ...newErrs, lastName: true };
      }
      setModalErrors({ ...newErrs });
    } else if (
      modalType === "Sub ISO" &&
      (!modalEmail ||
        !modalUsername ||
        !modalCompanyName ||
        !modalFirstName ||
        !modalLastName ||
        emailCheck.valid === false)
    ) {
      let newErrs = {
        email: false,
        username: false,
        firstName: false,
        lastName: false,
        emailTypo: false,
        dba: false,
      };
      if (!modalEmail) {
        newErrs = { ...newErrs, email: true };
      }
      if (emailCheck.valid === false) {
        newErrs = { ...newErrs, emailTypo: true };
      }
      if (!modalUsername) {
        newErrs = { ...newErrs, username: true };
      }
      if (!modalFirstName) {
        newErrs = { ...newErrs, firstName: true };
      }
      if (!modalLastName) {
        newErrs = { ...newErrs, lastName: true };
      }
      if (!modalCompanyName) {
        newErrs = { ...newErrs, dba: true };
      }
      setModalErrors({ ...newErrs });
    }
    else {
      setShowModal(true)
      if (modalType === "Merchants") {
        const docId = db.collection("users").doc().id;
        let tempData = {};
        if (merchantData.userType === "SubIso") {
          if (modalProcessorName.includes("Maverick")) {
            tempData = {
              userType: "IsoMerchants",
              username: modalUsername,
              firstName: modalFirstName,
              lastName: modalLastName,
              docFile: docFile,
              SubIsoCompanyName: merchantData.companyName,
              IsoAdminCompanyName: merchantData.IsoAdminCompanyName,
              contactUrl: merchantData.contactUrl ? merchantData.contactUrl : "",
              companyName: modalCompanyName,
              DBA: modalCompanyName,
              shippingAddress: modalAddressState,
              city: modalCity,
              state: modalState,
              zipCode: modalZipCode,
              EIN: modalEin,
              userBasisPts: Number(modalUserBasisPts),
              merchantId: modalMerchantId,
              email: modalEmail,
              id: docId,
              processor: modalProcessorName,
              processorMid: modalprocessorMid,
              processorPhone: modalProcessorPhone,
              processorEmail: modalProcessorEmail,
              notes: modalNotes,
              signUpBonus: modalSignUpBonus,
              logo: merchantData.logo ? merchantData.logo : "",
              emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
              apiInfo,
              apiUsername: modalApiUserName,
              apiPassword: modalApiPassword,
              apiUrl: merchantData.companyName.replace(/ /g, "").toLowerCase(),
              tuzoPoints: 0,
              approvedPts: 0,
              demo: demoAcc,
              accountId,
              status: "Pending",
            };
          } else {
            tempData = {
              userType: "IsoMerchants",
              username: modalUsername,
              firstName: modalFirstName,
              lastName: modalLastName,
              docFile: docFile,
              SubIsoCompanyName: merchantData.companyName,
              IsoAdminCompanyName: merchantData.IsoAdminCompanyName,
              contactUrl: merchantData.contactUrl ? merchantData.contactUrl : "",
              companyName: modalCompanyName,
              DBA: modalCompanyName,
              shippingAddress: modalAddressState,
              city: modalCity,
              state: modalState,
              zipCode: modalZipCode,
              EIN: modalEin,
              userBasisPts: Number(modalUserBasisPts),
              merchantId: modalMerchantId,
              email: modalEmail,
              id: docId,
              processor: modalProcessorName,
              processorMid: modalprocessorMid,
              processorPhone: modalProcessorPhone,
              processorEmail: modalProcessorEmail,
              notes: modalNotes,
              signUpBonus: modalSignUpBonus,
              logo: merchantData.logo ? merchantData.logo : "",
              emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
              apiInfo,
              apiUsername: modalApiUserName,
              apiPassword: modalApiPassword,
              apiUrl: merchantData.companyName.replace(/ /g, "").toLowerCase(),
              tuzoPoints: 0,
              approvedPts: 0,
              demo: demoAcc,
              status: "Pending",
            };
          }
        } else if (merchantData.userType === "Staff") {
          if (modalProcessorName.includes("Maverick")) {
            tempData = {
              userType: "IsoMerchants",
              username: modalUsername,
              firstName: modalFirstName,
              lastName: modalLastName,
              docFile: docFile,
              IsoStaffMember: merchantData.modalEmail,
              IsoAdminCompanyName: merchantData.IsoAdminCompanyName,
              contactUrl: merchantData.contactUrl ? merchantData.contactUrl : "",
              companyName: modalCompanyName,
              DBA: modalCompanyName,
              shippingAddress: modalAddressState,
              city: modalCity,
              state: modalState,
              zipCode: modalZipCode,
              EIN: modalEin,
              userBasisPts: Number(modalUserBasisPts),
              merchantId: modalMerchantId,
              email: modalEmail,
              id: docId,
              processor: modalProcessorName,
              processorMid: modalprocessorMid,
              processorPhone: modalProcessorPhone,
              processorEmail: modalProcessorEmail,
              notes: modalNotes,
              signUpBonus: modalSignUpBonus,
              logo: merchantData.logo ? merchantData.logo : "",
              emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
              apiInfo,
              apiUsername: modalApiUserName,
              apiPassword: modalApiPassword,
              apiUrl: merchantData.companyName.replace(/ /g, "").toLowerCase(),
              tuzoPoints: 0,
              approvedPts: 0,
              demo: demoAcc,
              accountId,
              status: "Pending",
            };
          } else {
            tempData = {
              userType: "IsoMerchants",
              username: modalUsername,
              firstName: modalFirstName,
              lastName: modalLastName,
              docFile: docFile,
              IsoStaffMember: merchantData.modalEmail,
              IsoAdminCompanyName: merchantData.IsoAdminCompanyName,
              contactUrl: merchantData.contactUrl ? merchantData.contactUrl : "",
              companyName: modalCompanyName,
              DBA: modalCompanyName,
              shippingAddress: modalAddressState,
              city: modalCity,
              state: modalState,
              zipCode: modalZipCode,
              EIN: modalEin,
              userBasisPts: Number(modalUserBasisPts),
              merchantId: modalMerchantId,
              email: modalEmail,
              id: docId,
              processor: modalProcessorName,
              processorMid: modalprocessorMid,
              processorPhone: modalProcessorPhone,
              processorEmail: modalProcessorEmail,
              notes: modalNotes,
              signUpBonus: modalSignUpBonus,
              logo: merchantData.logo ? merchantData.logo : "",
              emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
              apiInfo,
              apiUsername: modalApiUserName,
              apiPassword: modalApiPassword,
              apiUrl: merchantData.companyName.replace(/ /g, "").toLowerCase(),
              tuzoPoints: 0,
              approvedPts: 0,
              demo: demoAcc,
              status: "Pending",
            };
          }
        } else {
          if (modalProcessorName.includes("Maverick")) {
            tempData = {
              userType: "IsoMerchants",
              username: modalUsername,
              firstName: modalFirstName,
              lastName: modalLastName,
              docFile: docFile,
              IsoAdminCompanyName: merchantData.companyName,
              contactUrl: merchantData.contactUrl ? merchantData.contactUrl : "",
              companyName: modalCompanyName,
              DBA: modalCompanyName,
              shippingAddress: modalAddressState,
              city: modalCity,
              state: modalState,
              zipCode: modalZipCode,
              EIN: modalEin,
              userBasisPts: Number(modalUserBasisPts),
              merchantId: modalMerchantId,
              email: modalEmail,
              id: docId,
              processor: modalProcessorName,
              processorMid: modalprocessorMid,
              processorPhone: modalProcessorPhone,
              processorEmail: modalProcessorEmail,
              notes: modalNotes,
              signUpBonus: modalSignUpBonus,
              logo: merchantData.logo ? merchantData.logo : "",
              emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
              apiInfo,
              apiUsername: modalApiUserName,
              apiPassword: modalApiPassword,
              apiUrl: merchantData.companyName.replace(/ /g, "").toLowerCase(),
              tuzoPoints: 0,
              approvedPts: 0,
              demo: demoAcc,
              accountId,
              status: "Pending",
            };
          } else {
            tempData = {
              userType: "IsoMerchants",
              username: modalUsername,
              firstName: modalFirstName,
              lastName: modalLastName,
              docFile: docFile,
              IsoAdminCompanyName: merchantData.companyName,
              contactUrl: merchantData.contactUrl ? merchantData.contactUrl : "",
              companyName: modalCompanyName,
              DBA: modalCompanyName,
              shippingAddress: modalAddressState,
              city: modalCity,
              state: modalState,
              zipCode: modalZipCode,
              EIN: modalEin,
              userBasisPts: Number(modalUserBasisPts),
              merchantId: modalMerchantId,
              email: modalEmail,
              id: docId,
              processor: modalProcessorName,
              processorMid: modalprocessorMid,
              processorPhone: modalProcessorPhone,
              processorEmail: modalProcessorEmail,
              notes: modalNotes,
              signUpBonus: modalSignUpBonus,
              logo: merchantData.logo ? merchantData.logo : "",
              emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
              apiInfo,
              apiUsername: modalApiUserName,
              apiPassword: modalApiPassword,
              apiUrl: merchantData.companyName.replace(/ /g, "").toLowerCase(),
              tuzoPoints: 0,
              approvedPts: 0,
              demo: demoAcc,
              status: "Pending",
            };
          }
        }
        await db
          .collection("users")
          .doc(docId)
          .set(tempData)
          .then(async () => {
            let newObj = {
              email: modalEmail,
              firstName: modalFirstName,
              lastName: modalLastName,
              id: docId,
              headerImg: merchantData.branding !== "Default" && profilePic ? profilePic : "https://firebasestorage.googleapis.com/v0/b/tuzo-379415.appspot.com/o/EmailImg%2FEmailHeader.png?alt=media&token=f03aab60-d827-408c-9679-01688999f5dd",
              footerImg: merchantData.branding === "Priority" && profilePic ? profilePic : "https://firebasestorage.googleapis.com/v0/b/tuzo-379415.appspot.com/o/EmailImg%2FtuzoHorizontal.png?alt=media&token=f161b013-c40c-49f2-b6d7-468beae5a488",
              domain: window.location.host === "www.wpirewards.com" || merchantData.companyName === "Wholesale Payments" ? "notifications@wpirewards.com" : "notifications@tuzorewards.com",
              emailTitle: window.location.host === "www.wpirewards.com" || merchantData.companyName === "Wholesale Payments" ? "WPI Rewards" : "Tuzo Rewards",
              emailName: window.location.host === "www.wpirewards.com" || merchantData.companyName === "Wholesale Payments" ? "WPI" : "Tuzo",
              partnerImage: merchantData.branding !== false && merchantData.partnerImage? merchantData.partnerImage : merchantData.branding !== false && merchantData.logo? merchantData.logo :"https://firebasestorage.googleapis.com/v0/b/tuzo-379415.appspot.com/o/EmailImg%2FbaseImage.png?alt=media&token=69efb148-4c25-4a49-b2b3-328d314e3ae5",
              isoName: merchantData.IsoAdminCompanyName ? merchantData.IsoAdminCompanyName : merchantData.companyName ? merchantData.companyName : "Your ISO company",
              linkUrl: window.location.host === "www.wpirewards.com" || merchantData.companyName === "Wholesale Payments" ? `https://www.wpirewards.com/auth/iso-merchant-register/${docId}` : `https://www.tuzo.app/auth/iso-merchant-register/${docId}`,
              supportEmail: window.location.host === "www.wpirewards.com" || merchantData.companyName === "Wholesale Payments" ? "info@wpirewards.com" : "info@tuzorewards.com",
              supportPhone: window.location.host === "www.wpirewards.com" || merchantData.companyName === "Wholesale Payments" ? "Support Phone" : "929-367-8896",
            }
            let adminEmailObj = {
              email: modalEmail,
              firstName: modalFirstName,
              lastName: modalLastName,
              companyName: modalCompanyName,
              isoName: merchantData.companyName,
              merchantId: modalMerchantId,
            }
            await sendOnboardNotification(adminEmailObj)
            await sendIsoSignUpLinkLive(newObj)
          })
          .then(async () => {
            let idObj = {
              merchantId: modalMerchantId,
              apiUsername: modalApiUserName,
              apiPassword: modalApiPassword,
              apiUrl: merchantData.companyName.replace(/ /g, "").toLowerCase(),
              accountId: accountId,
            };
            if (modalProcessorName.includes("IRIS")) {
              await sendIrisCloudFunction(idObj);
            } else if (modalProcessorName.includes("Maverick")) {
              await sendMavCloudFunction(idObj);
            } else {
              await sendCloudFunction(idObj);
            }
          })
          .then(() => {
            window.location.reload();
          });
      } else {
        const docId = db.collection("temp-user").doc().id;
        let tempData = {};
        if (modalType === "Staff") {
          if (merchantData.userType === "SubIso") {
            tempData = {
              username: modalUsername,
              firstName: modalFirstName,
              lastName: modalLastName,
              SubIsoCompanyName: merchantData.companyName,
              IsoAdminCompanyName: merchantData.IsoAdminCompanyName,
              contactUrl: merchantData.contactUrl ? merchantData.contactUrl : "",
              companyName: merchantData.companyName,
              userBasisPts: Number(modalUserBasisPts),
              email: modalEmail,
              id: docId,
              processor: modalProcessorName,
              processorMid: modalprocessorMid,
              processorPhone: modalProcessorPhone,
              processorEmail: modalProcessorEmail,
              notes: modalNotes,
              logo: merchantData.logo ? merchantData.logo : "",
              emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
              userType: "Staff",
              demo: demoAcc,
              apiInfo,
              status: "Pending",
            };
          } else if (merchantData.userType === "Staff") {
            tempData = {
              username: modalUsername,
              firstName: modalFirstName,
              lastName: modalLastName,
              IsoStaffMember: merchantData.modalEmail,
              IsoAdminCompanyName: merchantData.IsoAdminCompanyName,
              contactUrl: merchantData.contactUrl ? merchantData.contactUrl : "",
              companyName: merchantData.companyName,
              userBasisPts: Number(modalUserBasisPts),
              email: modalEmail,
              id: docId,
              processor: modalProcessorName,
              processorMid: modalprocessorMid,
              processorPhone: modalProcessorPhone,
              processorEmail: modalProcessorEmail,
              notes: modalNotes,
              logo: merchantData.logo ? merchantData.logo : "",
              emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
              userType: "Staff",
              demo: demoAcc,
              apiInfo,
              status: "Pending",
            };
          } else {
            tempData = {
              username: modalUsername,
              firstName: modalFirstName,
              lastName: modalLastName,
              IsoAdminCompanyName: merchantData.companyName,
              contactUrl: merchantData.contactUrl ? merchantData.contactUrl : "",
              companyName: merchantData.companyName,
              userBasisPts: Number(modalUserBasisPts),
              email: modalEmail,
              id: docId,
              processor: modalProcessorName,
              processorMid: modalprocessorMid,
              processorPhone: modalProcessorPhone,
              processorEmail: modalProcessorEmail,
              notes: modalNotes,
              logo: merchantData.logo ? merchantData.logo : "",
              emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
              userType: "Staff",
              demo: demoAcc,
              apiInfo,
              status: "Pending",
            };
          }
        } else {
          if (merchantData.userType === "SubIso") {
            tempData = {
              username: modalUsername,
              firstName: modalFirstName,
              lastName: modalLastName,
              SubIsoCompanyName: merchantData.companyName,
              IsoAdminCompanyName: merchantData.IsoAdminCompanyName,
              contactUrl: merchantData.contactUrl ? merchantData.contactUrl : "",
              companyName: modalCompanyName,
              userBasisPts: Number(modalUserBasisPts),
              email: modalEmail,
              id: docId,
              processor: modalProcessorName,
              processorMid: modalprocessorMid,
              processorPhone: modalProcessorPhone,
              processorEmail: modalProcessorEmail,
              notes: modalNotes,
              logo: merchantData.logo ? merchantData.logo : "",
              emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
              userType: "SubIso",
              demo: demoAcc,
              apiInfo,
              status: "Pending",
            };
          } else if (merchantData.userType === "Staff") {
            tempData = {
              username: modalUsername,
              firstName: modalFirstName,
              lastName: modalLastName,
              IsoStaffMember: merchantData.modalEmail,
              IsoAdminCompanyName: merchantData.IsoAdminCompanyName,
              contactUrl: merchantData.contactUrl ? merchantData.contactUrl : "",
              companyName: modalCompanyName,
              userBasisPts: Number(modalUserBasisPts),
              email: modalEmail,
              id: docId,
              processor: modalProcessorName,
              processorMid: modalprocessorMid,
              processorPhone: modalProcessorPhone,
              processorEmail: modalProcessorEmail,
              notes: modalNotes,
              logo: merchantData.logo ? merchantData.logo : "",
              emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
              userType: "SubIso",
              demo: demoAcc,
              apiInfo,
              status: "Pending",
            };
          } else {
            tempData = {
              username: modalUsername,
              firstName: modalFirstName,
              lastName: modalLastName,
              IsoAdminCompanyName: merchantData.companyName,
              contactUrl: merchantData.contactUrl ? merchantData.contactUrl : "",
              companyName: modalCompanyName,
              userBasisPts: Number(modalUserBasisPts),
              email: modalEmail,
              id: docId,
              processor: modalProcessorName,
              processorMid: modalprocessorMid,
              processorPhone: modalProcessorPhone,
              processorEmail: modalProcessorEmail,
              notes: modalNotes,
              logo: merchantData.logo ? merchantData.logo : "",
              emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
              userType: "SubIso",
              demo: demoAcc,
              apiInfo,
              status: "Pending",
            };
          }
        }
        await db
          .collection("temp-user")
          .doc(docId)
          .set(tempData)
          .then(async () => {
            await sendIsoAdminSignUpLinkLive(tempData);
          })
          .then(() => {
            window.location.reload();
          });
      }
    }
  };

  const handleSelect = (address, placeholder) => {
    geocodeByAddress(address).then((results) => {
      const lat = results[0].geometry.location.lat();
      const lng = results[0].geometry.location.lng();
      if (placeholder === "Search your pick up address..") {
      }
      if (placeholder === "Search the address..") {
        const addressResults = results[0];
        addressResults.address_components.forEach((r) => {
          if (r.types.includes("locality")) {
            setModalCity(r.long_name);
          }
          if (r.types.includes("administrative_area_level_1")) {
            setModalState(r.short_name);
          }
          if (r.types.includes("postal_code")) {
            setModalZipCode(r.short_name);
          }
        });
        setModalAddressState(addressResults.formatted_address);
      }
    });
  };

  const getIsoAdminReport = () => {
    let arr = [];
    let pendArr = [];
    let combineArr = [];
    let docArr = [];
    let filtered = [];

    return db
      .collection("users")
      .where("SubIsoCompanyName", "==", merchantData.companyName)
      .get()
      .then((snapshots) => {
        if (!snapshots.empty) {
          snapshots.forEach((s) => {
            s = s.data();
            arr.push(s);
          });
          // setData(arr)
        } else {
          // setData([])
        }
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].merchantId) {
            db.collection("merchants-live")
              .where("merchantNumber", "==", arr[i].merchantId)
              .get()
              .then((snapshots) => {
                if (!snapshots.empty) {
                  snapshots.docs.forEach((doc) => {
                    db.collection("merchants-live")
                      .doc(doc.data().docId)
                      .collection("batch-summary")
                      .get()
                      .then((subSnapshot) => {
                        if (!subSnapshot.empty) {
                          subSnapshot.docs.forEach((subDoc) => {
                            subDoc = subDoc.data();
                            let merchantName = arr[i].companyName;
                            let email = arr[i].email;
                            let userBasisPts = arr[i].userBasisPts;
                            let ptsConversion = ptsFormula(subDoc.netAmount, userBasisPts)
                            let newObj = {
                              ...subDoc,
                              merchantName,
                              email,
                              parentDocId: doc.data().docId,
                              userBasisPts,
                              ptsConversion
                            };
                            docArr.push(newObj);
                          });
                          setIsoAdminReportData(docArr);
                        } else {
                          setIsoAdminReportData([]);
                        }
                      });
                  });
                } else {
                  setIsoAdminReportData([]);
                }
              });
          }
        }

        // })
      });
  };

  function formatPhoneNumber(phoneNumber) {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return "";
  }

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage);
    setEndIndex(selected * itemsPerPage + itemsPerPage);
  };

  const getBgImage = () => {
    return tuzoBg;
  };

  const updateUserInfo = (e) => {
    // e.preventDefault();
    var newInfo = {
      firstName,
      lastName,
      companyName,
      cellPhone,
      secondaryPhone,
      shippingAddress,
      autoApprove,
      ptsReleaseDay: interval,
      userBasisPts: userBasisPts,
      signUpBonus: signUpBonus,
      contactFirstName,
      contactLastName,
      apiInfo
    };
    db.collection('users').doc(merchantId).get()
      .then((doc) => {
        if (!doc.exists) {
          db.collection('temp-user').doc(merchantId).update(newInfo)
        } else {
          db.collection('users').doc(merchantId).update(newInfo)
        }
      })
      .then(() => {
        getData()
        setEdit(false)
      })
  };

  const handlePasswordReset = (e) => {
    e.preventDefault();
    props.startPasswordReset(merchantData.email).then(() => {
      setEmailSent(true);
    });
  };

  const getMerchantArrData = () => {
    let arr = [];
    let pendArr = [];
    let combineArr = [];
    return db
      .collection("users")
      .where("SubIsoCompanyName", "==", merchantData.companyName)
      .get()
      .then((snapshots) => {
        if (!snapshots.empty) {
          snapshots.forEach((s) => {
            s = s.data();
            arr.push(s);
          });
          setMerchantDataArr(arr);
          setCopyArr(arr);
        } else {
          setMerchantDataArr(arr);
          setCopyArr(arr);
        }
        db.collection("temp-user")
          .where("SubIsoCompanyName", "==", merchantData.companyName)
          .get()
          .then((snapshots) => {
            if (!snapshots.empty) {
              snapshots.forEach((s) => {
                s = s.data();
                let newObj = {
                  ...s,
                  status: "Pending",
                };
                pendArr.push(newObj);
              });
              combineArr = [...arr, ...pendArr];
              setMerchantDataArr(combineArr);
              setCopyArr(combineArr);
            } else {
              combineArr = [...arr, ...pendArr];
              setMerchantDataArr(combineArr);
              setCopyArr(combineArr);
            }
          });
      });
  };

  const getData = () => {
    let rewardsArr = [];
    var userPts = 0;
    db.collection("users")
      .doc(merchantId)
      .get()
      .then((snapshot) => {
        snapshot = snapshot.data()
        userPts = snapshot.tuzoPoints ? snapshot.tuzoPoints : "0"
        setMerchantData(snapshot)
        setFirstName(snapshot.firstName)
        setLastName(snapshot.lastName)
        setCompanyName(snapshot.companyName)
        setCellPhone(snapshot.cellPhone ? snapshot.cellPhone : "")
        setSecondaryPhone(snapshot.secondaryPhone ? snapshot.secondaryPhone : "")
        setShippingAddress(snapshot.shippingAddress ? snapshot.shippingAddress : snapshot.address ? snapshot.address : "")
        setUserBasisPts(snapshot.userBasisPts)
        setSignUpBonus(snapshot.signUpBonus ? snapshot.signUpBonus : "")
        setInterval(snapshot.ptsReleaseDay ? snapshot.ptsReleaseDay : "60")
        setContactFirstName(snapshot.contactFirstName ? snapshot.contactFirstName : "")
        setContactLastName(snapshot.contactLastName ? snapshot.contactLastName : "")
        setApiInfo(snapshot.apiInfo ? snapshot.apiInfo : apiInfo)
        setModalApiUsername(snapshot.apiInfo ? snapshot.apiInfo[0].apiKey : "");
        setModalApiPassword(snapshot.apiInfo ? snapshot.apiInfo[0].apiSecret : "");
      })
      .then(() => {
        db.collection("rewards-list-test")
          .where("salePricePoints", "<=", userPts)
          .get()
          .then((snapshots) => {
            if (!snapshots.empty) {
              snapshots.forEach((doc) => {
                rewardsArr.push(doc.data());
              });
              setRewardsData(rewardsArr);
            } else {
              setRewardsData([]);
            }
          });
      })
  };

  const getWishList = () => {
    let wishListArr = [];
    db.collection("users")
      .doc(merchantId)
      .collection("wish-list")
      .get()
      .then((snapshots) => {
        if (!snapshots.empty) {
          snapshots.forEach((doc) => {
            wishListArr.push(doc.data());
          });
          setWishListData(wishListArr);
        } else {
          setWishListData([]);
        }
      });
  };

  useEffect(() => {
    if (!merchantData) {
      getData();
      getWishList();
    }
  }, []);

  useEffect(() => {
    if (merchantData) {
      getIsoAdminReport()
    }
    if (
      merchantData && merchantData.userType === "SubIso") {
      getMerchantArrData();
    }
  }, [merchantData.userType]);

  if (!merchantData || !wishListData || !rewardsData ||
    (merchantData && merchantData.userType === "SubIso" && !merchantDataArr)
  ) return <Loader />;

  return (
    <div>
      <Hidden mdUp implementation="css">
        <div>
          <Typography
            variant="h2"
            className={classes.headerTitle}
            style={{ color: "#1A405F" }}
          >
            Please View Page on Desktop / Laptop
          </Typography>
        </div>
      </Hidden>

      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem xs={8} style={{ display: "flex" }}>
            <Typography variant="h2" className={classes.headerTitle}>
              {merchantData.firstName + " " + merchantData.lastName}
            </Typography>

            {!edit ? (
              <div>
                <Button
                  onClick={() => setEdit(true)}
                  className={classes.editButton}
                >
                  <Edit style={{ marginRight: "10px", marginTop: "-5px" }} />{" "}
                  Edit
                </Button>

                <Button
                  onClick={handlePasswordReset}
                  className={classes.editButton}
                  style={{ background: "#56CA93", width: "350px" }}
                >
                  <BsCheckCircleFill
                    style={{ marginRight: "10px", marginTop: "-5px" }}
                  />{" "}
                  Send Reset Password Email
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  className={classes.editButton}
                  style={{ background: "#1A405F", width: "128px" }}
                  onClick={updateUserInfo}
                >
                  <SaveIcon
                    style={{ marginRight: "10px", marginTop: "-5px" }}
                  />{" "}
                  Save
                </Button>

                <Button
                  onClick={() => setEdit(false)}
                  className={classes.editButton}
                  style={{ background: "#E2A400", width: "143px" }}
                >
                  <Delete style={{ marginRight: "10px", marginTop: "-5px" }} />{" "}
                  Cancel
                </Button>
              </div>
            )}
          </GridItem>

          <GridItem
            xs={4}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "-10px",
                marginRight: "40px",
              }}
            >
              <Typography variant="h2" className={classes.headerDesc}>
                User Type
              </Typography>
              <Typography
                variant="h2"
                className={classes.headerDesc}
                style={{
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "18px",
                  marginTop: "10px",
                }}
              >
                {merchantData.userType.includes("Merchant")
                  ? "Merchant"
                  : merchantData.userType}
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "-10px",
              }}
            >
              <Typography variant="h2" className={classes.headerDesc}>
                MID
              </Typography>
              <Typography
                variant="h2"
                className={classes.headerDesc}
                style={{
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "18px",
                  marginTop: "10px",
                }}
              >
                {merchantData.merchantId}
              </Typography>
            </div>
          </GridItem>
        </GridContainer>

        <div
          style={{
            backgroundImage: "url(" + getBgImage() + ")",
            width: "100vw",
            marginLeft: "-30px",
            height: "100%",
            marginTop: "15px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#274b73",
            backgroundPhoto: "no-repeat",
            backgroundSize: "contain",
            padding: "20px",
          }}
        >
          <GridContainer style={{ alignItems: "center" }}>
            <GridItem xs={4} style={{ textAlign: "center", marginTop: "10px" }}>
              <Typography variant="h2" className={classes.profileBannerText}>
                User has{" "}
                <span className={classes.profileBannerPts}>
                  {" "}
                  {merchantData.approvedPts
                    ? (Math.trunc(merchantData.approvedPts)).toLocaleString()
                    : 0}{" "}
                </span>{" "}
                Tuzo points now and{" "}
                <span className={classes.profileBannerPts}>
                  {" "}
                  {merchantData.tuzoPoints
                    ? (Math.trunc(merchantData.tuzoPoints)).toLocaleString()
                    : 0}{" "}
                </span>{" "}
                pending!
              </Typography>
            </GridItem>

            <GridItem xs={4} style={{ textAlign: "center", marginTop: "10px" }}>
              <Typography
                variant="h2"
                className={classes.profileBannerText}
                style={{ lineHeight: "30px" }}
              >
                Based on your average monthly processing (
                {merchantData.tuzoPoints
                  ? (Math.trunc(merchantData.tuzoPoints)).toLocaleString()
                  : 0}
                ), you’re on track to have:
              </Typography>
            </GridItem>

            <GridItem
              xs={4}
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-evenly",
                textAlign: "center",
              }}
            >
              <div>
                <Typography
                  variant="h2"
                  className={classes.profileBannerDurationText}
                >
                  1 Month:
                </Typography>
                <Typography
                  variant="h2"
                  className={classes.profileBannerDurationPts}
                >
                  {merchantData.approvedPts
                    ? (Math.trunc(merchantData.approvedPts)).toLocaleString()
                    : 0}
                </Typography>
              </div>

              <div>
                <Typography
                  variant="h2"
                  className={classes.profileBannerDurationText}
                >
                  1 Year:
                </Typography>
                <Typography
                  variant="h2"
                  className={classes.profileBannerDurationPts}
                >
                  {merchantData.approvedPts
                    ? ((Math.trunc(merchantData.approvedPts)) * 12).toLocaleString()
                    : 0}
                </Typography>
              </div>

              <div>
                <Typography
                  variant="h2"
                  className={classes.profileBannerDurationText}
                >
                  3 Years:
                </Typography>
                <Typography
                  variant="h2"
                  className={classes.profileBannerDurationPts}
                >
                  {merchantData.approvedPts
                    ? ((Math.trunc(merchantData.approvedPts)) * 36).toLocaleString()
                    : 0}
                </Typography>
              </div>
            </GridItem>
          </GridContainer>
        </div>

        <GridContainer>
          <GridItem xs={12} style={{ marginTop: "20px" }}>
            <MerchantInfo
              edit={edit}
              setEdit={setEdit}
              merchantId={merchantId}
              rewardsData={rewardsData}
              classes={classes}
              history={history}
              wishListData={wishListData}
              merchantData={merchantData}
              formatPhoneNumber={formatPhoneNumber}
              itemsPerPage={itemsPerPage}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              index={index}
              setIndex={setIndex}
              endIndex={endIndex}
              setEndIndex={setEndIndex}
              handlePageClick={handlePageClick}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              companyName={companyName}
              setCompanyName={setCompanyName}
              contactFirstName={contactFirstName}
              setContactFirstName={setContactFirstName}
              contactLastName={contactLastName}
              setContactLastName={setContactLastName}
              cellPhone={cellPhone}
              setCellPhone={setCellPhone}
              secondaryPhone={secondaryPhone}
              setSecondaryPhone={setSecondaryPhone}
              shippingAddress={shippingAddress}
              setShippingAddress={setShippingAddress}
            />
          </GridItem>

          <GridItem xs={4} style={{ marginTop: "20px" }}>
            <Card className={classes.profileCardStyling2}>
              <Gateway
                gateway={gateway}
                setGateway={setGateway}
                apiInfo={apiInfo}
                setApiInfo={setApiInfo}
                userData={merchantData}
                globalEdit={edit}
              />
            </Card>
          </GridItem>

          <GridItem xs={4} style={{ marginTop: "20px" }}>
            <Card className={classes.profileCardStyling2}>
              <GridContainer>
                <GridItem xs={12} style={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h2" className={classes.cardHeader}>
                    Latest Wishlist Items
                  </Typography>

                  <Button
                    className={classes.viewWishListButton}
                    onClick={() => history.push(`/iso-admin/${userCompany}/merchant-wish-list/${merchantId}`)}
                  >
                    <ViewIcon className={classes.icon} /> View Full WishList
                  </Button>
                </GridItem>

                <GridItem xs={12}>
                  <WishListTable
                    classes={classes}
                    wishListData={wishListData}
                    itemsPerPage={itemsPerPage}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    index={index}
                    setIndex={setIndex}
                    endIndex={endIndex}
                    setEndIndex={setEndIndex}
                    handlePageClick={handlePageClick}
                  />
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>

          <GridItem xs={4} style={{ marginTop: "20px" }}>
            <Card className={classes.profileCardStyling2}>
              <GridContainer>
                <GridItem xs={12}>
                  <Typography variant="h2" className={classes.cardHeader}>
                    Contract Details
                  </Typography>
                </GridItem>

                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem xs={5}>
                      <Typography variant="h2" className={classes.cardDesc}>
                        Basis Points Set Aside
                      </Typography>
                    </GridItem>

                    <GridItem xs={7}>
                      {edit ?
                        <>
                          <TextField
                            className={classes.userInfoTextField}
                            value={userBasisPts}
                            onChange={(e) => setUserBasisPts(e.target.value)}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                adornedEnd: classes.adournedEnd,
                                input: classes.input
                              },
                            }}
                          />
                        </>
                        :
                        <>
                          <Typography variant="h2" className={classes.cardDescLessWeight}>
                            {userBasisPts ? userBasisPts : "N/A"}
                          </Typography>
                        </>
                      }
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem xs={5}>
                      <Typography variant="h2" className={classes.cardDesc}>
                        Sign-Up Bonus
                      </Typography>
                    </GridItem>

                    <GridItem xs={7}>
                      {edit ?
                        <>
                          <TextField
                            className={classes.userInfoTextField}
                            value={signUpBonus}
                            onChange={(e) => setSignUpBonus(e.target.value)}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                adornedEnd: classes.adournedEnd,
                                input: classes.input
                              },
                            }}
                          />
                        </>
                        :
                        <>
                          <Typography variant="h2" className={classes.cardDescLessWeight}>
                            {signUpBonus ? signUpBonus : "N/A"}
                          </Typography>
                        </>
                      }
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem xs={5}>
                      <Typography variant="h2" className={classes.cardDesc}>
                        Auto Approve Points
                      </Typography>
                    </GridItem>

                    <GridItem xs={7}>
                      <Typography variant="h2" className={classes.cardDescLessWeight}>
                        {merchantData.autoApprove ? "Enabled" : "Disabled"}
                      </Typography>
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem xs={12} style={{}}>
                  <GridContainer>
                    <GridItem xs={5}>
                      <Typography variant="h2" className={classes.cardDesc}>
                        Points Release Schedule
                      </Typography>
                    </GridItem>

                    <GridItem xs={7}>
                      {edit && !userLock ?
                        <>
                          <Select
                            className={classes.selectMenuSortProfile}
                            value={interval}
                            disableUnderline
                            classes={{
                              root: classes.selectText,
                            }}
                            onChange={(e) => setInterval(e.target.value)}

                          >
                            {intervalArr.map((o, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  value={o}
                                  classes={{
                                    root: classes.selectMenuItem,
                                  }}
                                >
                                  {o} Days
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </>
                        :
                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                          {merchantData.ptsReleaseDay ? merchantData.ptsReleaseDay + " Days" : "N/A"}
                        </Typography>
                      }

                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>

          {merchantData.userType === "SubIso" ?
            <>
              <GridItem xs={12} style={{ marginTop: "20px" }}>
                <Card className={classes.profileCardStyling2}>
                  <GridContainer>
                    <GridItem
                      xs={4}
                      style={{
                        alignItems: "center",
                        display: "flex",
                        marginBottom: "10px",
                      }}
                    >
                      <Typography variant="h2" className={classes.cardHeader}>
                        User Table
                      </Typography>

                      {userTypeArrFilter.length > 0 ? (
                        <Typography
                          className={classes.cardHeader}
                          style={{ marginLeft: "10px" }}
                        >
                          {userTypeArrFilter.length < 4 &&
                            merchantDataArr.length > 0
                            ? " " +
                            merchantDataArr.length +
                            " " +
                            userTypeArrFilter
                            : null}
                        </Typography>
                      ) : null}

                      <Button
                        onClick={() => setAddModal(true)}
                        className={classes.editButton}
                        style={{
                          background: "#E2A400",
                          width: "250px",
                          height: "35px",
                          marginTop: "0px",
                        }}
                      >
                        <Add
                          style={{ marginRight: "10px", marginTop: "-5px" }}
                        />{" "}
                        Invite User
                      </Button>
                    </GridItem>

                    <GridItem xs={8}>
                      <DateRange
                        classes={classes}
                        fromDate={fromDate}
                        setFromDate={setFromDate}
                        toDate={toDate}
                        setToDate={setToDate}
                        getIsoAdminReport={getIsoAdminReport}
                        csvData={isoAdminReportData}
                        csvLinkRef={csvLinkRef}
                      />
                    </GridItem>

                    <GridItem xs={12}>
                      <Filter
                        classes={classes}
                        statusDropdown={statusDropdown}
                        setStatusDropdown={setStatusDropdown}
                        ascDropdown={ascDropdown}
                        setAscDropdown={setAscDropdown}
                        searchString={searchString}
                        setSearchString={setSearchString}
                        statusArr={statusArr}
                        ascArr={ascArr}
                        itemsPerPage={userItemsPerPage}
                        setItemsPerPage={setUserItemsPerPage}
                        entriesArr={entriesArr}
                        handleSearch={handleSearch}
                        csvData={merchantDataArr}
                        header={headersISO}
                        setEndIndex={setUserEndIndex}
                        handleFilter={handleFilter}
                        resetEmptyData={resetEmptyData}
                        emptyData={emptyData}
                        userTypeArrFilter={userTypeArrFilter}
                        handleUserTypeFilter={handleUserTypeFilter}
                        userTypeArr={modalTypeArr}
                      />
                    </GridItem>

                    <GridItem xs={12}>
                      <Table
                        classes={classes}
                        data={merchantDataArr}
                        pageNumber={userPageNumber}
                        setPageNumber={setUserPageNumber}
                        index={userIndex}
                        setIndex={setUserIndex}
                        endIndex={userEndIndex}
                        setEndIndex={setUserEndIndex}
                        itemsPerPage={userItemsPerPage}
                        handlePageClick={handlePageClick}
                        history={history}
                        copyArr={copyArr}
                      />
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
            </>
            : null}
        </GridContainer>
        <DialogLoader showModal={showModal} />
      </Hidden>

      <Dialog
        className={classes.requestModal}
        open={emailSent}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Card className={classes.confirmModalCard}>
          <GridContainer>
            <GridItem xs={12}>
              <h5 className={classes.cardHeader}>
                Email has been sent to user to reset their password.{" "}
              </h5>
            </GridItem>
            <GridItem
              xs={12}
              style={{ justifyContent: "center", display: "flex" }}
            >
              <Button
                className={classes.uploadButton}
                onClick={() => {
                  setEmailSent(false);
                  history.push(
                    `/iso-admin/${userCompany
                      .replace(/ /g, "")
                      .toLowerCase()}/merchants`
                  );
                }}
              >
                <Close style={{ marginRight: "10px" }} /> Close
              </Button>
            </GridItem>
          </GridContainer>
        </Card>
      </Dialog>

      <Dialog
        className={classes.addUserModal}
        open={addModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AddModal
          classes={classes}
          merchantId={modalMerchantId}
          setMerchantId={setModalMerchantId}
          userBasisPts={modalUserBasisPts}
          setUserBasisPts={setModalUserBasisPts}
          signUpBonus={modalSignUpBonus}
          setSignUpBonus={setModalSignUpBonus}
          username={modalUsername}
          setUsername={setModalUsername}
          apiUsername={modalApiUserName}
          setApiUsername={setModalApiUsername}
          apiPassword={modalApiPassword}
          setApiPassword={setModalApiPassword}
          firstNameState={modalFirstName}
          setFirstNameState={setModalFirstName}
          lastNameState={modalLastName}
          setLastNameState={setModalLastName}
          companyState={modalCompanyName}
          setCompanyState={setModalCompanyName}
          ein={modalEin}
          setEin={setModalEin}
          notes={modalNotes}
          setNotes={setModalNotes}
          processorName={modalProcessorName}
          setProcessorName={setModalProcessorName}
          processorPhone={modalProcessorPhone}
          setProcessorPhone={setModalProcessorPhone}
          processorEmail={modalProcessorEmail}
          setProcessorEmail={setModalProcessorEmail}
          processorMid={modalprocessorMid}
          setProcessorMid={setModalProcessorMid}
          emailState={modalEmail}
          setEmailState={setModalEmail}
          cellPhoneState={modalCellPhone}
          setCellPhoneState={setModalCellPhone}
          secondaryPhoneState={modalSecondaryPhone}
          setSecondaryPhoneState={setModalSecondaryPhone}
          addressState={modalAddressState}
          setAddressState={setModalAddressState}
          handleSelect={handleSelect}
          errors={modalErrors}
          type={modalType}
          setType={setModalType}
          typeArr={modalTypeArr}
          resetState={resetState}
          addModal={addModal}
          setAddModal={setAddModal}
          sendCustomerEmail={sendCustomerEmail}
          userData={merchantData}
          accountId={accountId}
          setAccountId={setAccountId}
        />
      </Dialog>
    </div>
  );
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, location) {
  return {
    user: state.auth.user,
    userSet: state.auth.user.userSet,
    userCompany: state.auth.user.companyName,
    editState: location.history.location.state?.editState,
    userAutoApprove: state.auth.user.autoApprove,
    userLock: state.auth.user.lockSchedule
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    edit: (key) =>
      dispatch(
        actionsForNavigation.openChild("Profile Editor", `/admin/user/editor`)
      ),
    updateUserData: (uid, newInfo) => dispatch(updateUserData(uid, newInfo)),
    updateUserPhoto: (id, document) => dispatch(updateUserPhoto(id, document)),

    updateUserLogo: (id, document) => dispatch(updateUserLogo(id, document)),
    logout: () => dispatch(AuthActions.startLogout()),
    startPasswordReset: (email) =>
      dispatch(AuthActions.startPasswordReset(email)),
  });
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(UserProfile);
