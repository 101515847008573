import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/cartStyle';
import withStyles from '@material-ui/core/styles/withStyles';

// icons
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { ReactComponent as Edit } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";

// core components
import { Button, Checkbox, FormControlLabel, Hidden, TextareaAutosize, TextField, Typography } from "@material-ui/core";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';


export function ItemInfo(props) {
    const {
        classes,
        cart,
        numberFormatterDecimals,
        history,
        user,
        shippingAddress,
        setShippingAddress
    } = props;

    const [edit, setEdit] = useState(false)

    return (
        <div>
            <Hidden mdUp implementation='css'>
                <Card className={classes.card}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Button
                                style={{ width: "100%" }}
                                className={classes.backButton}
                                onClick={() => {
                                    if (user.IsoAdminCompanyName) {
                                        history.push(`/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/cart`)
                                    } else {
                                        history.push('/merchant/cart')
                                    }

                                }}
                            >
                                <MdKeyboardArrowLeft style={{ fontSize: "28px", marginTop: "-3px" }} /> Back to Cart
                            </Button>
                        </GridItem>

                        <GridItem xs={12} style={{ display: "flex" }}>
                            <Typography className={classes.totalCost}>
                                Review Your Order
                            </Typography>
                        </GridItem>
                    </GridContainer>

                    {cart.map((data, index) => (
                        data.checked === true ?
                            <div className={classes.itemBorderDiv} key={index}>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <img
                                            className={classes.mainImg}
                                            src={data.mainImage}
                                        />
                                    </GridItem>

                                    <GridItem xs={12}>
                                        <Typography
                                            className={classes.cardSubtitle}
                                            onClick={() => {
                                                if (user.IsoAdminCompanyName) {
                                                    history.push(
                                                        `/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/reward-detail/${data.rewardId}`,
                                                        {
                                                            rewardData: data
                                                        }
                                                    )
                                                }
                                                else {
                                                    history.push(
                                                        `/merchant/reward-detail/${data.rewardId}`,
                                                        {
                                                            rewardData: data
                                                        }
                                                    )
                                                }

                                            }}
                                        >
                                            {data.rewardName}
                                        </Typography>

                                        <Typography className={classes.cardDescription}>
                                            {data.topDescription.length > 8 ? <span dangerouslySetInnerHTML={{ __html: data.topDescription }}></span> : "No Description"}
                                        </Typography>

                                        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                                            <Typography
                                                className={classes.cardPolicy}
                                                onClick={() => {
                                                    window.open("https://www.tuzorewards.com/contact/terms-of-use/",
                                                        '_blank',
                                                        'noopener,noreferrer'
                                                    )
                                                }}
                                            >
                                                Rules, policies and cancellations
                                            </Typography>

                                            <Typography
                                                className={classes.cardPolicy}
                                                onClick={() => {
                                                    if (user.IsoAdminCompanyName) {
                                                        history.push(
                                                            `/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/reward-detail/${data.rewardId}`,
                                                            {
                                                                rewardData: data
                                                            }
                                                        )
                                                    }
                                                    else {
                                                        history.push(
                                                            `/merchant/reward-detail/${data.rewardId}`,
                                                            {
                                                                rewardData: data
                                                            }
                                                        )
                                                    }

                                                }}
                                            >
                                                Details
                                            </Typography>
                                        </div>
                                    </GridItem>

                                    <GridItem xs={12} style={{ display: "flex", alignItems: "flex-end", flexDirection: "column" }}>
                                        <Typography className={classes.totalCost}>
                                            {(data.salePricePoints).toLocaleString()}
                                            <span style={{ fontWeight: 400 }}>
                                                {" "}points
                                            </span>
                                        </Typography>

                                        <Typography className={classes.totalCostMarginTop} style={{ fontSize: "17px", fontWeight: 500 }}>
                                            ${numberFormatterDecimals.format(data.salePrice)}
                                        </Typography>

                                        <Typography className={classes.cardDescriptionFees}>
                                            Includes {data.taxClass} tax/fees
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                                {(data.giftCardType && !data.giftCardType.includes("eGift Card")) || !data.categories.travel ?
                                    <div className={classes.itemBorderTopDiv} key={index}>
                                        <GridContainer>
                                            <GridItem xs={6}>
                                                <Typography className={classes.totalCost}>
                                                    Shipping Address
                                                </Typography >
                                            </GridItem>

                                            <GridItem xs={6}>
                                                {!edit?
                                                <>
                                                    <Typography className={classes.cardDescription} style={{ marginTop: "0px" }}>
                                                        {shippingAddress}
                                                    </Typography>
                                                </>
                                                :
                                                <>
                                                    <TextareaAutosize
                                                        style={{ height: "66px", color: "#1A405F", lineHeight: "24px" }}
                                                        className={classes.userInfoTextField}
                                                        value={shippingAddress}
                                                        onChange={(e) => setShippingAddress(e.target.value)}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            classes: {
                                                                adornedEnd: classes.adournedEnd,
                                                                input: classes.input
                                                            },
                                                        }}
                                                    />
                                                </>
                                                }
                                            </GridItem>

                                            <GridItem xs={6}>
                                            {!edit?
                                                <>
                                                    <Button
                                                    className={classes.editButton}
                                                    onClick={() => setEdit(true)}
                                                    style={{ height: "35px", marginLeft: "0px" }}
                                                    >
                                                        <Edit style={{ marginRight: "5px", width: "17px" }} /> Edit
                                                    </Button>
                                                </>
                                                :
                                                <>
                                                    <Button
                                                    className={classes.editButton}
                                                    onClick={() => setEdit(false)}
                                                    style={{ height: "35px", marginLeft: "0px", background:"#1A405F" }}
                                                    >
                                                        <SaveIcon style={{ marginRight: "5px", width: "17px" }} /> Save
                                                    </Button>
                                                </>
                                                }
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            : null
                    ))}
                </Card>
            </Hidden>


            <Hidden smDown implementation='css'>
                <Card className={classes.card}>
                    <GridContainer>
                        <GridItem xs={12} style={{ display: "flex" }}>
                            <Typography className={classes.totalCost}>
                                Review Your Order
                            </Typography>

                            <Button
                                className={classes.backButton}
                                onClick={() => {
                                    if (user.IsoAdminCompanyName) {
                                        history.push(`/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/cart`)
                                    } else {
                                        history.push('/merchant/cart')
                                    }

                                }}
                            >
                                <MdKeyboardArrowLeft style={{ fontSize: "28px", marginTop: "-3px" }} /> Back to Cart
                            </Button>
                        </GridItem>
                    </GridContainer>

                    {cart.map((data, index) => (
                        data.checked === true ?
                            <div className={classes.itemBorderDiv} key={index}>
                                <GridContainer>
                                    <GridItem xs={3}>
                                        <img
                                            className={classes.mainImg}
                                            src={data.mainImage}
                                        />
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography
                                            className={classes.cardSubtitle}
                                            onClick={() => history.push(
                                                `/merchant/reward-detail/${data.rewardId}`,
                                                {
                                                    rewardData: data
                                                }
                                            )}
                                        >
                                            {data.rewardName}
                                        </Typography>

                                        <Typography className={classes.cardDescription}>
                                            {data.topDescription.length > 8 ? <span dangerouslySetInnerHTML={{ __html: data.topDescription }}></span> : "No Description"}
                                        </Typography>

                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography
                                                className={classes.cardPolicy}
                                                onClick={() => {
                                                    window.open("https://www.tuzorewards.com/contact/terms-of-use/",
                                                        '_blank',
                                                        'noopener,noreferrer'
                                                    )
                                                }}
                                            >
                                                Rules, policies and cancellations
                                            </Typography>

                                            <Typography
                                                className={classes.cardPolicy}
                                                onClick={() => history.push(
                                                    `/merchant/reward-detail/${data.rewardId}`,
                                                    {
                                                        rewardData: data
                                                    }
                                                )}
                                            >
                                                Details
                                            </Typography>
                                        </div>
                                    </GridItem>

                                    <GridItem xs={3} style={{ display: "flex", alignItems: "flex-end", flexDirection: "column" }}>
                                        <Typography className={classes.totalCost}>
                                            {(data.salePricePoints).toLocaleString()}
                                            <span style={{ fontWeight: 400 }}>
                                                {" "}points
                                            </span>
                                        </Typography>

                                        <Typography className={classes.totalCostMarginTop} style={{ fontSize: "17px", fontWeight: 500 }}>
                                            ${numberFormatterDecimals.format(data.salePrice)}
                                        </Typography>

                                        <Typography className={classes.cardDescriptionFees}>
                                            Includes {data.taxClass} tax/fees
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                                {(data.giftCardType && !data.giftCardType.includes("eGift Card")) || !data.categories.travel ?
                                    <div className={classes.itemBorderTopDiv} key={index}>
                                        <GridContainer>
                                            <GridItem xs={3}>
                                                <Typography className={classes.totalCost}>
                                                    Shipping Address
                                                </Typography >
                                            </GridItem>

                                            <GridItem xs={9}>
                                                {!edit?
                                                <>
                                                    <Typography className={classes.cardDescription} style={{ marginTop: "0px" }}>
                                                        {shippingAddress}
                                                    </Typography>
                                                </>
                                                :
                                                <>
                                                    <TextareaAutosize
                                                        style={{ height: "66px", color: "#1A405F", lineHeight: "24px" }}
                                                        className={classes.userInfoTextField}
                                                        value={shippingAddress}
                                                        onChange={(e) => setShippingAddress(e.target.value)}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            classes: {
                                                                adornedEnd: classes.adournedEnd,
                                                                input: classes.input
                                                            },
                                                        }}
                                                    />
                                                </>
                                                }
                                            </GridItem>

                                            <GridItem xs={3}>
                                                {!edit?
                                                <>
                                                    <Button
                                                    className={classes.editButton}
                                                    onClick={() => setEdit(true)}
                                                    style={{ height: "35px", marginLeft: "0px" }}
                                                    >
                                                        <Edit style={{ marginRight: "5px", width: "17px" }} /> Edit
                                                    </Button>
                                                </>
                                                :
                                                <>
                                                    <Button
                                                    className={classes.editButton}
                                                    onClick={() => setEdit(false)}
                                                    style={{ height: "35px", marginLeft: "0px", background:"#1A405F" }}
                                                    >
                                                        <SaveIcon style={{ marginRight: "5px", width: "17px" }} /> Save
                                                    </Button>
                                                </>
                                                }
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            : null
                    ))}
                </Card>
            </Hidden>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.auth.user
    }
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(ItemInfo);