import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import ReactPaginate from "react-paginate";
import "./pagination.css";

// core components
import { MenuItem, Select } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

// icons
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import { BsCheckCircleFill } from "react-icons/bs";


const headers = [
    "Name",
    "Cost",
    "Sku",
    "Category",
    "Actions",
];


function RewardTable(props) {
    const {
        classes,
        history,
        rewardsData,
        pageNumber,
        setPageNumber,
        index,
        setIndex,
        endIndex,
        setEndIndex,
        itemsPerPage,
        handlePageClick,
        selectReward,
        adName,
        deselectReward
    } = props;

    const getCategories = (data) => {
        let str = ""
        if (data.categories.electronics) {
            str += "Electronics, "
        }
        if (data.categories.sportsOutdoors) {
            str += "Sports/Outdoors, "
        }
        if (data.categories.giftCards) {
            str += "Gift Cards, "
        }
        if (data.categories.luxuryGoods) {
            str += "Luxury Goods, "
        }
        if (data.categories.homeKitchen) {
            str += "Home/Kitchen, "
        }
        if (data.categories.travel) {
            str += "Travel, "
        }
        return str.replace(/,\s*$/, "")
    }

    const numberFormatter = new Intl.NumberFormat('en-US', {
    });

    const numberFormatterDecimals = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    useEffect(() => {
        rewardsData.slice(index, endIndex);
    }, [index]);
    const renderTableHeader = () => {
        return (
            <tr
                className={classes.tableRow}
                style={{
                    height: "38px",
                    border: "1px solid #8E8E8E",
                }}
            >
                {headers.map((key, index) => {
                    return (
                        <th className={classes.tableHeaders} key={key}>
                            {key}
                        </th>
                    );
                })}
            </tr>
        );
    };

    const renderTableData = () => {
        return rewardsData
            .slice(
                pageNumber * itemsPerPage,
                pageNumber * itemsPerPage + itemsPerPage
            )
            .map((d, index) => {
                return (
                    <tr
                        className={index % 2 === 0 ? classes.tableRow : classes.tableRowOdd}
                        key={index}
                    >
                        <td className={classes.tableCell} style={{ fontWeight: 700, textAlign:"left" }}>{d.rewardName}</td>
                        <td className={classes.tableCell}>{numberFormatter.format(d.salePricePoints) + " Points"} / ${numberFormatterDecimals.format(d.salePrice)}</td>
                        <td className={classes.tableCell}>{d.sku}</td>
                        <td className={classes.tableCell}>
                            {getCategories(d)}
                        </td>
                        <td className={classes.tableCell}>
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-evenly",
                                }}
                            >
                                <Tooltip
                                    id="tooltip-top"
                                    title="View"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        style={{ background: "#FECD2C" }}
                                        className={classes.actionButton}
                                    >
                                        <ViewIcon />
                                    </Button>
                                </Tooltip>
                                {d.rewardName !== adName ?
                                    <>
                                        <Tooltip
                                            id="tooltip-top"
                                            title="Select"
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <Button
                                                className={classes.actionButton}
                                                style={{ background: "#E2A400" }}
                                                onClick={() => selectReward(d)}
                                            >
                                                <BsCheckCircleFill style={{ fontSize: "24px" }} />
                                            </Button>
                                        </Tooltip>
                                    </>
                                    :
                                    <Tooltip
                                            id="tooltip-top"
                                            title="Deselect"
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <Button
                                                className={classes.actionButton}
                                                style={{ background: "#56CA93" }}
                                                onClick={() => deselectReward()}
                                            >
                                                <TrashIcon/>
                                            </Button>
                                        </Tooltip>
                                
                            }

                            </div>
                        </td>
                    </tr>
                );
            });
    };

    return (
        <GridContainer>
            <GridItem xs={12}>
                <table className={classes.rewardsTable} style={{ marginTop: "10px" }}>
                    <tbody>
                        {renderTableHeader()}
                        {renderTableData()}
                    </tbody>
                </table>
                <GridContainer style={{ marginBottom: "-30px" }}>
                    <GridItem xs={10} style={{ marginTop: "5px", display: "flex", justifyContent: "flex-end" }}>
                        <h5
                            className={classes.indexes}
                        >
                            {index + 1} - {endIndex} of{" "}
                            {Math.ceil(rewardsData.length)}
                        </h5>
                    </GridItem>
                    <GridItem xs={2}>
                        <ReactPaginate
                            forcePage={pageNumber}
                            paginationSize={0}
                            onPageChange={handlePageClick}
                            pageCount={Math.ceil(rewardsData.length / itemsPerPage)}
                            nextLabel={
                                <ArrowRight
                                    style={{
                                        fontSize: 25,
                                        fontWeight: "bold",
                                        color: "white",
                                    }}
                                />
                            }
                            previousLabel={
                                <ArrowLeft
                                    style={{
                                        fontSize: 25,
                                        fontWeight: "bold",
                                        color: "white",
                                    }}
                                />
                            }
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />
                    </GridItem>
                </GridContainer>

            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authenticated: state.auth.authenticated,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(dashboardStyle)
)(React.memo(RewardTable));
