import React, { useEffect, useState } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//core components
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import { Dialog, TextareaAutosize, TextField, Typography } from "@material-ui/core";

// styling
import withStyles from "@material-ui/core/styles/withStyles";
import Style from "assets/jss/material-dashboard-pro-react/views/isoAdminDashboardStyle";

// icons
import {
    Cancel,
    CheckCircle,
} from "@material-ui/icons";
import DialogLoader from "components/Loader/DialogLoader";
export function Dashboard(props) {
    const {
        classes,
        transferModal,
        setTransferModal,
        currMerchant,
        errors,
        points,
        formatInput,
        resetTransferModal,
        confirmModal,
        setConfirmModal,
        numberFormatter,
        calculateAfterPoints,
        transferPoints,
        finishModal,
        setFinishModal,
        showModal,
        bonusMessage,
        setBonusMessage
    } = props;

    return (
        <div>
            <Dialog
                classes={{
                    paper: classes.transferModal
                }}
                open={transferModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xl"
            >
                <Card className={classes.modalCard}>
                    <GridContainer>
                        <GridItem xs={12} style={{ display: "flex", justifyContent: "space-evenly", marginTop: "15px" }}>
                            <h5 className={classes.cardHeaderModal}>Transfer Points To: {currMerchant.companyName}</h5>
                        </GridItem>

                        <GridItem xs={5} className={classes.gridItemMarginTop}>
                            <Typography
                                style={errors.points ? { marginTop: "30px" } : {}}
                                className={classes.modalCardDesc}>
                                Amount of Points
                            </Typography>
                        </GridItem>

                        <GridItem xs={7} className={classes.gridItemMarginTop}>
                            <div>
                                {errors.points ? (
                                    <span className={classes.errText}> Please fill out the field</span>
                                ) : null}
                                <TextField
                                    placeholder="0"
                                    className={classes.modalTextField}
                                    value={points}
                                    onChange={(e) => formatInput(e.target.value)}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            input: classes.input
                                        },
                                    }}
                                />
                            </div>
                        </GridItem>

                        <GridItem xs={5} className={classes.gridItemMarginTop}>
                            <Typography
                                className={classes.modalCardDesc}>
                                Message for Merchant
                            </Typography>
                        </GridItem>

                        <GridItem xs={7} className={classes.gridItemMarginTop}>
                            <div>
                                <TextareaAutosize
                                    style={{ height: "66px", color: "#1A405F", paddingTop:"5px"}}
                                    className={classes.modalTextField}
                                    value={bonusMessage}
                                    onChange={(e) => setBonusMessage(e.target.value)}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            input: classes.input
                                        },
                                    }}
                                />
                            </div>
                        </GridItem>

                        <GridItem xs={12} style={{ display: "flex", justifyContent: "space-evenly", marginTop: "15px" }}>
                            <Button
                                onClick={() => resetTransferModal()}
                                className={classes.modalButton}>
                                <Cancel style={{ marginRight: "10px", marginTop: "-5px" }} /> Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    setTransferModal(false)
                                    setConfirmModal(true)
                                }}
                                className={classes.modalButton}
                                style={{ background: "#1A405F", }}>
                                <CheckCircle style={{ marginRight: "10px", marginTop: "-5px" }} /> Send
                            </Button>
                        </GridItem>
                    </GridContainer>
                </Card>
            </Dialog>

            <Dialog
                classes={{
                    paper: classes.contactModal
                }}
                open={confirmModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xl"
            >
                <Card className={classes.modalCard}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <h5 className={classes.cardHeaderModal} style={{ fontSize: "22px", display: "flex", justifyContent: "center" }}>
                                Are you sure you want to tranfer {points} points to {currMerchant.companyName}?
                            </h5>
                        </GridItem>
                        <GridItem xs={12} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography className={classes.modalCardDesc} style={{ fontSize: "18px" }}>
                                Current Pending Points: {numberFormatter.format(currMerchant.tuzoPoints ? currMerchant.tuzoPoints : 0)}
                            </Typography>
                            <Typography className={classes.modalCardDesc} style={{ fontSize: "18px" }}>
                                After Point Transfer: {calculateAfterPoints(currMerchant.tuzoPoints ? currMerchant.tuzoPoints : 0, points)}
                            </Typography>
                        </GridItem>
                        <GridItem xs={12} style={{ display: "flex", justifyContent: "space-evenly", marginTop: "15px" }}>
                            <Button
                                onClick={() => resetTransferModal()}
                                className={classes.modalButton}>
                                <Cancel style={{ marginRight: "10px", marginTop: "-5px" }} /> Cancel
                            </Button>
                            <Button
                                onClick={() => transferPoints()}
                                className={classes.modalButton}
                                style={{ background: "#1A405F", }}>
                                <CheckCircle style={{ marginRight: "10px", marginTop: "-5px" }} /> Confirm
                            </Button>
                        </GridItem>
                    </GridContainer>

                </Card>
            </Dialog>

            <Dialog
                classes={{
                    paper: classes.contactModal
                }}
                open={finishModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xl"
            >
                <Card className={classes.modalCard}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <h5 className={classes.cardHeaderModal} style={{ fontSize: "22px", display: "flex", justifyContent: "center" }}>Point Tranfer Complete!</h5>
                        </GridItem>
                        {/* <GridItem xs={12}>
                    <Typography className={classes.modalCardDesc} style={{ fontSize: "18px" }}>
                      Thanks for requesting a trip package! A travel concierge will reach out shortly to assist in your booking.
                    </Typography>
                  </GridItem> */}
                        <GridItem xs={12} style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
                            <Button
                                onClick={() => {
                                    setFinishModal(false)
                                    window.location.reload()
                                }}
                                className={classes.modalButton}
                                style={{ background: "#1A405F", }}>
                                <Cancel style={{ marginRight: "10px", marginTop: "-5px" }} /> Close
                            </Button>
                        </GridItem>
                    </GridContainer>

                </Card>
            </Dialog>

            <DialogLoader showModal={showModal} />
        </div>
    )
}
function mapStateToProps(state) {
    return {
        userCompany: state.auth.user.companyName,
        user: state.auth.user
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({});
}

export default withRouter(
    compose(
        connect(mapStateToProps, mapDispatchToProps),
        withStyles(Style)
    )(Dashboard)
);