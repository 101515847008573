import React, { useEffect } from "react";
import { useState } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { db } from "firebase/client";
import { reportSelections } from "../reportSelections";

// mui
import { Card, Hidden, Typography } from "@material-ui/core";

// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// styling
import Style from "assets/jss/material-dashboard-pro-react/views/reportsStyle";
import withStyles from "@material-ui/core/styles/withStyles";

import { zip } from "lodash";
import { ptsFormula } from "globalCalculationFunction/globalCalculationFunction";
import Loader from "components/Loader/Loader";
import Filter from "./components/Filter";
import Table from "./components/Table";

const headers = [
    { label: "ISO Name", key: "isoName" },
    { label: "Sender Name", key: "senderName" },
    // { label: "Sender ID", key: "senderId"},
    { label: "Merchant #", key: "merchantNumber" },
    { label: "Merchant Name", key: "name" },
    { label: "Points Transfered", key: "pointsTransfered" },
    { label: "Transfer Date", key: "transferDate" },
];

const csvHeaders = [
    { label: "ISO Name", key: "isoName" },
    { label: "Sender Name", key: "senderName" },
    // { label: "Sender ID", key: "senderId" },
    { label: "Merchant #", key: "merchantNumber" },
    { label: "Merchant Name", key: "name" },
    { label: "Points Transfered", key: "pointsTransfered" },
    { label: "Transfer Date", key: "transferDate" },
];

export function PointTransfers(props) {
    const {
        classes,
        history,
        user,
        userType
    } = props;

    const [reportArr, setReportArr] = useState([]);
    const [sortValue, setSortValue] = useState("");
    const itemsPerPage = 9;
    const [pageNumber, setPageNumber] = useState(0);
    const [index, setIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(itemsPerPage);
    const [data, setData] = useState(false);
    const [copyArr, setCopyArr] = useState(false);
    const [searchString, setSearchString] = useState("");

    const numberFormatter = new Intl.NumberFormat("en-US", {});

    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
        setIndex(selected * itemsPerPage);
        setEndIndex(selected * itemsPerPage + itemsPerPage);
    };

    useEffect(() => {
        reportSelections.map((data) => {
            if (data.type === "Admin" && (userType === "Administrator" || userType === "Admin")) {
                setReportArr((prevArr) => [...prevArr, data]);
            }
        });
    }, []);

    const handleSearch = () => {
        if (searchString) {
            const filtered = copyArr.filter((f) => {
                if (
                    f.senderCompanyName.toString().toLowerCase().includes(searchString.toLowerCase())
                ) {
                    return true;
                }
                if (
                    f.senderFirstName.toString().toLowerCase().includes(searchString.toLowerCase())
                ) {
                    return true;
                }
                if (
                    f.senderLastName.toString().toLowerCase().includes(searchString.toLowerCase())
                ) {
                    return true;
                }
                if (f.merchantName.toLowerCase().includes(searchString.toLowerCase())) {
                    return true;
                }
                if (
                    f.merchantId.toString().toLowerCase().includes(searchString.toLowerCase())
                ) {
                    return true;
                }
            });
            setData(filtered);
        } else {
            setData(copyArr);
        }
    };

    const getData = () => {
        let arr = []
        db.collection('point-transfers').get()
            .then((snapshots) => {
                if (!snapshots.empty) {
                    snapshots.forEach((doc) => {
                        let data = doc.data()
                        arr.push(data)
                    })
                    setData(arr)
                    setCopyArr(arr)
                } else {
                    setData([])
                    setCopyArr([])
                }
            })
    }

    useEffect(() => {
        if (!data) {
            getData()
        }
    }, [])

    if (!data) return <Loader />;

    return (
        <div>
            <Hidden mdUp implementation="css">
                <div>
                    <Typography
                        variant="h2"
                        className={classes.title}
                        style={{ color: "#1A405F" }}
                    >
                        Please View Page on Desktop / Laptop
                    </Typography>
                </div>
            </Hidden>

            <Hidden smDown implementation="css">
                <GridContainer>
                    <GridItem xs={12}>
                        <Typography variant="h2" className={classes.title}>
                            Reports
                        </Typography>
                    </GridItem>

                    <GridItem xs={12} style={{ marginTop: "20px" }}>
                        <Card style={{ padding: "25px 20px 5px" }}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <Filter
                                        reportSelections={reportArr}
                                        sortValue={sortValue}
                                        setSortValue={setSortValue}
                                        csvData={data}
                                        headers={csvHeaders}
                                        numberFormatter={numberFormatter}
                                        searchString={searchString}
                                        setSearchString={setSearchString}
                                        handleSearch={handleSearch}
                                    />
                                </GridItem>

                                <GridItem xs={12}>
                                    <Table
                                        history={history}
                                        data={data}
                                        pageNumber={pageNumber}
                                        setPageNumber={setPageNumber}
                                        index={index}
                                        setIndex={setIndex}
                                        endIndex={endIndex}
                                        setEndIndex={setEndIndex}
                                        itemsPerPage={itemsPerPage}
                                        handlePageClick={handlePageClick}
                                        headers={headers}
                                        numberFormatter={numberFormatter}
                                    />
                                </GridItem>
                            </GridContainer>
                        </Card>
                    </GridItem>
                </GridContainer>
            </Hidden>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        userId: state.auth.user.uid,
        userType: state.auth.user.userType,
        user: state.auth.user,
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({});
}

export default withRouter(
    compose(
        connect(mapStateToProps, mapDispatchToProps),
        withStyles(Style)
    )(PointTransfers)
);