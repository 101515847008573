import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

// core components
import GridContainer from 'components/Grid/GridContainer';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem';
import { Button, Card, Checkbox, FormControl, FormControlLabel, FormGroup, Hidden, MenuItem, Radio, RadioGroup, Select, TextField } from '@material-ui/core';

// icons
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";


// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/rewardsStyle';
import { handleError } from 'store/actions/util';


export function RewardCategories(props) {
    const { classes, history, categories, handleChecked } = props;

    return (
        <div>
            <Hidden smDown implementation="css">
                <Card className={classes.card}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <h5 className={classes.cardHeader}>Reward Categories</h5>
                        </GridItem>
                        <GridItem xs={12} >
                            <FormControl component="fieldset" variant="standard">
                                <FormGroup>
                                    <FormControlLabel
                                        classes={{
                                            label: classes.checkboxes
                                        }}
                                        control={
                                            <Radio
                                                style={{ color: "#56CA93" }}
                                                checked={categories.luxuryGoods}
                                                onChange={handleChecked}
                                            />
                                        }
                                        value="luxuryGoods"
                                        label="Luxury Goods"
                                    />
                                    <FormControlLabel
                                        classes={{
                                            label: classes.checkboxes
                                        }}
                                        control={
                                            <Radio
                                                style={{ color: "#56CA93" }}
                                                checked={categories.travel}
                                                onChange={handleChecked}
                                            />
                                        }
                                        value="travel"
                                        label="Travel"
                                    />
                                    <FormControlLabel
                                        classes={{
                                            label: classes.checkboxes
                                        }}
                                        control={
                                            <Radio
                                                style={{ color: "#56CA93" }}
                                                checked={categories.electronics}
                                                onChange={handleChecked}
                                            />
                                        }
                                        value="electronics"
                                        label="Electronics"
                                    />
                                    <FormControlLabel
                                        classes={{
                                            label: classes.checkboxes
                                        }}
                                        control={
                                            <Radio
                                                style={{ color: "#56CA93" }}
                                                checked={categories.sportsOutdoors}
                                                onChange={handleChecked}
                                            />
                                        }
                                        value="sportsOutdoors"
                                        label="Sports and Outdoors"
                                    />
                                    <FormControlLabel
                                        classes={{
                                            label: classes.checkboxes
                                        }}
                                        control={
                                            <Radio
                                                style={{ color: "#56CA93" }}
                                                checked={categories.giftCards}
                                                onChange={handleChecked}
                                            />
                                        }
                                        value="giftCards"
                                        label="Gift Cards"
                                    />
                                    <FormControlLabel
                                        classes={{
                                            label: classes.checkboxes
                                        }}
                                        control={
                                            <Radio
                                                style={{ color: "#56CA93" }}
                                                checked={categories.homeKitchen}
                                                onChange={handleChecked}
                                            />
                                        }
                                        value="homeKitchen"
                                        label="Home/Kitchen"
                                    />
                                </FormGroup>
                            </FormControl>
                        </GridItem>
                    </GridContainer>
                </Card>
            </Hidden>
        </div>
    );
}
function mapStateToProps(state) {
    return {
        // nothing yet
    };
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}
export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(RewardCategories));