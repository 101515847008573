import React, { useEffect } from "react";
import { useState } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Carousel from "react-multi-carousel";
import Filter from "../rewards/components/Filter";
import NoRewards from "./components/NoRewards";
import { addProduct } from "store/actions/cart";
import { addToWishList } from "store/actions/wishlistAction";
import { removeFromWishlist } from "store/actions/wishlistAction";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Datetime from "react-datetime";
import moment from "moment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";

// mui core
import { CardContent, IconButton, Typography, Hidden, Tooltip, Dialog, TextField, InputAdornment, FormControl } from "@material-ui/core";
import ReactPaginate from "react-paginate";
import "views/Pages/reports/batch/components/pagination.css";

// @material-ui/icons
import { ReactComponent as Info } from "assets/img/tuzo/icons/Info.svg";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { AiFillGift } from "react-icons/ai";
import { ReactComponent as Diamond } from "assets/img/tuzo/icons/Diamond.svg";
import { ReactComponent as Trip } from "assets/img/tuzo/icons/TripPkg.svg";
import { ReactComponent as Computer } from "assets/img/tuzo/icons/Computer.svg";
import { ReactComponent as SportsandOutdoors } from "assets/img/tuzo/icons/SportsAndOutdoors.svg"
import { ReactComponent as GiftCard } from "assets/img/tuzo/icons/GiftCard.svg";
import { ReactComponent as CaretLeft } from "assets/img/tuzo/icons/CaretLeft.svg";
import { ReactComponent as CaretRight } from "assets/img/tuzo/icons/CaretRight.svg";
import { ReactComponent as HomeAndKitchen } from "assets/img/tuzo/icons/HomeAndKitchen.svg";
import { ReactComponent as CalendarIcon } from "assets/img/tuzo/icons/calendarIconWhite.svg";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";

// stylings
import Style from "assets/jss/material-dashboard-pro-react/views/rewardsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import tuzoBg from "assets/img/tuzo/carouselBackground.png";
import "../rewards/carousel.css";
import Loader from "components/Loader/Loader";
import { sendTripRequest } from "firebase/client";
import { Cancel, CheckCircle } from "@material-ui/icons";
import DialogLoader from "components/Loader/DialogLoader";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1900 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1900, min: 1366 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1350, min: 1024 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 1000, min: 0 },
    items: 1,
  },
};

const buttonNames = [
  "All",
  "Luxury Goods",
  "Trip Packages",
  "Electronics",
  "Sports/Outdoors",
  "Gift Cards",
  "Home/Kitchen",
];

const sortArr = [
  "Recently Added",
  "Price: Low to High",
  "Price: High to Low",
  "Title: A-Z",
  "Title: Z-A",
];

const yesterday = moment().subtract(1, "day");
const disablePastDt = (current) => {
  return current.isAfter(yesterday);
};

export function TravelPage(props) {
  const {
    classes,
    history,
    userId,
    addProduct,
    cart,
    rewards,
    wishlist,
    wishlistLoaded,
    addToWishList,
    removeFromWishlist,
    user,
  } = props;

  const [buttonFilter, setButtonFilter] = useState("Trip Packages");
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState("Recently Added");
  const [copyArr, setCopyArr] = useState(false);
  const [arr, setArr] = useState(false);
  const [wishListArr, setWishListArr] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const itemsPerPageMobile = 5;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0);
  const [mobileEndIndex, setMobileEndIndex] = useState(itemsPerPageMobile);
  const [address, setAddress] = useState("")
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
  const [adults, setAdults] = useState("")
  const [children, setChildren] = useState("")
  const [contactModal, setContactModal] = useState(false)
  const [city, setCity] = useState("")
  const [state, setState] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [tripData, setTripData] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [errors, setErrors] = useState({
    origin: false,
    fromDate: false,
    toDate: false,
    adults: false
  })

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPageMobile);
    setMobileEndIndex(selected * itemsPerPageMobile + itemsPerPageMobile);
  };

  useEffect(() => {
    if (arr.length > 0) {
      arr.slice(index, mobileEndIndex);
    }
  }, [index]);

  useEffect(() => {
    let filteredArr = [];
    if (rewards.length > 0 && wishlistLoaded) {
      var filteredTime = rewards.sort(function (a, b) {
        return b.timestamp.seconds - a.timestamp.seconds;
      });
      if (buttonFilter) {
        let valueLowerCase = ""
        if (buttonFilter === "Trip Packages") {
          valueLowerCase = "travel"
        }
        else {
          valueLowerCase = buttonFilter.toLowerCase().replace(/ +/g, "").replace(/\//g,"");
        }
        Object.keys(filteredTime).map((key) => {
          let val = filteredTime[key]["categories"];
          Object.keys(val).map((valKey) => {
            if (
              valKey.toLowerCase() === valueLowerCase &&
              val[valKey] === true
            ) {
              filteredArr.push(filteredTime[key]);
            }
          });
        });
        setArr([...filteredArr]);
        setCopyArr(rewards);
        setWishListArr(wishlist);
        setDataLoaded(true);
      } else {
        setArr([...filteredTime]);
        setCopyArr(rewards);
        setWishListArr(wishlist);
        setDataLoaded(true);
      }
    }
  }, [rewards, wishlist]);

  const reset = () => {
    setContactModal(false)
    setAddress("")
    setFromDate("")
    setToDate("")
    setAdults("")
    setChildren("")
    setErrors({
      origin: false,
      fromDate: false,
      toDate: false,
      adults: false
    })
  }

  const formatPhoneNumber = (input) => {
    input = input.replace(/\D/g, "");

    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0, 10);

    // Based upon the length of the string, we add formatting as necessary
    var size = input.length;
    if (size == 0) {
      input = input;
    } else if (size < 4) {
      input = "(" + input;
    } else if (size < 7) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    } else {
      input =
        "(" +
        input.substring(0, 3) +
        ") " +
        input.substring(3, 6) +
        "-" +
        input.substring(6, 10);
    }
    setPhoneNumber(input);
  };

  const handleSelect = (address, placeholder) => {
    geocodeByAddress(address).then((results) => {
      const lat = results[0].geometry.location.lat();
      const lng = results[0].geometry.location.lng();
      if (placeholder === "Going from...") {
        const addressResults = results[0];
        setAddress(addressResults.formatted_address);
        addressResults.address_components.forEach((r) => {
          if (r.types.includes("locality")) {
            setCity(r.long_name);
          }
          if (r.types.includes("administrative_area_level_1")) {
            setState(r.short_name);
          }
          if (r.types.includes("postal_code")) {
            setZipCode(r.short_name);
          }
        });

      }
    });
  };

  const submitRequest = async () => {
    if (!address || !fromDate || !adults) {
      let newErrors = {
        origin: false,
        fromDate: false,
        // toDate: false,
        adults: false
      }
      if (!address) {
        newErrors = { ...newErrors, origin: true }
      }
      if (!fromDate) {
        newErrors = { ...newErrors, fromDate: true }
      }
      if (!adults) {
        newErrors = { ...newErrors, adults: true }
      }
      setErrors({ ...newErrors })
    } else {
      setShowModal(true)
      let newObj = {
        user,
        tripData,
        address,
        fromDate,
        adults,
        children
      }
      await sendTripRequest(newObj).then(() => {
        setShowModal(false)
        setContactModal(false)
        setConfirmModal(true)
      })
    }

  }

  const getBgImage = () => {
    return tuzoBg;
  };

  const handleButtonFilter = (value) => {
    let valueLowerCase = "";
    if (value === "Trip Packages") {
      valueLowerCase = "travel";
    } else {
      valueLowerCase = value.toLowerCase().replace(/ +/g, "").replace(/\//g,"");
    }

    setButtonFilter(value);
    let filteredArr = [];
    if (value === "All") {
      setArr(copyArr);
    } else {
      Object.keys(copyArr).map((key) => {
        let val = copyArr[key]["categories"];
        Object.keys(val).map((valKey) => {
          if (valKey.toLowerCase() === valueLowerCase && val[valKey] === true) {
            filteredArr.push(copyArr[key]);
          }
        });
      });
      setArr(filteredArr);
    }
  };

  const CustomRightArrow = ({ onClick }) => (
    <button className={classes.rightArrow} onClick={onClick}>
      <CaretRight />
    </button>
  );
  const CustomLeftArrow = ({ onClick }) => (
    <button className={classes.leftArrow} onClick={onClick}>
      <CaretLeft />
    </button>
  );

  const handleFilter = (value) => {
    if (value === "Recently Added") {
      const filtered = arr.sort(function (a, b) {
        return b.timestamp.seconds - a.timestamp.seconds;
      });
      setIndex(0);
      setMobileEndIndex(itemsPerPageMobile);
      setPageNumber(0);
      setArr([...filtered]);
    }
    if (value === "Price: Low to High") {
      const filtered = arr.sort((a, b) =>
        a.salePricePoints
          .toString()
          .localeCompare(b.salePricePoints.toString(), undefined, {
            numeric: true,
            sensitivity: "base",
          })
      );
      setArr([...filtered]);
      setIndex(0);
      setMobileEndIndex(itemsPerPageMobile);
      setPageNumber(0);
    }
    if (value === "Price: High to Low") {
      const filtered = arr.sort((a, b) =>
        b.salePricePoints
          .toString()
          .localeCompare(a.salePricePoints.toString(), undefined, {
            numeric: true,
          })
      );
      setArr(filtered);
      setIndex(0);
      setMobileEndIndex(itemsPerPageMobile);
      setPageNumber(0);
    }
    if (value === "Title: A-Z") {
      const filtered = arr.sort(function (a, b) {
        if (a.rewardName < b.rewardName) {
          return -1;
        }
        if (a.rewardName > b.rewardName) {
          return 1;
        }
        return 0;
      });
      setArr(filtered);
      setIndex(0);
      setMobileEndIndex(itemsPerPageMobile);
      setPageNumber(0);
    }
    if (value === "Title: Z-A") {
      const filtered = arr.sort(function (a, b) {
        if (a.rewardName.toLowerCase() > b.rewardName.toLowerCase()) {
          return -1;
        }
        if (a.rewardName.toLowerCase() < b.rewardName.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setArr(filtered);
      setIndex(0);
      setMobileEndIndex(itemsPerPageMobile);
      setPageNumber(0);
    }
  };

  const handleAddWishlist = (index) => {
    let newArr = [...arr];
    newArr[index]["wishlist"] = true;
    setArr(newArr);
  };

  const handleRemoveFromWishlist = (index) => {
    let newArr = [...arr];
    newArr[index]["wishlist"] = false;
    setArr(newArr);
  };

  useEffect(() => {
    if (dataLoaded) {
      let newArr = [...arr];
      newArr.map((data, index) => {
        data.added = false;
        cart.map((cartData, index) => {
          if (cartData.rewardId === data.rewardId) {
            data.added = true;
          }
        });
        if (JSON.stringify(wishListArr).includes(data.rewardId)) {
          data.wishlist = true;
        }
      });
      setArr(newArr);
    }
  }, [dataLoaded]);

  const checkedAdded = (index) => {
    let newArr = [...arr];
    newArr[index]["added"] = true;
    setArr(newArr);
  };

  const handleSearch = () => {
    if (searchValue) {
      const filtered = arr.filter((f) => {
        if (f.rewardName.toLowerCase().includes(searchValue.toLowerCase())) {
          return true;
        }
      });
      setArr(filtered);
    } else {
      setArr(copyArr);
    }
  };

  if (!dataLoaded) return <Loader />;

  return (
    <div>
      <Hidden mdUp implementation="css">
        <div
          style={{
            backgroundImage: "url(" + getBgImage() + ")",
            width: "100vw",
            marginLeft: "-30px",
            height: "100%",
            marginTop: "30px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#274b73",
            backgroundPhoto: "repeat",
            backgroundSize: "contain",
            padding: "30px 20px",
          }}
        >
          <GridContainer>
            <GridItem xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="h2"
                  className={classes.rewardsBannerTitle}
                  style={{ marginTop: "15px" }}
                >
                  Explore the Possibilities
                </Typography>

                <Typography
                  variant="h2"
                  className={classes.rewardsBannerDesc}
                  style={{ marginTop: "15px" }}
                >
                  Take a trip, pamper yourself, or buy that luxury item!
                </Typography>
              </div>
            </GridItem>

            <GridItem xs={12} style={{ marginTop: "10px" }}>
              <Carousel
                autoPlay={false}
                autoPlaySpeed={3000}
                infinite={true}
                swipeable={true}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                draggable={false}
                showDots={false}
                responsive={responsive}
                style={{ display: "flex", alignItems: "center" }}
                arrows={true}
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}
              >
                {buttonNames.map((name) => {
                  return (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        onClick={() => {
                          if (name === "All") {
                            history.push("/merchant/rewards");
                          } else {
                            history.push("/merchant/rewards", {
                              filterName:
                                name === "Trip Packages" ? "Travel" : name,
                            });
                          }
                        }}
                        style={
                          buttonFilter === name ? { background: "#56CA93", width: "200px" } : { width: "200px" }
                        }
                        className={classes.rewardsBannerButton}
                      >
                        {name === "All" ? (
                          <AiFillGift
                            className={classes.icon}
                            style={{ marginRight: "10px" }}
                          />
                        ) : name === "Luxury Goods" ? (
                          <Diamond className={classes.icon} />
                        ) : name === "Trip Packages" ? (
                          <Trip className={classes.icon} />
                        ) : name === "Electronics" ? (
                          <Computer className={classes.icon} />
                        ) : name === "Sports/Outdoors" ? (
                          <SportsandOutdoors className={classes.icon} />
                        ) : name === "Gift Cards" ? (
                          <GiftCard className={classes.icon} />
                        ) : (
                          <HomeAndKitchen className={classes.icon} />
                        )}
                        {name}
                      </Button>
                    </div>
                  );
                })}
              </Carousel>
            </GridItem>
          </GridContainer>
        </div>

        <GridContainer>
          {arr.length < 1 ? (
            <>
              <GridItem xs={12}>
                <NoRewards
                  classes={classes}
                  handleButtonFilter={handleButtonFilter}
                  buttonFilter={buttonFilter}
                  history={history}
                />
              </GridItem>
            </>
          ) : (
            <>
              <GridItem xs={12} style={{ marginTop: "10px" }}>
                <Filter
                  sortArr={sortArr}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  sortValue={sortValue}
                  setSortValue={setSortValue}
                  handleSearch={handleSearch}
                  handleFilter={handleFilter}
                  history={history}
                />
              </GridItem>

              <GridItem xs={12}>
                <GridContainer>
                  {arr
                    .slice(
                      pageNumber * itemsPerPageMobile,
                      pageNumber * itemsPerPageMobile + itemsPerPageMobile
                    )
                    .map((data, index) => (
                      <>
                        <GridItem
                          xs={12}
                          className={classes.gridSpace}
                          key={index}
                        >
                          <Card
                            className={classes.rewardsCardListStylingMobile}
                            key={index}
                          >
                            <CardContent style={{ marginBottom: "0px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <IconButton>
                                  <Info />
                                </IconButton>
                                {!data.wishlist ? (
                                  <>
                                    <IconButton
                                      onClick={() => {
                                        handleAddWishlist(index);
                                        addToWishList(data, userId);
                                      }}
                                      style={{ color: "#E2A400" }}
                                    >
                                      <AiOutlineHeart />
                                    </IconButton>
                                  </>
                                ) : (
                                  <>
                                    <IconButton
                                      onClick={() => {
                                        handleRemoveFromWishlist(index);
                                        removeFromWishlist(data, userId, index);
                                      }}
                                      style={{ color: "#E2A400" }}
                                    >
                                      <AiFillHeart />
                                    </IconButton>
                                  </>
                                )}
                              </div>

                              <div
                                className={classes.divHover}
                                onClick={() => {
                                  if (user.IsoAdminCompanyName) {
                                    history.push(
                                      `/iso-merchant/${user.IsoAdminCompanyName.replace(
                                        / /g,
                                        ""
                                      ).toLowerCase()}/${user.companyName
                                        .replace(/ /g, "")
                                        .toLowerCase()}/reward-detail/${data.rewardId
                                      }`,
                                      {
                                        rewardData: data,
                                      }
                                    );
                                  } else {
                                    history.push(
                                      `/merchant/reward-detail/${data.rewardId}`,
                                      {
                                        rewardData: data,
                                      }
                                    );
                                  }
                                }}
                              >
                                <img
                                  style={{ width: "50%" }}
                                  src={data.mainImage}
                                  alt="img here"
                                />

                                <Typography
                                  variant="h2"
                                  className={classes.cardListTitleNoOverflow}
                                >
                                  {/* {(data.rewardName).length < 33 ? "" : null} */}
                                  {data.rewardName}
                                </Typography>
                              </div>

                              {buttonFilter == "Trip Packages" ?
                                <GridContainer>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "93%",
                                      padding: "5px",
                                    }}
                                  >
                                    <GridItem xs={6}>
                                      <Typography
                                        variant="h2"
                                        className={classes.cardListDesc}
                                      >
                                        Starting At (Points)
                                      </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                      <Typography
                                        variant="h2"
                                        className={classes.cardListPtsMobile}
                                      >
                                        {data.salePricePoints.toLocaleString()}
                                      </Typography>
                                    </GridItem>
                                  </div>

                                  <GridItem
                                    xs={12}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Tooltip
                                      id="tooltip-top"
                                      title="Contact us to book with one of our concierge."
                                      placement="top"
                                      classes={{ tooltip: classes.tooltip }}
                                    >
                                      <Button
                                        className={
                                          classes.addToCartButtonMobile
                                        }
                                        onClick={() => {
                                          setContactModal(true)
                                          setTripData(data)
                                        }}
                                      >
                                        Contact Us
                                      </Button>
                                    </Tooltip>
                                  </GridItem>

                                </GridContainer>
                                :
                                <GridContainer>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "93%",
                                      padding: "5px",
                                    }}
                                  >
                                    <GridItem xs={6}>
                                      <Typography
                                        variant="h2"
                                        className={classes.cardListDesc}
                                      >
                                        Points Price
                                      </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                      <Typography
                                        variant="h2"
                                        className={classes.cardListPtsMobile}
                                      >
                                        {data.salePricePoints.toLocaleString()}
                                      </Typography>
                                    </GridItem>
                                  </div>


                                  <GridItem
                                    xs={12}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {data.added ? (
                                      <>
                                        <Button
                                          className={
                                            classes.addedInCartButtonMobile
                                          }
                                        >
                                          Added to Cart
                                        </Button>
                                      </>
                                    ) : (
                                      <Button
                                        className={classes.addToCartButtonMobile}
                                        onClick={() => {
                                          addProduct(data);
                                          checkedAdded(index);
                                        }}
                                      >
                                        Add to Cart
                                      </Button>
                                    )}
                                  </GridItem>
                                </GridContainer>
                              }
                            </CardContent>
                          </Card>
                        </GridItem>
                      </>
                    ))}
                </GridContainer>
              </GridItem>
            </>
          )}
        </GridContainer>
        <GridContainer style={{ marginBottom: "-20px" }}>
          <GridItem
            xs={8}
            style={{
              marginTop: "5px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <h5 className={classes.indexes}>
              {index + 1} - {mobileEndIndex} of{" "}
              {Math.ceil(arr.length / itemsPerPageMobile)}
            </h5>
          </GridItem>
          <GridItem xs={4}>
            <ReactPaginate
              forcePage={pageNumber}
              paginationSize={0}
              onPageChange={handlePageClick}
              pageCount={Math.ceil(arr.length / itemsPerPageMobile)}
              nextLabel={
                <ArrowRight
                  style={{
                    fontSize: 25,
                    fontWeight: "bold",
                    color: "white",
                    marginTop: "-2px",
                  }}
                />
              }
              previousLabel={
                <ArrowLeft
                  style={{
                    fontSize: 25,
                    fontWeight: "bold",
                    color: "white",
                    marginTop: "-2px",
                  }}
                />
              }
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </GridItem>
        </GridContainer>
      </Hidden>

      <Hidden smDown implementation="css">
        <div>
          <div
            style={{
              backgroundImage: "url(" + getBgImage() + ")",
              width: "100vw",
              marginLeft: "-30px",
              height: "100%",
              marginTop: "-30px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#274b73",
              backgroundPhoto: "repeat",
              backgroundSize: "contain",
              padding: "30px 20px",
            }}
          >
            <GridContainer style={{ marginLeft: "30px" }}>
              <GridItem xs={4}>
                <div>
                  <Typography
                    variant="h2"
                    className={classes.rewardsBannerTitle}
                  >
                    Explore the Possibilities
                  </Typography>

                  <Typography
                    variant="h2"
                    className={classes.rewardsBannerDesc}
                    style={{ marginTop: "15px" }}
                  >
                    Take a trip, pamper yourself, or buy that luxury item!
                  </Typography>
                </div>
              </GridItem>

              <GridItem xs={7}>
                <Carousel
                  autoPlay={false}
                  autoPlaySpeed={3000}
                  infinite={true}
                  swipeable={true}
                  containerClass="carousel-container"
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  style={{ display: "flex" }}
                  arrows={true}
                  customLeftArrow={<CustomLeftArrow />}
                  customRightArrow={<CustomRightArrow />}
                >
                  {buttonNames.map((name) => {
                    return (
                      <Button
                        onClick={() => {
                          if (name === "All") {
                            history.push("/merchant/rewards");
                          } else {
                            history.push("/merchant/rewards", {
                              filterName:
                                name === "Trip Packages" ? "Travel" : name,
                            });
                          }
                        }}
                        style={
                          buttonFilter === name ? { background: "#56CA93" } : {}
                        }
                        className={classes.rewardsBannerButton}
                      >
                        {name === "All" ? (
                          <AiFillGift
                            className={classes.icon}
                            style={{ marginRight: "10px" }}
                          />
                        ) : name === "Luxury Goods" ? (
                          <Diamond className={classes.icon} />
                        ) : name === "Trip Packages" ? (
                          <Trip className={classes.icon} />
                        ) : name === "Electronics" ? (
                          <Computer className={classes.icon} />
                        ) : name === "Sports/Outdoors" ? (
                          <SportsandOutdoors className={classes.icon} />
                        ) : name === "Gift Cards" ? (
                          <GiftCard className={classes.icon} />
                        ) : (
                          <HomeAndKitchen className={classes.icon} />
                        )}

                        {name}
                      </Button>
                    );
                  })}
                </Carousel>
              </GridItem>
            </GridContainer>
          </div>

          <GridContainer>
            {arr.length < 1 ? (
              <>
                <GridItem xs={12}>
                  <NoRewards
                    classes={classes}
                    handleButtonFilter={handleButtonFilter}
                    buttonFilter={buttonFilter}
                    history={history}
                  />
                </GridItem>
              </>
            ) : (
              <>
                <GridItem xs={12} style={{ marginTop: "10px" }}>
                  <Filter
                    sortArr={sortArr}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    sortValue={sortValue}
                    setSortValue={setSortValue}
                    handleSearch={handleSearch}
                    handleFilter={handleFilter}
                    history={history}
                  />
                </GridItem>

                <GridItem xs={12}>
                  <GridContainer>
                    {arr.map((data, index) => (
                      <>
                        <GridItem
                          xs={4}
                          md={4}
                          xl={3}
                          className={classes.gridSpace}
                          key={index}
                        >
                          <Card
                            className={classes.rewardsCardListStyling}
                            key={index}
                          >
                            <CardContent style={{ padding: "0px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <IconButton
                                  onClick={() => {
                                    if (user.IsoAdminCompanyName) {
                                      history.push(
                                        `/iso-merchant/${user.IsoAdminCompanyName.replace(
                                          / /g,
                                          ""
                                        ).toLowerCase()}/${user.companyName
                                          .replace(/ /g, "")
                                          .toLowerCase()}/reward-detail/${data.rewardId
                                        }`,
                                        {
                                          rewardData: data,
                                        }
                                      );
                                    } else {
                                      history.push(
                                        `/merchant/reward-detail/${data.rewardId}`,
                                        {
                                          rewardData: data,
                                        }
                                      );
                                    }
                                  }}
                                >
                                  <Info />
                                </IconButton>
                                {!data.wishlist ? (
                                  <>
                                    <IconButton
                                      onClick={() => {
                                        handleAddWishlist(index);
                                        addToWishList(data, userId);
                                      }}
                                      style={{ color: "#E2A400" }}
                                    >
                                      <AiOutlineHeart />
                                    </IconButton>
                                  </>
                                ) : (
                                  <>
                                    <IconButton
                                      onClick={() => {
                                        handleRemoveFromWishlist(index);
                                        removeFromWishlist(data, userId, index);
                                      }}
                                      style={{ color: "#E2A400" }}
                                    >
                                      <AiFillHeart />
                                    </IconButton>
                                  </>
                                )}
                              </div>

                              <div
                                className={classes.divHover}
                                onClick={() => {
                                  if (user.IsoAdminCompanyName) {
                                    history.push(
                                      `/iso-merchant/${user.IsoAdminCompanyName.replace(
                                        / /g,
                                        ""
                                      ).toLowerCase()}/${user.companyName
                                        .replace(/ /g, "")
                                        .toLowerCase()}/reward-detail/${data.rewardId
                                      }`,
                                      {
                                        rewardData: data,
                                      }
                                    );
                                  } else {
                                    history.push(
                                      `/merchant/reward-detail/${data.rewardId}`,
                                      {
                                        rewardData: data,
                                      }
                                    );
                                  }
                                }}
                              >
                                <img
                                  className={classes.cardListImg}
                                  src={data.mainImage}
                                  alt="img here"
                                />

                                <Typography
                                  variant="h2"
                                  className={classes.cardListTitleNoOverflow}
                                >
                                  {data.rewardName.length < 33 ? <br /> : null}
                                  {data.rewardName}
                                </Typography>
                              </div>

                              {buttonFilter == "Trip Packages" ?
                                <div className={classes.divStyle}>
                                  <div>
                                    <Typography
                                      variant="h2"
                                      className={classes.cardListDesc}
                                    >
                                      Starting At (Points)
                                    </Typography>
                                    <Typography
                                      variant="h2"
                                      className={classes.cardListPts}
                                    >
                                      {data.salePricePoints.toLocaleString()}
                                    </Typography>
                                  </div>

                                  <div style={{ display: "flex" }}>
                                    <Tooltip
                                      id="tooltip-top"
                                      title="Contact us to book with one of our concierge."
                                      placement="top"
                                      classes={{ tooltip: classes.tooltip }}
                                    >
                                      <Button
                                        className={
                                          classes.addToCartButton
                                        }
                                        onClick={() => {
                                          setContactModal(true)
                                          setTripData(data)
                                        }}
                                      >
                                        Contact Us
                                      </Button>
                                    </Tooltip>
                                  </div>
                                </div>
                                :
                                <div className={classes.divStyle}>
                                  <div>
                                    <Typography
                                      variant="h2"
                                      className={classes.cardListDesc}
                                    >
                                      Points Price
                                    </Typography>
                                    <Typography
                                      variant="h2"
                                      className={classes.cardListPts}
                                    >
                                      {data.salePricePoints.toLocaleString()}
                                    </Typography>
                                  </div>

                                  <div style={{ display: "flex" }}>
                                    {data.added ? (
                                      <Button
                                        className={classes.addedInCartButton}
                                      >
                                        Added to Cart
                                      </Button>
                                    ) : (
                                      <Button
                                        className={classes.addToCartButton}
                                        onClick={() => {
                                          addProduct(data);
                                          checkedAdded(index);
                                        }}
                                      >
                                        Add to Cart
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              }
                            </CardContent>
                          </Card>
                        </GridItem>
                      </>
                    ))}
                  </GridContainer>
                </GridItem>
              </>
            )}
          </GridContainer>
        </div>
      </Hidden>

      <Dialog
        classes={{
          paper: classes.contactModal
        }}
        open={contactModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <Card className={classes.modalCard}>
          <GridContainer>
            <GridItem xs={12}>
              <h5 className={classes.cardHeaderModal}>Contact Concierge About: {tripData.rewardName}</h5>
            </GridItem>

            <GridItem xs={5} className={classes.gridItemMarginTop}>
              <Typography
                style={errors.origin ? { marginTop: "30px" } : {}}
                className={classes.modalCardDesc}>
                Origin
              </Typography>
            </GridItem>

            <GridItem xs={7} className={classes.gridItemMarginTop}>
              <PlacesAutocomplete
                value={address}
                onChange={(e) => {
                  setAddress(e)
                }}
                onSelect={(e) => handleSelect(e, "Going from...")}
                googleCallbackName={"initOne"}
                searchOptions={{
                  // componentRestrictions: { country: "us" },
                }}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <FormControl style={{ width: "100%" }}>
                      {errors.origin ? (
                        <span className={classes.errText}> Please fill out field</span>
                      ) : null}
                      <TextField
                        // className={classes.modalTextField}
                        value={address}
                        InputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                          disableUnderline: true,
                          classes: {
                            root: classes.modalTextField,
                            adornedEnd: classes.adournedEnd,
                            input: classes.input
                          },
                        }}
                        {...getInputProps({
                          placeholder: "Going from...",
                          className: "location-search-input",
                        })}
                      />
                      <div className="autocomplete-dropdown-container" style={{ zIndex: "99" }}>
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                              padding: "10px"
                            }
                            : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                              padding: "10px",
                            };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </FormControl>
                  </div>
                )}
              </PlacesAutocomplete>
            </GridItem>

            <GridItem xs={5} className={classes.gridItemMarginTop}>
              <Typography
                style={errors.fromDate ? { marginTop: "30px" } : {}}
                className={classes.modalCardDesc}>
                From Date
              </Typography>
            </GridItem>

            <GridItem xs={7} className={classes.gridItemMarginTop}>
              <div>
                {errors.fromDate ? (
                  <span className={classes.errText}>Please fill out the field</span>
                ) : null}
                <Datetime
                  isValidDate={disablePastDt}
                  className='travel'
                  selected={fromDate}
                  dateFormat="MM/DD/YY"
                  onChange={(date, e) => {
                    setFromDate(date.format("MM/DD/YY"));
                  }}
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside
                  renderInput={(props, openCalendar, closeCalendar) => (
                    <div onClick={openCalendar}>
                      <TextField
                        style={{ marginTop: "5px" }}
                        className={classes.datetime}
                        value={fromDate}
                        variant="outlined"
                        InputProps={{
                          classes: {
                            adornedEnd: classes.adournedEnd,
                            input: classes.input
                          },
                          endAdornment: (
                            <InputAdornment
                              className={classes.adournedEnd}
                              position="end"
                            >
                              <Button className={classes.datetimeButton}>
                                <CalendarIcon
                                  className={classes.calendarIcon}
                                  onClick={openCalendar}
                                />
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  )}
                />
              </div>
            </GridItem>

            <GridItem xs={5} className={classes.gridItemMarginTop}>
              <Typography
                style={errors.phoneNumber ? { marginTop: "30px" } : {}}
                className={classes.modalCardDesc}>
                Phone Number
              </Typography>
            </GridItem>

            <GridItem xs={7} className={classes.gridItemMarginTop}>
              <div>
                {errors.phoneNumber ? (
                  <span className={classes.errText}> Please fill out the field</span>
                ) : null}
                <TextField
                  placeholder="(XXX) XXX-XXXX"
                  className={classes.modalTextField}
                  value={phoneNumber}
                  onChange={(e) => formatPhoneNumber(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input
                    },
                  }}
                />
              </div>
            </GridItem>

            <GridItem xs={5} className={classes.gridItemMarginTop}>
              <Typography
                style={errors.adults ? { marginTop: "30px" } : {}}
                className={classes.modalCardDesc}>
                Adult(s)
              </Typography>
            </GridItem>

            <GridItem xs={7} className={classes.gridItemMarginTop}>
              <div>
                {errors.adults ? (
                  <span className={classes.errText}> Please fill out the field</span>
                ) : null}
                <TextField
                  placeholder="0"
                  type="number"
                  className={classes.modalTextField}
                  value={adults}
                  onChange={(e) => setAdults(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input
                    },
                    inputProps: {
                      min: 0
                    }
                  }}
                />
              </div>
            </GridItem>

            <GridItem xs={5} className={classes.gridItemMarginTop}>
              <Typography
                // style={errors.adult ? { marginTop: "30px" } : {}}
                className={classes.modalCardDesc}>
                Children(s)
              </Typography>
            </GridItem>

            <GridItem xs={7} className={classes.gridItemMarginTop}>
              <TextField
                placeholder="0"
                type="number"
                className={classes.modalTextField}
                value={children}
                onChange={(e) => setChildren(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: classes.input
                  },
                  inputProps: {
                    min: 0
                  }
                }}
              />
            </GridItem>

            <GridItem xs={12} style={{ display: "flex", justifyContent: "space-evenly", marginTop: "15px" }}>
              <Button
                onClick={() => reset()}
                className={classes.modalButton}>
                <Cancel style={{ marginRight: "10px", marginTop: "-5px" }} /> Cancel
              </Button>
              <Button
                onClick={() => submitRequest()}
                className={classes.modalButton}
                style={{ background: "#1A405F", }}>
                <CheckCircle style={{ marginRight: "10px", marginTop: "-5px" }} /> Send
              </Button>
            </GridItem>
          </GridContainer>
        </Card>
      </Dialog>

      <Dialog
        classes={{
          paper: classes.contactModal
        }}
        open={confirmModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <Card className={classes.modalCard}>
          <GridContainer>
            <GridItem xs={12}>
              <h5 className={classes.cardHeaderModal} style={{ fontSize: "22px", display: "flex", justifyContent: "center" }}>Request Sent!</h5>
            </GridItem>
            <GridItem xs={12}>
              <Typography className={classes.modalCardDesc} style={{ fontSize: "18px" }}>
                Thanks for requesting a trip package! A travel concierge will reach out shortly to assist in your booking.
              </Typography>
            </GridItem>
            <GridItem xs={12} style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
              <Button
                onClick={() => setConfirmModal(false)}
                className={classes.modalButton}
                style={{ background: "#1A405F", }}>
                <Cancel style={{ marginRight: "10px", marginTop: "-5px" }} /> Close
              </Button>
            </GridItem>
          </GridContainer>
        </Card>
      </Dialog>
      <DialogLoader showModal={showModal} />
    </div>
  );
}

function mapStateToProps(state, location) {
  return {
    user: state.auth.user,
    userId: state.auth.user.uid,
    filterName: location.history.location.state?.filterName,
    cart: state.cart.cart,
    rewards: state.rewards.rewards,
    wishlist: state.wishlist.wishlist,
    wishlistLoaded: state.wishlist.initDataRetrieved,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    addProduct: (product) => dispatch(addProduct(product)),
    addToWishList: (data, uid) => dispatch(addToWishList(data, uid)),
    removeFromWishlist: (data, uid, index) =>
      dispatch(removeFromWishlist(data, uid, index)),
  });
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
  )(TravelPage)
);

