import React, { useState, createRef, useEffect } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { db, firebase, storage } from "firebase/client";
import UserInfo from "./components/UserInfo";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import validator from "validator";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { Typography } from "@material-ui/core";

import {
  updateUserData,
  updateUserPhoto,
  updateUserLogo,
  deleteUserPhoto,
  deleteUserContract,
} from "store/actions/auth.js";

// @material-ui/icons
import { ReactComponent as Delete } from "assets/img/tuzo/icons/Delete.svg";
import { CheckCircle } from "@material-ui/icons";

//styling
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";
import { sendIsoSignUpLinkLive } from "firebase/client";
import "./calendar.css";
import { sendIrisCloudFunction } from "firebase/client";
import { sendCloudFunction } from "firebase/client";
import { sendMavCloudFunction } from "firebase/client";
import DialogLoader from "components/Loader/DialogLoader";
import { sendOnboardNotification } from "firebase/client";

const processorArr = ["Fiserv AccessOne", "IRIS", "Maverick"];

export function NewProfile(props) {
  const {
    classes,
    history,
    profilePic,
    userCompany,
    logo,
    contactUrl,
    userEmail,
    userApiInfo,
    user,
  } = props;

  const EmailValidation = require("emailvalid");
  const ev = new EmailValidation({ allowFreemail: true, allowDisposable: true, whitelist: ['att.net', 'ymail.com'] });
  const [userBasisPts, setUserBasisPts] = useState("");
  const [signUpBonus, setSignUpBonus] = useState("");
  const [merchantId, setMerchantId] = useState("");
  const [username, setUsername] = useState("");
  const [firstNameState, setFirstNameState] = useState("");
  const [lastNameState, setLastNameState] = useState("");
  const [emailState, setEmailState] = useState("");
  const [cellPhoneState, setCellPhoneState] = useState("");
  const [secondaryPhoneState, setSecondaryPhoneState] = useState("");
  const [uploading, setUploading] = useState(false);
  const [addressState, setAddressState] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [companyState, setCompanyState] = useState("");
  const [ein, setEin] = useState("");
  const [notes, setNotes] = useState("");
  const [processorName, setProcessorName] = useState(
    userApiInfo ? userApiInfo[0].apiType : "Fiserv AccessOne"
  );
  const [apiUsername, setApiUsername] = useState(
    userApiInfo ? userApiInfo[0].apiKey : ""
  );
  const [apiPassword, setApiPassword] = useState(
    userApiInfo ? userApiInfo[0].apiSecret : ""
  );
  const [accountId, setAccountId] = useState("");
  const [processorPhone, setProcessorPhone] = useState("");
  const [processorEmail, setProcessorEmail] = useState("");
  const [processorMid, setProcessorMid] = useState("");
  const [docFile, setDocFile] = useState([]);
  const [progress, setProgress] = useState(0);
  const [apiInfo, setApiInfo] = useState(userApiInfo ? userApiInfo : [
    {
      apiType: "Fiserv AccessOne",
      apiKey: "",
      apiSecret: "",
      status: "Unused",
      id: 0,
    },
    {
      apiType: "IRIS",
      apiKey: "",
      apiSecret: "",
      status: "Unused",
      id: 1,
    },
    {
      apiType: "Maverick",
      apiKey: "",
      apiSecret: "",
      status: "Unused",
      id: 2,
    },
  ]);
  const [errors, setErrors] = useState({
    points: false,
    processor: false,
    dba: false,
    docFile: false,
    email: false,
    emailTypo: false,
    id: false,
    username: false,
    firstName: false,
    lastName: false,
    apiUsername: false,
    apiPassword: false,
  });

  useEffect(() => {
    if (progress === 100) {
      setUploading(false);
    }
  }, [progress]);
  const [showModal, setShowModal] = useState(false)

  const handleSelect = (address, placeholder) => {
    geocodeByAddress(address).then((results) => {
      const lat = results[0].geometry.location.lat();
      const lng = results[0].geometry.location.lng();
      if (placeholder === "Search your pick up address..") {
      }
      if (placeholder === "Search the address..") {
        const addressResults = results[0];
        addressResults.address_components.forEach((r) => {
          if (r.types.includes("locality")) {
            setCity(r.long_name);
          }
          if (r.types.includes("administrative_area_level_1")) {
            setState(r.short_name);
          }
          if (r.types.includes("postal_code")) {
            setZipCode(r.short_name);
          }
        });
        setAddressState(addressResults.formatted_address);
      }
    });
  };

  const sendCustomerEmail = async (e) => {
    e.preventDefault();
    const emailCheck = ev.check(emailState);
    if (
      processorName.includes("Fiserv AccessOne") &&
      (!userBasisPts ||
        !companyState ||
        !emailState ||
        // !docFile ||
        !merchantId ||
        !username ||
        !apiUsername ||
        !apiPassword ||
        !firstNameState ||
        !lastNameState ||
        emailCheck.valid === false)
    ) {
      let newErrs = {
        points: false,
        processor: false,
        docFile: false,
        dba: false,
        email: false,
        id: false,
        username: false,
        firstName: false,
        lastName: false,
        emailTypo: false,
        apiUsername: false,
        apiPassword: false,
      };
      if (!userBasisPts) {
        newErrs = { ...newErrs, points: true };
      }
      if (!companyState) {
        newErrs = { ...newErrs, dba: true };
      }
      if (!emailState) {
        newErrs = { ...newErrs, email: true };
      }
      if (emailCheck.valid === false) {
        newErrs = { ...newErrs, emailTypo: true };
      }
      if (!merchantId) {
        newErrs = { ...newErrs, id: true };
      }
      if (!username) {
        newErrs = { ...newErrs, username: true };
      }
      if (!firstNameState) {
        newErrs = { ...newErrs, firstName: true };
      }
      if (!lastNameState) {
        newErrs = { ...newErrs, lastName: true };
      }
      if (!apiUsername) {
        newErrs = { ...newErrs, apiUsername: true };
      }
      if (!apiPassword) {
        newErrs = { ...newErrs, apiPassword: true };
      }
      // if (!docFile.length) {
      //   newErrs = { ...newErrs, docFile: true };
      // }
      setErrors({ ...newErrs });
    } else if (
      processorName.includes("IRIS") &&
      (!userBasisPts ||
        !companyState ||
        !emailState ||
        // !docFile ||
        !merchantId ||
        !username ||
        !apiUsername ||
        !firstNameState ||
        !lastNameState ||

        emailCheck.valid === false)
    ) {
      let newErrs = {
        points: false,
        processor: false,
        dba: false,
        docFile: false,
        email: false,
        id: false,
        username: false,
        firstName: false,
        lastName: false,
        emailTypo: false,
        apiUsername: false,
      };
      if (!userBasisPts) {
        newErrs = { ...newErrs, points: true };
      }
      if (!companyState) {
        newErrs = { ...newErrs, dba: true };
      }
      if (!emailState) {
        newErrs = { ...newErrs, email: true };
      }
      if (emailCheck.valid === false) {
        newErrs = { ...newErrs, emailTypo: true };
      }
      if (!merchantId) {
        newErrs = { ...newErrs, id: true };
      }
      if (!username) {
        newErrs = { ...newErrs, username: true };
      }
      if (!apiUsername) {
        newErrs = { ...newErrs, apiUsername: true };
      }
      if (!firstNameState) {
        newErrs = { ...newErrs, firstName: true };
      }
      if (!lastNameState) {
        newErrs = { ...newErrs, lastName: true };
      }
      // if (!docFile.length) {
      //   newErrs = { ...newErrs, docFile: true };
      // }
      setErrors({ ...newErrs });
    } else if (
      processorName.includes("Maverick") &&
      (!userBasisPts ||
        !companyState ||
        // !docFile ||
        !emailState ||
        !merchantId ||
        !username ||
        !apiUsername ||
        !accountId ||
        !firstNameState ||
        !lastNameState ||

        emailCheck.valid === false)
    ) {
      let newErrs = {
        points: false,
        processor: false,
        dba: false,
        docFile: false,
        email: false,
        id: false,
        username: false,
        firstName: false,
        lastName: false,
        emailTypo: false,
        apiUsername: false,
        accountId: false,
      };
      if (!userBasisPts) {
        newErrs = { ...newErrs, points: true };
      }
      if (!companyState) {
        newErrs = { ...newErrs, dba: true };
      }
      if (!emailState) {
        newErrs = { ...newErrs, email: true };
      }
      if (emailCheck.valid === false) {
        newErrs = { ...newErrs, emailTypo: true };
      }
      if (!merchantId) {
        newErrs = { ...newErrs, id: true };
      }
      if (!username) {
        newErrs = { ...newErrs, username: true };
      }
      if (!apiUsername) {
        newErrs = { ...newErrs, apiUsername: true };
      }
      if (!accountId) {
        newErrs = { ...newErrs, accountId: true };
      }
      if (!firstNameState) {
        newErrs = { ...newErrs, firstName: true };
      }
      if (!lastNameState) {
        newErrs = { ...newErrs, lastName: true };
      }
      // if (!docFile.length) {
      //   newErrs = { ...newErrs, docFile: true };
      // }
      setErrors({ ...newErrs });
    }
    else {
      setShowModal(true)
      const docId = db.collection('users').doc().id
      let tempData = {}
      if (processorName.includes("Maverick")) {
        tempData = {
          apiInfo,
          username,
          firstName: firstNameState,
          lastName: lastNameState,
          docFile: docFile,
          IsoAdminCompanyName: userCompany,
          contactUrl: contactUrl ? contactUrl : "",
          companyName: companyState,
          DBA: companyState,
          shippingAddress: addressState,
          city,
          state,
          zipCode,
          EIN: ein,
          userBasisPts: Number(userBasisPts),
          merchantId,
          email: emailState,
          id: docId,
          processor: processorName,
          processorMid,
          processorPhone,
          processorEmail,
          notes,
          signUpBonus,
          logo: logo ? logo : "",
          emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
          IsoStaffMember: userEmail,
          apiUsername,
          apiPassword,
          userType: "IsoMerchants",
          accountId,
          status: "Pending",
        };
      } else {
        tempData = {
          apiInfo,
          username,
          firstName: firstNameState,
          lastName: lastNameState,
          docFile: docFile,
          IsoAdminCompanyName: userCompany,
          contactUrl: contactUrl ? contactUrl : "",
          companyName: companyState,
          DBA: companyState,
          shippingAddress: addressState,
          city,
          state,
          zipCode,
          EIN: ein,
          userBasisPts: Number(userBasisPts),
          merchantId,
          email: emailState,
          id: docId,
          processor: processorName,
          processorMid,
          processorPhone,
          processorEmail,
          notes,
          signUpBonus,
          logo: logo ? logo : "",
          emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
          IsoStaffMember: userEmail,
          apiUsername,
          apiPassword,
          userType: "IsoMerchants",
          status: "Pending",
        };
      }
      await db
        .collection("users")
        .doc(docId)
        .set(tempData)
        .then(async () => {
          let newObj = {
            email: emailState,
            firstName: firstNameState,
            lastName: lastNameState,
            id: docId,
            headerImg: user.branding !== "Default" && profilePic ? profilePic : "https://firebasestorage.googleapis.com/v0/b/tuzo-379415.appspot.com/o/EmailImg%2FEmailHeader.png?alt=media&token=f03aab60-d827-408c-9679-01688999f5dd",
            footerImg: user.branding === "Priority" && profilePic ? profilePic : "https://firebasestorage.googleapis.com/v0/b/tuzo-379415.appspot.com/o/EmailImg%2FtuzoHorizontal.png?alt=media&token=f161b013-c40c-49f2-b6d7-468beae5a488",
            domain: window.location.host === "www.wpirewards.com" || user.companyName === "Wholesale Payments" ? "notifications@wpirewards.com" : "notifications@tuzorewards.com",
            emailTitle: window.location.host === "www.wpirewards.com" || user.companyName === "Wholesale Payments" ? "WPI Rewards" : "Tuzo Rewards",
            emailName: window.location.host === "www.wpirewards.com" || user.companyName === "Wholesale Payments" ? "WPI" : "Tuzo",
            partnerImage: user.branding !== false && user.partnerImage? user.partnerImage : user.branding !== false && user.logo? user.logo :"https://firebasestorage.googleapis.com/v0/b/tuzo-379415.appspot.com/o/EmailImg%2FbaseImage.png?alt=media&token=69efb148-4c25-4a49-b2b3-328d314e3ae5",
            isoName: user.IsoAdminCompanyName ? user.IsoAdminCompanyName : user.companyName ? user.companyName :"Your ISO company",
            linkUrl: window.location.host === "www.wpirewards.com" || user.companyName === "Wholesale Payments" ? `https://www.wpirewards.com/auth/iso-merchant-register/${docId}` : `https://www.tuzo.app/auth/iso-merchant-register/${docId}`,
            supportEmail: window.location.host === "www.wpirewards.com" || user.companyName === "Wholesale Payments" ? "info@wpirewards.com" : "info@tuzorewards.com",
            supportPhone: window.location.host === "www.wpirewards.com" || user.companyName === "Wholesale Payments" ? "Support Phone" : "929-367-8896",
          }
          let adminEmailObj = {
            email: emailState,
            firstName: firstNameState,
            lastName: lastNameState,
            companyName: companyState,
            isoName: user.companyName,
            merchantId: merchantId,
          }
          await sendOnboardNotification(adminEmailObj)
          await sendIsoSignUpLinkLive(newObj)
        })
        .then(async () => {
          let idObj = {
            merchantId: merchantId,
            apiUsername: apiUsername,
            apiPassword: apiPassword,
            apiUrl: user.IsoAdminCompanyName.replace(/ /g, "").toLowerCase(),
            accountId: accountId,
          };
          if (processorName.includes("IRIS")) {
            await sendIrisCloudFunction(idObj);
          } else if (processorName.includes("Maverick")) {
            await sendMavCloudFunction(idObj);
          } else {
            await sendCloudFunction(idObj);
          }
        })
        .then(() => {
          history.push(
            `/iso-staff/${userCompany
              .replace(/ /g, "")
              .toLowerCase()}/merchants`
          );
        });
    }
  };

  const handleDocUpload = (event) => {
    var file = event.target.files[0];

    const storageRef = storage.ref("contracts");
    let fileRef;

    fileRef = storageRef.child(file.name);

    var uploadTask = fileRef.put(file);

    // fileRef.put(file).then((snapshot) => {
    setUploading(true);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Documnet Upload is running");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      (snapshot) => {
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then((downloadURL) => {
            const fileName = uploadTask.snapshot.ref.name;

            const uploadInfo = {
              downloadURL: downloadURL,
              filePath: `contracts/${fileName}`,
              fileName: fileName,
            };

            console.log(uploadInfo);

            setDocFile((prevDocFile) => [...prevDocFile, uploadInfo]);
          })
          .catch(() => { });
      }
    );
  };

  const deleteDoc = (fileName, filePath) => {
    setDocFile((prevDocFile) =>
      prevDocFile.filter((file) => file.filePath !== filePath)
    );

    props.deleteUserContract(fileName, filePath);
  };

  return (
    <div>
      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem xs={8} style={{ display: "flex" }}>
            <Typography variant="h2" className={classes.headerTitle}>
              User Onboarding Form
            </Typography>
            <div>
              <Button
                className={classes.editButton}
                style={{ background: "#1A405F", width: "128px" }}
                onClick={sendCustomerEmail}
              >
                <CheckCircle className={classes.icons} /> Send
              </Button>
              <Button
                onClick={() => history.goBack()}
                className={classes.editButton}
                style={{ background: "#E2A400", width: "143px" }}
              >
                <Delete className={classes.icons} /> Cancel
              </Button>
            </div>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} style={{ marginTop: "20px" }}>
            <UserInfo
              progress={progress}
              uploading={uploading}
              docFile={docFile}
              handleDocUpload={handleDocUpload}
              deleteDoc={deleteDoc}
              merchantId={merchantId}
              setMerchantId={setMerchantId}
              userBasisPts={userBasisPts}
              setUserBasisPts={setUserBasisPts}
              signUpBonus={signUpBonus}
              setSignUpBonus={setSignUpBonus}
              profilePic={profilePic}
              username={username}
              setUsername={setUsername}
              firstNameState={firstNameState}
              setFirstNameState={setFirstNameState}
              lastNameState={lastNameState}
              setLastNameState={setLastNameState}
              companyState={companyState}
              setCompanyState={setCompanyState}
              ein={ein}
              setEin={setEin}
              notes={notes}
              setNotes={setNotes}
              processorName={processorName}
              setProcessorName={setProcessorName}
              processorPhone={processorPhone}
              setProcessorPhone={setProcessorPhone}
              processorEmail={processorEmail}
              setProcessorEmail={setProcessorEmail}
              processorMid={processorMid}
              setProcessorMid={setProcessorMid}
              emailState={emailState}
              setEmailState={setEmailState}
              cellPhoneState={cellPhoneState}
              setCellPhoneState={setCellPhoneState}
              secondaryPhoneState={secondaryPhoneState}
              setSecondaryPhoneState={setSecondaryPhoneState}
              addressState={addressState}
              setAddressState={setAddressState}
              handleSelect={handleSelect}
              processorArr={processorArr}
              errors={errors}
              apiUsername={apiUsername}
              setApiUsername={setApiUsername}
              apiPassword={apiPassword}
              setApiPassword={setApiPassword}
              accountId={accountId}
              setAccountId={setAccountId}
            />
          </GridItem>
        </GridContainer>
        <DialogLoader showModal={showModal} />
      </Hidden>
    </div>
  );
}

NewProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    userCompany: state.auth.user.companyName,
    logo: state.auth.user.logo,
    contactUrl: state.auth.user?.contactUrl,
    userEmail: state.auth.user.email,
    userApiInfo: state.auth.user?.apiInfo,
    user: state.auth.user,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    updateUserData: (uid, newInfo) => dispatch(updateUserData(uid, newInfo)),
    updateUserPhoto: (id, document) => dispatch(updateUserPhoto(id, document)),
    deleteUserPhoto: (id) => dispatch(deleteUserPhoto(id)),
    deleteUserContract: (fileName, filePath) =>
      dispatch(deleteUserContract(fileName, filePath)),
    updateUserLogo: (id, document) => dispatch(updateUserLogo(id, document)),
  });
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(NewProfile);
