import React, { useEffect } from "react";
import DonutChart from "react-donut-chart";

// core components
import Box from "@material-ui/core/Box";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import actionsForNavigation from "store/actions/navigation";
import AuthActions from "store/actions/auth";
import { actionsForDialog } from "store/actions/dialog";
import TermsAndConditionsDialog from "views/Components/TermsAndConditionsDialog";
import Slide from "@material-ui/core/Slide";
import Hidden from "@material-ui/core/Hidden";
import { db } from "firebase/client";
// @material-ui/icons
import { AiOutlineDollar } from "react-icons/ai";
import { Add } from "@material-ui/icons";

import Style from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import withStyles from "@material-ui/core/styles/withStyles";
import { useState } from "react";
import Filter from "./components/Filter";
import { setLists } from "store/actions/listsActions";
import Table from "./components/Table";
import { Link, Typography } from "@material-ui/core";
import Activity from "../activity/Activity";
import { drop } from "lodash";
import Loader from "components/Loader/Loader";

const statusArr = ["Date", "Order ID", "User Name", "Reward Name", "Total"];

const ascArr = ["Asc", "Desc"];

export function AdminOrdersPage(props) {
  const { classes, history, userType, userId, dropdownValue } = props;
  const [rewardsSwitch, setRewardsSwitch] = useState("Order History");
  const [statusDropdown, setStatusDropdown] = useState("Date");
  const [ascDropdown, setAscDropdown] = useState("Asc");
  const [searchString, setSearchString] = useState("");
  const itemsPerPage = 6;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPage);
  const [ordersArr, setOrdersArr] = useState(false);
  const [copyArr, setCopyArr] = useState([]);
  const [merchantLen, setMerchantLen] = useState("");
  const [transactionLen, setTransactionLen] = useState("");

  const numberFormatter = new Intl.NumberFormat("en-US", {});

  useEffect(() => {
    let noSpaceStr = dropdownValue.replace(/\s/g, "").replace(/\//g,"");
    let newStr = noSpaceStr.charAt(0).toLowerCase() + noSpaceStr.slice(1);
    let filtered = [];
    Object.keys(copyArr).map((key) => {
      let val = copyArr[key]["categories"];
      Object.keys(val).map((valKey) => {
        if (val[valKey] === true && valKey === newStr) {
          copyArr[key]["filteredCategory"] = valKey;
          filtered.push(copyArr[key]);
        }
      });
    });
    resetPageFilter(filtered);
  }, [dropdownValue]);

  const handleRewardsToggle = (value) => {
    setRewardsSwitch(value);
  };

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage);
    setEndIndex(selected * itemsPerPage + itemsPerPage);
  };

  const handleSearch = () => {
    if (searchString) {
      const filtered = copyArr.filter((f) => {
        if (f.rewardName.toLowerCase().includes(searchString.toLowerCase())) {
          return true;
        }
      });
      setOrdersArr(filtered);
    } else {
      setOrdersArr(copyArr);
    }
  };

  const resetPageFilter = (filterArr) => {
    setOrdersArr([...filterArr]);
    setIndex(0);
    setEndIndex(itemsPerPage);
    setPageNumber(0);
  };

  const handleFilter = (ascValue, sortValue) => {
    if (ascValue === "Asc") {
      if (sortValue === "Order ID") {
        const filtered = copyArr.sort(function (a, b) {
          if (a.orderId < b.orderId) {
            return -1;
          }
          if (a.orderId > b.orderId) {
            return 1;
          }
          return 0;
        });
        resetPageFilter(filtered);
      }
      if (sortValue === "Date") {
        const filtered = copyArr.sort(function (a, b) {
          return a.orderDate.seconds - b.orderDate.seconds;
        });
        resetPageFilter(filtered);
      }
      if (sortValue === "User Name") {
        const filtered = copyArr.sort(function (a, b) {
          if (a.lastName < b.lastName) {
            return -1;
          }
          if (a.lastName > b.lastName) {
            return 1;
          }
          return 0;
        });
        resetPageFilter(filtered);
      }
      if (sortValue === "Reward Name") {
        const filtered = copyArr.sort(function (a, b) {
          if (a.rewardName < b.rewardName) {
            return -1;
          }
          if (a.rewardName > b.rewardName) {
            return 1;
          }
          return 0;
        });
        resetPageFilter(filtered);
      }
      if (sortValue === "Total") {
        const filtered = copyArr.sort((a, b) =>
          a.salePricePoints
            .toString()
            .localeCompare(b.salePricePoints.toString(), undefined, {
              numeric: true,
              sensitivity: "base",
            })
        );
        resetPageFilter(filtered);
      }
    }
    if (ascValue === "Desc") {
      if (sortValue === "Order ID") {
        const filtered = copyArr.sort(function (a, b) {
          if (a.orderId > b.orderId) {
            return -1;
          }
          if (a.orderId < b.orderId) {
            return 1;
          }
          return 0;
        });
        resetPageFilter(filtered);
      }
      if (sortValue === "Date") {
        const filtered = copyArr.sort(function (a, b) {
          return b.orderDate.seconds - a.orderDate.seconds;
        });
        resetPageFilter(filtered);
      }
      if (sortValue === "User Name") {
        const filtered = copyArr.sort(function (a, b) {
          if (a.lastName > b.lastName) {
            return -1;
          }
          if (a.lastName < b.lastName) {
            return 1;
          }
          return 0;
        });
        resetPageFilter(filtered);
      }
      if (sortValue === "Reward Name") {
        const filtered = copyArr.sort(function (a, b) {
          if (a.rewardName > b.rewardName) {
            return -1;
          }
          if (a.rewardName < b.rewardName) {
            return 1;
          }
          return 0;
        });
        resetPageFilter(filtered);
      }
      if (sortValue === "Total") {
        const filtered = copyArr.sort((a, b) =>
          b.salePricePoints
            .toString()
            .localeCompare(a.salePricePoints.toString(), undefined, {
              numeric: true,
              sensitivity: "base",
            })
        );
        resetPageFilter(filtered);
      }
    }
  };

  const getData = () => {
    const orders = [];
    db.collection("orders")
      .get()
      .then((snapshots) => {
        if (!snapshots.empty) {
          snapshots.forEach((doc) => {
            let docId = doc.data().orderData[0].orderId;
            let orderData = doc.data().orderData;
            for (let i = 0; i < orderData.length; i++) {
              orders.docId = docId;
              orders.push(orderData[i]);
            }
          });
          setOrdersArr(orders);
          setCopyArr(orders);
        }
      });
  };

  const getUserCount = async () => {
    const merchDb = db.collection("merchants-live");
    let docArr = [];
    const arr = [];
    let count = 0;
    let transCount = 0;
    await merchDb.get().then(async (querySnapshot) => {
      querySnapshot.docs.forEach(async (doc) => {
        let docId = doc.data().docId;
        let mid = doc.data().merchantNumber;
        arr.push(doc.data());
        await db
          .collection("users")
          .where("merchantId", "==", mid)
          .get()
          .then((snapshots) => {
            if (!snapshots.empty) {
              snapshots.forEach((s) => {
                s = s.data();
              });
            } else {
              return;
            }
          });
        await merchDb
          .doc(docId)
          .collection("batch-summary")
          .get()
          .then((subSnapshot) => {
            subSnapshot.docs.forEach((subDoc) => {
              subDoc = subDoc.data();
              if (subDoc.approved == undefined && subDoc.denied == undefined) {
                let newObj = {
                  ...subDoc,
                  parentDocId: docId,
                };
                transCount += Number(subDoc.transactionCount);
                docArr.push(newObj);
              }
            });
          });
        count++;
        if (count === querySnapshot.docs.length) {
          setMerchantLen(count);
          setTransactionLen(transCount);
        }
      });
    });
  };

  useEffect(() => {
    getUserCount();
    getData();
  }, []);

  if (!ordersArr || !transactionLen) return <Loader fromLogin={false} />;

  return (
    <div>
      <Hidden mdUp implementation="css">
        <div>
          <Typography
            variant="h2"
            className={classes.dashboardTitle}
            style={{ color: "#1A405F" }}
          >
            Please View Page on Desktop / Laptop
          </Typography>
        </div>
      </Hidden>
      <Hidden smDown implementation="css">
        <GridContainer style={{ marginTop: "-30px" }}>
          <GridItem
            xs={3}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h2"
              className={classes.dashboardTitle}
              style={{ paddingBottom: "10%" }}
            >
              Orders
            </Typography>
            <Button
              className={classes.addButton}
              onClick={() => history.push("/admin/assign-reward")}
            >
              <Add /> Assign Reward
            </Button>
          </GridItem>
          <GridItem xs={9}>
            <Card className={classes.card}>
              <GridContainer style={{ marginTop: "10px", marginBottom: "5px" }}>
                <GridItem xs={2}>
                  <AiOutlineDollar style={{ marginTop: "5px" }} />
                </GridItem>
                <GridItem xs={5}>Total Merchants</GridItem>
                <GridItem
                  xs={5}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontWeight: 600,
                    fontSize: "24px",
                  }}
                >
                  {numberFormatter.format(merchantLen)}
                </GridItem>
              </GridContainer>
              <div style={{ borderTop: "2px solid #FECD2C" }} />
              <GridContainer style={{ marginTop: "15px" }}>
                <GridItem xs={2}>
                  <AiOutlineDollar style={{ marginTop: "5px" }} />
                </GridItem>
                <GridItem xs={5}>Total Transactions</GridItem>
                <GridItem
                  xs={5}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontWeight: 600,
                    fontSize: "24px",
                  }}
                >
                  {numberFormatter.format(transactionLen)}
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
          {/* <GridItem xs={7} style={{ marginBottom: "0px" }}>
                        <Card className={classes.card}>
                            <GridContainer style={{ marginTop: "20px" }}>
                                <GridItem xs={3}>
                                    <p className={classes.circleChartDesc}>
                                        Product Popularity by {"\n"}Category
                                    </p>
                                </GridItem>
                                <GridItem xs={2} className={classes.circleChartGridContainer}>
                                    <div style={{ display: "flex" }}>
                                        <DonutChart
                                            data={[
                                                {
                                                    // label: 'Luxury Goods',
                                                    value: 50,
                                                },
                                                {
                                                    label: '',
                                                    value: 50,
                                                    isEmpty: true,
                                                },
                                            ]}
                                            innerRadius={.5}
                                            outerRadius={.9}
                                            legend={false}
                                            interactive={false}
                                            height={100}
                                            width={50}
                                            formatValues={() => `${(50 / 100 * 100).toFixed(2)}%`}
                                            colors={["#56CA93"]}
                                            strokeColor='#56CA93'
                                            colorFunction={(colors, index) => colors[(index % colors.length)]}
                                            emptyColor={"#1A405F"}
                                        />
                                        <p className={classes.circleChartLabel}>
                                            Luxury{"\n"}Goods{"\n"}50%
                                        </p>
                                    </div>
                                </GridItem>
                                <GridItem xs={2} className={classes.circleChartGridContainer}>
                                    <div style={{ display: "flex" }}>
                                        <DonutChart
                                            data={[
                                                {
                                                    // label: 'Luxury Goods',
                                                    value: 30,
                                                },
                                                {
                                                    label: '',
                                                    value: 100 - 30,
                                                    isEmpty: true,
                                                },
                                            ]}
                                            innerRadius={.5}
                                            outerRadius={.9}
                                            legend={false}
                                            interactive={false}
                                            height={100}
                                            width={50}
                                            formatValues={() => `${(50 / 100 * 100).toFixed(2)}%`}
                                            colors={["#56CA93"]}
                                            strokeColor='#56CA93'
                                            colorFunction={(colors, index) => colors[(index % colors.length)]}
                                            emptyColor={"#1A405F"}
                                        />
                                        <p className={classes.circleChartLabel}>
                                            Experiences{"\n"}Goods{"\n"}30%
                                        </p>
                                    </div>
                                </GridItem>
                                <GridItem xs={2} className={classes.circleChartGridContainer}>
                                    <div style={{ display: "flex" }}>
                                        <DonutChart
                                            data={[
                                                {
                                                    // label: 'Luxury Goods',
                                                    value: 15,
                                                },
                                                {
                                                    label: '',
                                                    value: 100 - 15,
                                                    isEmpty: true,
                                                },
                                            ]}
                                            innerRadius={.5}
                                            outerRadius={.9}
                                            legend={false}
                                            interactive={false}
                                            height={100}
                                            width={50}
                                            formatValues={() => `${(50 / 100 * 100).toFixed(2)}%`}
                                            colors={["#56CA93"]}
                                            strokeColor='#56CA93'
                                            colorFunction={(colors, index) => colors[(index % colors.length)]}
                                            emptyColor={"#1A405F"}
                                        />
                                        <p className={classes.circleChartLabel}>
                                            Luxury{"\n"}Goods{"\n"}15%
                                        </p>
                                    </div>
                                </GridItem>
                                <GridItem xs={2} className={classes.circleChartGridContainer}>
                                    <div style={{ display: "flex" }}>
                                        <DonutChart
                                            data={[
                                                {
                                                    // label: 'Luxury Goods',
                                                    value: 10,
                                                },
                                                {
                                                    label: '',
                                                    value: 100 - 10,
                                                    isEmpty: true,
                                                },
                                            ]}
                                            innerRadius={.5}
                                            outerRadius={.9}
                                            legend={false}
                                            interactive={false}
                                            height={100}
                                            width={50}
                                            formatValues={() => `${(50 / 100 * 100).toFixed(2)}%`}
                                            colors={["#56CA93"]}
                                            strokeColor='#56CA93'
                                            colorFunction={(colors, index) => colors[(index % colors.length)]}
                                            emptyColor={"#1A405F"}
                                        />
                                        <p className={classes.circleChartLabel}>
                                            Other{"\n"}Goods{"\n"}10%
                                        </p>
                                    </div>
                                </GridItem>
                            </GridContainer >
                        </Card>
                    </GridItem> */}
          <GridItem xs={12}>
            <Card className={classes.cardTable} style={{ marginTop: "0px" }}>
              <GridContainer>
                <GridItem xs={12}>
                  <Filter
                    rewardsSwitch={rewardsSwitch}
                    setRewardsSwitch={setRewardsSwitch}
                    statusDropdown={statusDropdown}
                    setStatusDropdown={setStatusDropdown}
                    ascDropdown={ascDropdown}
                    setAscDropdown={setAscDropdown}
                    searchString={searchString}
                    setSearchString={setSearchString}
                    statusArr={statusArr}
                    ascArr={ascArr}
                    handleRewardsToggle={handleRewardsToggle}
                    handleSearch={handleSearch}
                    handleFilter={handleFilter}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Table
                    history={history}
                    ordersArr={ordersArr}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    index={index}
                    setIndex={setIndex}
                    endIndex={endIndex}
                    setEndIndex={setEndIndex}
                    itemsPerPage={itemsPerPage}
                    handlePageClick={handlePageClick}
                  />
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
        </GridContainer>
      </Hidden>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userType: state.auth.user.userType,
    userId: state.auth.user.uid,
    dropdownValue: state.dropdown.dropdown,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    edit: (key) =>
      dispatch(
        actionsForNavigation.openChild("Profile Editor", "/admin/user/editor")
      ),
    logout: () => dispatch(AuthActions.startLogout()),
  });
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
  )(AdminOrdersPage)
);
