import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

// @material-ui/icons
import { AiFillGift } from 'react-icons/ai';
import { ReactComponent as Diamond } from "assets/img/tuzo/icons/DiamondYellow.svg";
import { ReactComponent as Plane } from "assets/img/tuzo/icons/PlaneYellow.svg";
import { ReactComponent as Computer } from "assets/img/tuzo/icons/ComputerYellow.svg";
import { ReactComponent as SportsandOutdoors } from "assets/img/tuzo/icons/SportsAndOutdoors.svg"
import { ReactComponent as GiftCard } from "assets/img/tuzo/icons/GiftCardYellow.svg";
import { ReactComponent as RaffleIcon } from "assets/img/tuzo/icons/RaffleYellow.svg";

// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/activityStyle';
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import { Card, Hidden, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from 'components/Grid/GridItem';

export function PointsSpent(props) {
    const { classes, history, userPts } = props;
    return (
        <div>
            <Hidden mdUp implementation="css">
                <Card className={classes.card}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Typography className={classes.cardDescMobile}>
                                Points Spent on Rewards
                            </Typography>
                        </GridItem>

                        <GridItem xs={12} style={{ marginTop: "10px" }}>
                            <Typography className={classes.cardDescMobile}>
                                {userPts.toLocaleString()} <span className={classes.cardDescPoints}>Points</span>
                            </Typography>

                        </GridItem>

                        <GridItem xs={12} style={{ display: "flex", flexDirection: "column" }}>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <div className={classes.divColumnFlex2Mobile}>
                                        <Typography className={classes.cardDescPointsMobile}>
                                            <Plane className={classes.icons} />  Travel
                                        </Typography>

                                        <Typography className={classes.cardDescPointsMobile}>
                                            <Diamond className={classes.icons} />  Luxury Goods
                                        </Typography>

                                        <Typography className={classes.cardDescPointsMobile}>
                                            <SportsandOutdoors className={classes.icons} />  Sports/Outdoors
                                        </Typography>

                                        <Typography className={classes.cardDescPointsMobile}>
                                            <Computer className={classes.icons} />  Electronics
                                        </Typography>

                                        <Typography className={classes.cardDescPointsMobile}>
                                            <RaffleIcon className={classes.icons} />  Home/Kitchen
                                        </Typography>

                                        <Typography className={classes.cardDescPointsMobile}>
                                            <GiftCard className={classes.icons} />  Gift Cards
                                        </Typography>
                                    </div>
                                </GridItem>

                                <GridItem xs={6}>
                                    <div className={classes.divColumnFlex2Mobile}>
                                        <Typography className={classes.cardDescSmallEndMobile}>
                                            0
                                        </Typography>

                                        <Typography className={classes.cardDescSmallEndMobile}>
                                            0
                                        </Typography>

                                        <Typography className={classes.cardDescSmallEndMobile}>
                                            0
                                        </Typography>

                                        <Typography className={classes.cardDescSmallEndMobile}>
                                            0
                                        </Typography>

                                        <Typography className={classes.cardDescSmallEndMobile}>
                                            0
                                        </Typography>

                                        <Typography className={classes.cardDescSmallEndMobile}>
                                            0
                                        </Typography>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </Card>
            </Hidden>

            <Hidden smDown implementation="css">
                <Card className={classes.card} style={{ minHeight: "193px" }}>
                    <GridContainer>
                        <GridItem xs={6}>
                            <Typography className={classes.cardDesc}>
                                Points Spent on Rewards
                            </Typography>
                        </GridItem>

                        <GridItem xs={6} style={{ display: "flex", justifyContent: "flex-end", whiteSpace: "noWrap", }}>
                            <Typography className={classes.cardDesc}>
                                {userPts.toLocaleString()} <span className={classes.cardDescPoints}>Points</span>
                            </Typography>
                        </GridItem>

                        <GridItem xs={4} style={{ display: "flex", flexDirection: "column" }}>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <div className={classes.divColumnFlex2}>
                                        <Typography className={classes.cardDescPoints}>
                                            <Plane className={classes.icons} />  Travel
                                        </Typography>

                                        <Typography className={classes.cardDescPoints}>
                                            <Diamond className={classes.icons} />  Luxury Goods
                                        </Typography>

                                    </div>
                                </GridItem>

                                <GridItem xs={6}>
                                    <div className={classes.divColumnFlex2}>
                                        <Typography className={classes.cardDescSmallEnd}>
                                            0
                                        </Typography>

                                        <Typography className={classes.cardDescSmallEnd}>
                                            0
                                        </Typography>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </GridItem>

                        <GridItem xs={4} style={{ display: "flex", flexDirection: "column" }}>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <div className={classes.divColumnFlex2}>

                                        <Typography className={classes.cardDescPoints}>
                                            <SportsandOutdoors className={classes.icons} />  Sports/Outdoors
                                        </Typography>

                                        <Typography className={classes.cardDescPoints}>
                                            <Computer className={classes.icons} />  Electronics
                                        </Typography>
                                    </div>
                                </GridItem>

                                <GridItem xs={6}>
                                    <div className={classes.divColumnFlex2}>
                                        <Typography className={classes.cardDescSmallEnd}>
                                            0
                                        </Typography>

                                        <Typography className={classes.cardDescSmallEnd}>
                                            0
                                        </Typography>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </GridItem>

                        <GridItem xs={4} style={{ display: "flex", flexDirection: "column" }}>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <div className={classes.divColumnFlex2}>

                                        <Typography className={classes.cardDescPoints}>
                                            <RaffleIcon className={classes.icons} />  Home/Kitchen
                                        </Typography>

                                        <Typography className={classes.cardDescPoints}>
                                            <GiftCard className={classes.icons} />  Gift Cards
                                        </Typography>
                                    </div>
                                </GridItem>

                                <GridItem xs={6}>
                                    <div className={classes.divColumnFlex2}>
                                        <Typography className={classes.cardDescSmallEnd}>
                                            0
                                        </Typography>

                                        <Typography className={classes.cardDescSmallEnd}>
                                            0
                                        </Typography>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </Card>
            </Hidden>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        userPts: state.auth.user.tuzoPoints
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(PointsSpent));