import React, { useEffect } from "react";
import { useState } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { db } from "firebase/client";
import { addProduct } from "store/actions/cart";
import Card from "components/Card/Card";
import Quantity from "./components/Quantity";
// mui
import {
  Button,
  Hidden,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Loader from "components/Loader/Loader";

// stylings
import Style from "assets/jss/material-dashboard-pro-react/views/rewardsStyle";
import withStyles from "@material-ui/core/styles/withStyles";

// icons
import { ReactComponent as Add } from "assets/img/tuzo/icons/AddCircle.svg";
import { ReactComponent as EditIcon } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import { ReactComponent as Copy } from "assets/img/tuzo/icons/CopyIcon.svg";

const quantityArr = ["1", "2", "3", "4", "5"];
const denominationArr = [100];

export function RewardsDetail(props) {
  const { classes, history, rewardData, addProduct, cart, user } = props;

  const rewardId = window.location.pathname.split("/")[3];
  const [denomination, setDenomination] = useState(denominationArr[0]);
  const [giftCardType, setGiftCardType] = useState("");
  const [quantity, setQuantity] = useState("1");
  const [price, setPrice] = useState("");
  const [data, setData] = useState(false);

  const numberFormatter = new Intl.NumberFormat("en-US", {});

  const numberFormatterDecimals = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const checkedAdded = () => {
    let newObj = { ...data };
    newObj["added"] = true;
    setData(newObj);
  };

  useEffect(() => {
    if (data) {
      let newObj = { ...data };
      if (newObj.topDescription) {
        let str = newObj.topDescription;
        let values = str.split(";");
        if (str.includes("font-family")) {
          for (let i = 0; i < values.length; i++) {
            if (values[i].includes("font-family")) {
              values.splice(i, 1);
            }
          }
          let stringValues = values.toString();
          let newLine = stringValues.replace(/&nbsp,/g, " ");
          newObj.topDescription = newLine;
        }
      }
      setData(newObj);
    }
  }, []);

  const getData = () => {
    db.collection("rewards-list-test")
      .doc(rewardId)
      .get()
      .then((s) => {
        s = s.data();
        setData(s);
      });
  };

  useEffect(() => {
    if (!data) {
      getData();
    }
  }, []);

  if (!data) return <Loader />;

  return (
    <div>
      <Hidden mdUp implementation="css">
        <div style={{ marginTop: "50px" }}>
          <GridContainer>
            <GridItem xs={12}>
              <Typography variant="h2" className={classes.title}>
                Reward Detail
              </Typography>
            </GridItem>

            <GridItem xs={12}>
              {user.userType.includes("Merchants") ? (
                !data.added ? (
                  <Button
                    // onClick={() => {
                    //     checkedAdded()
                    //     addProduct(data)
                    // }}
                    className={classes.addToCartButtonDetailMobile}
                  >
                    <Add className={classes.icon} /> Add to Cart
                  </Button>
                ) : (
                  <Button
                    style={{ background: "#06667C" }}
                    className={classes.addToCartButtonDetailMobile}
                  >
                    {/* <Add className={classes.icon} />  */}
                    Added to Cart
                  </Button>
                )
              ) : null}
            </GridItem>

            <GridItem xs={12}>
              {user.userType.includes("Merchants") ? (
                <Button
                  // onClick={() => {
                  //     checkedAdded()
                  //     addProduct(data)
                  //     if (user.IsoAdminCompanyName) {
                  //         history.push(`/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/cart`)
                  //     } else {
                  //         history.push('/merchant/cart')
                  //     }

                  // }}
                  className={classes.addToCartButtonDetailMobile}
                  style={{ background: "#56CA93" }}
                >
                  <Add className={classes.icon} /> Redeem Now
                </Button>
              ) : null}
            </GridItem>

            <GridItem xs={12}>
              <Card className={classes.detailCard}>
                <GridContainer>
                  <GridItem xs={5}>
                    <img
                      className={classes.cardListImg}
                      src={data.mainImage}
                      alt="img here"
                    />
                  </GridItem>

                  <GridItem xs={12}>
                    <Typography className={classes.rewardDetailName}>
                      {data.rewardName}
                    </Typography>

                    <Typography className={classes.rewardDetailDescription}>
                      {data.topDescription.length > 8 ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: data.topDescription,
                          }}
                        ></span>
                      ) : (
                        "No Description"
                      )}
                    </Typography>
                  </GridItem>

                  <GridItem xs={12}>
                    <div style={{ display: "flex", marginTop: "15px" }}>
                      <Typography className={classes.detailDesc}>
                        SKU: <span style={{ fontWeight: 400 }}>{data.sku}</span>
                      </Typography>

                      <Typography className={classes.detailDesc}>
                        Category:
                        <span style={{ fontWeight: 400 }}>
                          {data.categories.electronics === true
                            ? " Electronics"
                            : data.categories.sportsOutdoors === true
                            ? " Sports/Outdoors"
                            : data.categories.giftCards === true
                            ? " Gift Cards"
                            : data.categories.luxuryGoods === true
                            ? " Luxury Goods"
                            : data.categories.homeKitchen === true
                            ? " Home/Kitchen"
                            : " Travel"}
                        </span>
                      </Typography>
                    </div>
                  </GridItem>

                  <GridItem xs={12}>
                    {data.giftCardType ? (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          className={classes.rewardDetailDescriptionDropdown}
                        >
                          Denomination
                        </Typography>
                        <Select
                          style={{ width: "100%" }}
                          className={classes.selectMenuSortDetailRewardsPage}
                          value={denomination}
                          disableUnderline
                          classes={{
                            root: classes.selectText,
                          }}
                          onChange={(e) => {
                            setDenomination(e.target.value);
                          }}
                        >
                          {denominationArr.map((o, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={o}
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                              >
                                {o}
                              </MenuItem>
                            );
                          })}
                        </Select>

                        <Typography
                          className={classes.rewardDetailDescriptionDropdown}
                        >
                          Type
                        </Typography>

                        <Select
                          style={{ width: "100%" }}
                          className={classes.selectMenuSortDetailRewardsPage}
                          value={giftCardType}
                          disableUnderline
                          classes={{
                            root: classes.selectText,
                          }}
                          onChange={(e) => {
                            setGiftCardType(e.target.value);
                          }}
                        >
                          {data.giftCardType.map((o, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={o}
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                              >
                                {o}
                              </MenuItem>
                            );
                          })}
                        </Select>

                        <Typography
                          className={classes.rewardDetailDescriptionDropdown}
                        >
                          Quantity
                        </Typography>
                        <Select
                          style={{ width: "100%" }}
                          className={classes.selectMenuSortDetailRewardsPage}
                          value={quantity}
                          disableUnderline
                          classes={{
                            root: classes.selectText,
                          }}
                          onChange={(e) => {
                            setQuantity(e.target.value);
                            // updateQuantity(e.target.value)
                          }}
                        >
                          {quantityArr.map((o, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={o}
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                              >
                                {o}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </div>
                    ) : (
                      <div style={{ display: "flex" }}>
                        <Select
                          className={classes.selectMenuSortDetailRewardsPage}
                          value={quantity}
                          disableUnderline
                          classes={{
                            root: classes.selectText,
                          }}
                          onChange={(e) => {
                            setQuantity(e.target.value);
                            // updateQuantity(e.target.value)
                          }}
                        >
                          {quantityArr.map((o, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={o}
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                              >
                                {o}
                              </MenuItem>
                            );
                          })}
                        </Select>

                        <Typography
                          className={classes.rewardDetailDescriptionDropdown}
                        >
                          Quantity
                        </Typography>
                      </div>
                    )}
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>

            <GridItem xs={12} style={{ marginTop: "-30px" }}>
              <Quantity
                user={user}
                quantity={quantity}
                price={price}
                giftCardType={giftCardType}
                addProduct={addProduct}
                data={data}
                denomination={denomination}
                checkAdded={checkedAdded}
              />
            </GridItem>
          </GridContainer>
        </div>
      </Hidden>

      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h2" className={classes.title}>
              Reward Detail
            </Typography>

            <Button
              className={classes.addToCartButtonDetail}
              style={{ background: "#FECD2C" }}
              onClick={() => {
                if (data.categories.travel) {
                  history.push(
                    `/admin/rewards/edit-form/travel/${data.rewardId}`
                  );
                } else if (data.categories.giftCards) {
                  history.push(
                    `/admin/rewards/edit-form/gift-cards/${data.rewardId}`
                  );
                } else {
                  history.push(`/admin/rewards/edit-form/${data.rewardId}`);
                }
              }}
            >
              <EditIcon className={classes.icon} /> Edit
            </Button>

            <Button
              className={classes.addToCartButtonDetail}
              style={{ background: "#E2A400" }}
            >
              <Copy className={classes.icon} /> Duplicate
            </Button>

            <Button
              onClick={() => {
                history.push("/admin/assign-reward", {
                  rewardData: data,
                });
              }}
              className={classes.addToCartButtonDetail}
            >
              <Add className={classes.icon} /> Assign Reward
            </Button>

            <Button
              className={classes.addToCartButtonDetail}
              style={{ background: "#56CA93" }}
            >
              <TrashIcon className={classes.icon} /> Delete
            </Button>
          </GridItem>

          <GridItem xs={8}>
            <Card className={classes.detailCard}>
              <GridContainer>
                <GridItem xs={5}>
                  <img
                    className={classes.cardListImg}
                    src={data.mainImage}
                    alt="img here"
                  />
                </GridItem>

                <GridItem xs={7}>
                  <Typography className={classes.rewardDetailName}>
                    {data.rewardName}
                  </Typography>

                  <Typography className={classes.rewardDetailDescription}>
                    {data.topDescription.length > 8 ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: data.topDescription,
                        }}
                      ></span>
                    ) : (
                      "No Description"
                    )}
                  </Typography>
                </GridItem>

                <GridItem xs={5}>
                  <div style={{ display: "flex", marginTop: "15px" }}>
                    <Typography className={classes.detailDesc}>
                      SKU: <span style={{ fontWeight: 400 }}>{data.sku}</span>
                    </Typography>

                    <Typography className={classes.detailDesc}>
                      Category:
                      <span style={{ fontWeight: 400 }}>
                        {data.categories.electronics === true
                          ? " Electronics"
                          : data.categories.sportsOutdoors === true
                          ? " Sports/Outdoors"
                          : data.categories.giftCards === true
                          ? " Gift Cards"
                          : data.categories.luxuryGoods === true
                          ? " Luxury Goods"
                          : data.categories.homeKitchen === true
                          ? " Home/Kitchen"
                          : " Travel"}
                      </span>
                    </Typography>
                  </div>
                </GridItem>

                <GridItem xs={7}>
                  {
                    data.giftCardType ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Select
                            className={classes.selectMenuSortDetailRewardsPage}
                            value={denomination}
                            disableUnderline
                            classes={{
                              root: classes.selectText,
                            }}
                            onChange={(e) => {
                              setDenomination(e.target.value);
                            }}
                          >
                            {denominationArr.map((o, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  value={o}
                                  classes={{
                                    root: classes.selectMenuItem,
                                  }}
                                >
                                  {o}
                                </MenuItem>
                              );
                            })}
                          </Select>

                          <Typography
                            className={classes.rewardDetailDescriptionDropdown}
                          >
                            Denomination
                          </Typography>
                        </div>

                        <Typography
                          className={classes.rewardDetailDescriptionDropdown}
                        >
                          ${numberFormatterDecimals.format(data.salePrice)} /{" "}
                          {numberFormatter.format(data.salePricePoints)} points
                        </Typography>
                      </div>
                    ) : null
                    // <div style={{ display: "flex" }}>
                    //     <Select
                    //         className={classes.selectMenuSortDetailRewardsPage}
                    //         value={quantity}
                    //         disableUnderline
                    //         classes={{
                    //             root: classes.selectText,
                    //         }}
                    //         onChange={(e) => {
                    //             setQuantity(e.target.value)
                    //             // updateQuantity(e.target.value)
                    //         }}
                    //     >
                    //         {quantityArr.map((o, i) => {
                    //             return (
                    //                 <MenuItem
                    //                     key={i}
                    //                     value={o}
                    //                     classes={{
                    //                         root: classes.selectMenuItem,
                    //                     }}
                    //                 >
                    //                     {o}
                    //                 </MenuItem>
                    //             );
                    //         })}
                    //     </Select>

                    //     <Typography className={classes.rewardDetailDescriptionDropdown}>
                    //         Quantity
                    //     </Typography>
                    // </div>
                  }
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>

          {data.giftCardType ? (
            <GridItem xs={4}>
              <Quantity
                user={user}
                quantity={quantity}
                price={price}
                giftCardType={giftCardType}
                addProduct={addProduct}
                data={data}
                denomination={denomination}
                checkAdded={checkedAdded}
              />
            </GridItem>
          ) : null}
        </GridContainer>
      </Hidden>
    </div>
  );
}

function mapStateToProps(state, location) {
  return {
    rewardData: location.history.location.state?.rewardData,
    user: state.auth.user,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    addProduct: (product) => dispatch(addProduct(product)),
  });
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
  )(RewardsDetail)
);
