import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Earnings from './components/Earnings';
import LuxuryRewards from './components/LuxuryRewards';
import PointsSpent from './components/PointsSpent';
import HistoryTable from './components/HistoryTable';
import { sendRequest } from 'firebase/client';
import moment from 'moment'
import MobileTable from './components/MobileTable';

//mui core
import { Button, ClickAwayListener, Dialog, FormControlLabel, Hidden, IconButton, Radio, TextField, Tooltip, Typography } from "@material-ui/core";

//styling
import Style from 'assets/jss/material-dashboard-pro-react/views/activityStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import tuzoBg from "assets/img/tuzo/Round2.png";

//icons
import { ReactComponent as TransferPoints } from "assets/img/tuzo/icons/TransferPointsWhite.svg";
import { Cancel, CheckCircle, Info } from '@material-ui/icons';
import Icon from "assets/img/tuzo/iconNoText.png"

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import { db } from 'firebase/client';
import { ptsFormula } from 'globalCalculationFunction/globalCalculationFunction';
import Carousel from './components/Carousel';
import { add } from 'lodash';
import { sendSignUpLinkLive } from 'firebase/client';
import Loader from 'components/Loader/Loader';
import { userEmailUpdate } from 'firebase/client';


const headers = [
    { label: "Date", key: "date" },
    { label: "# of Transactions", key: "numTransactions" },
    { label: "Total ($)", key: "total" },
    { label: "Reference", key: "referenceNum" },
    { label: "Points", key: "pts" },
];

export function ActivityPage(props) {
    const { classes, history, user, userPts, userType, userMid, rewards } = props;

    const [batchData, setBatchData] = useState(false)
    const [points, setPoints] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [merchantId, setMerchantId] = useState("")
    const [message, setMessage] = useState("")
    const [requestModal, setRequestModal] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [acceptModal, setAcceptModal] = useState(false)
    const [docId, setDocId] = useState("")
    const [lastMonthPts, setLastMonthPts] = useState(0)
    const [lastYrPts, setLastYrPts] = useState(0)
    const itemsPerPage = 5;
    const itemsPerPageMobile = 1;
    const [pageNumber, setPageNumber] = useState(0);
    const [index, setIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(itemsPerPage);
    const [mobileEndIndex, setMobileEndIndex] = useState(itemsPerPageMobile)
    const [open, setOpen] = useState(false)
    const [rewardsArr, setRewardsArr] = useState(false)
    const [adData, setAdData] = useState(false)
    const [option, setOption] = useState("")
    const [redeemPts, setRedeemPts] = useState("")
    const [resetPts, setResetPts] = useState(0)
    const [errors, setErrors] = useState({
        points: false,
        recipient: false,
        message: false
    })

    const numberFormatter = new Intl.NumberFormat('en-US', {
    });


    useEffect(() => {
        if (rewards.length > 0) {
            // let allArr = []
            // let luxArr = []
            // let travelArr = []
            // let elecArr = []
            // let expArr = []
            // let giftArr = []
            // let raffleArr = []
            // rewards.filter((f) => {
            //     if (f.salePricePoints <= userPts && f.categories.luxuryGoods && luxArr.length < 2) {
            //         luxArr.push(f)
            //     }
            //     if (f.salePricePoints <= userPts && f.categories.travel && travelArr.length < 2) {
            //         travelArr.push(f)
            //     }
            //     if (f.salePricePoints <= userPts && f.categories.electronics && elecArr.length < 2) {
            //         elecArr.push(f)
            //     }
            //     if (f.salePricePoints <= userPts && f.categories.experiences && expArr.length < 2) {
            //         expArr.push(f)
            //     }
            //     if (f.salePricePoints <= userPts && f.categories.giftCards && giftArr.length < 2) {
            //         giftArr.push(f)
            //     }
            //     if (f.salePricePoints <= userPts && f.categories.raffle && raffleArr.length < 2) {
            //         raffleArr.push(f)
            //     }
            // })
            // allArr = luxArr.concat(travelArr, elecArr, expArr, giftArr, raffleArr)
            // const descArr = allArr.sort((a, b) =>
            //     (b.salePricePoints).toString().localeCompare((a.salePricePoints).toString(),
            //         undefined, {
            //         numeric: true,
            //         sensitivity: 'base'
            //     }))
            setRewardsArr(rewards)

        }
    }, [rewards])


    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
        setIndex(selected * itemsPerPage);
        setEndIndex(selected * itemsPerPage + itemsPerPage);
    };


    const getBgImage = () => {
        return tuzoBg;
    }


    const submitRequest = () => {
        if (!points || (!phoneNumber && !email && !merchantId) || !message) {
            let newErrors = {
                points: false,
                recipient: false,
                message: false
            }
            if (!points) {
                newErrors = { ...newErrors, points: true }
            }
            if ((!phoneNumber && !email && !merchantId)) {
                newErrors = { ...newErrors, recipient: true }
            }
            if (!message) {
                newErrors = { ...newErrors, message: true }
            }
            setErrors({ ...newErrors })
        }
        else {
            const recipient = phoneNumber ? phoneNumber : email ? email : merchantId
            const data = {
                points,
                recipient,
                message,
                user
            }
            sendRequest(data)
            setRequestModal(false)
            setConfirmModal(true)

        }
    }

    const reset = () => {
        setRequestModal(false)
        setPoints("")
        setEmail("")
        setPhoneNumber("")
        setMerchantId("")
        setMessage("")
        setErrors({
            points: false,
            recipient: false,
            message: false
        })
    }

    useEffect(() => {
        if (!batchData) {
            const getData = () => {
                let subId = ""
                return db
                    .collection('merchants-live')
                    .where("merchantNumber", "==", userMid)
                    .get()
                    .then((snapshots) => {
                        if (!snapshots.empty) {
                            snapshots.forEach((s) => {
                                s = s.data()
                                subId = s.docId
                                setDocId(s.docId)
                            })
                            db
                                .collection("merchants-live")
                                .doc(subId)
                                .collection("batch-summary")
                                .orderBy('reportDate', 'desc')
                                .onSnapshot((snapshot) => {
                                    const arr = [];
                                    let ptsTotal = 0
                                    snapshot.forEach((doc) => {
                                        arr.push(doc.data())
                                        // ptsTotal += ptsFormula(doc.data().netAmount, 20)
                                    })
                                    setBatchData(arr)
                                    // setResetPts(ptsTotal)
                                })
                        }
                        else {
                            setBatchData([])
                        }
                    })
            }
            getData()
        }
        if (!adData) {
            const getAdData = () => {
                return db.collection('ad-list').get()
                    .then((snapshots) => {
                        if (!snapshots.empty) {
                            const arr = []
                            snapshots.forEach((doc) => {
                                arr.push(doc.data())
                            })
                            setAdData(arr)
                        }
                    })
            }
            getAdData()
        }
    }, [])

    const getLastMonthSum = () => {
        let lastMonth = moment().subtract(1, 'months')
        let monthSum = 0
        batchData.filter((m) => {
            if (m.reportDate >= lastMonth.startOf('month').format("YYYY-MM-DDT00:00:00")
                && m.reportDate <= lastMonth.endOf('month').format("YYYY-MM-DDT23:59:59")
            ) {
                const totalRewardDollarValue = ptsFormula(m.netAmount, user.userBasisPts)
                monthSum += totalRewardDollarValue
                return true;
            }
        })
        setLastMonthPts(monthSum)
    }

    // useEffect(() => {
    //     let arr = [
    //         820100005959,
    //         820100020784,
    //         820100022871,
    //     ]
    //     for(let i =0; i < arr.length; i++){
    //         db.collection("users").where("merchantId", "==", String(arr[i]))
    //         .get()
    //         .then((snapshots) => {
    //             snapshots.forEach((s) => {
    //                 s = s.data()
    //                 let id = db.collection("merchants-live").doc().id
    //                 db.collection("merchants-live").doc(id).set({
    //                     docId: id,
    //                     email: s.email,
    //                     merchantName: s.companyName,
    //                     merchantNumber: s.merchantId
    //                 })
    //             })
    //         })
    //     }
    // }, [])

    const getLastYearSum = () => {
        let lastYear = moment().subtract(1, 'year')
        let yearSum = 0
        batchData.filter((m) => {
            if (m.reportDate >= lastYear.startOf('year').format("YYYY-MM-DDT00:00:00")
                && m.reportDate <= lastYear.endOf('year').format("YYYY-MM-DDT23:59:59")
            ) {
                const totalRewardDollarValue = ptsFormula(m.netAmount, user.userBasisPts)
                yearSum += totalRewardDollarValue
                return true;
            }
        })
        setLastYrPts(yearSum)
    }

    useEffect(() => {
        if (batchData.length > 1) {
            getLastMonthSum()
            getLastYearSum()
        }

    }, [batchData])

    if (!batchData || !rewardsArr || !adData)
        return (
            <Loader />
        );

    return (
        <div>
            <Hidden mdUp implementation='css'>
                <div
                    style={{
                        backgroundImage: "url(" + getBgImage() + ")",
                        width: "100vw",
                        marginLeft: "-30px",
                        height: "100%",
                        marginTop: "30px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#274b73",
                        backgroundPhoto: "repeat",
                        backgroundPosition: "right top",
                        backgroundSize: "cover",
                        paddingTop: "20px",
                        paddingBottom: "20px"
                    }}
                >
                    <GridContainer>
                        <GridItem xs={12} style={{ marginTop: "10px" }}>
                            <Typography variant="h2" className={classes.bannerTitlePadding} style={{ display: "flex", justifyContent: "center" }}>
                                Your Activity
                            </Typography >
                        </GridItem>

                        <GridItem xs={12} style={{ marginTop: "10px" }}>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <img
                                    src={Icon}
                                    style={{
                                        width: "36px",
                                        height: "36px",
                                        marginRight: "15px",
                                        marginTop: "2px"
                                    }} />
                                <Typography
                                    style={{ fontSize: "28px", lineHeight: "28px" }}
                                    className={classes.bannerTitlePadding}>
                                    {user.approvedPts ? (Math.trunc(user.approvedPts)).toLocaleString() : 0}
                                </Typography >

                                <Typography
                                    variant="h2"
                                    className={classes.bannerDescPadding}
                                >
                                    Available Points
                                </Typography >
                            </div>

                        </GridItem>

                        <GridItem xs={12} >
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Typography
                                    style={{ fontSize: "28px", lineHeight: "28px" }}
                                    className={classes.bannerTitlePadding}>
                                    {userPts ? (Math.trunc(userPts)).toLocaleString() : 0}
                                </Typography >

                                <Typography variant="h2" className={classes.bannerDescPadding}>
                                    Pending Points
                                </Typography >

                                <ClickAwayListener onClickAway={() => setOpen(false)}>
                                    <IconButton onClick={() => setOpen(!open)} >
                                        <Tooltip
                                            id="tooltip-top"
                                            title="This is the amount of points you’ve earned since your last statement. They will be added to your available points balance when approved by an Administrator, minus any credits or adjustments."
                                            placement="bottom"
                                            classes={{ tooltip: classes.tooltip2 }}
                                            style={{ fontSize: "16px !important" }}
                                            open={open}
                                        >
                                            <Info className={classes.infoIcon} style={{ fontSize: "24px" }} />
                                        </Tooltip>
                                    </IconButton>
                                </ClickAwayListener>
                            </div>



                        </GridItem>
                    </GridContainer>
                </div>

                <GridContainer >
                    <GridItem xs={12}>
                        <Earnings lastMonthPts={lastMonthPts} lastYrPts={lastYrPts} />
                    </GridItem>
                    <GridItem xs={12}>
                        <Carousel
                            rewardsArr={rewardsArr}
                            history={history}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <MobileTable
                            docId={docId}
                            batchData={batchData}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                            index={index}
                            setIndex={setIndex}
                            endIndex={mobileEndIndex}
                            setEndIndex={setMobileEndIndex}
                            itemsPerPage={itemsPerPageMobile}
                            headers={headers}
                        />
                    </GridItem>
                    <GridItem xs={12} style={{ marginTop: "25px" }}>
                        <LuxuryRewards adData={adData} />
                    </GridItem>
                </GridContainer>
            </Hidden>

            <Hidden smDown implementation="css">
                <div
                    style={{
                        backgroundImage: "url(" + getBgImage() + ")",
                        width: "100vw",
                        marginLeft: "-30px",
                        height: "90px",
                        marginTop: "-30px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#274b73",
                        backgroundPhoto: "repeat",
                        backgroundSize: "cover"
                    }}
                >

                    <GridContainer style={{ marginLeft: "30px" }}>
                        <GridItem xs={3}>
                            <Typography variant="h2" className={classes.bannerTitle}>
                                Your Activity
                            </Typography >
                        </GridItem>

                        <GridItem xs={3}>
                            <div style={{ display: "flex", marginLeft: "-50px" }}>
                                <img
                                    src={Icon}
                                    style={{
                                        width: "36px",
                                        height: "36px",
                                        marginRight: "15px",
                                        marginTop: "-5px"
                                    }} />
                                <Typography
                                    style={{ fontSize: "28px", lineHeight: "28px" }}
                                    className={classes.bannerTitle}>
                                    {user.approvedPts ? (Math.trunc(user.approvedPts)).toLocaleString() : 0}
                                </Typography >

                                <Typography
                                    variant="h2"
                                    className={classes.bannerDesc}
                                >
                                    Available Points
                                </Typography >
                            </div>

                        </GridItem>

                        <GridItem xs={3}>
                            <Tooltip
                                id="tooltip-top"
                                title="This is the amount of points you’ve earned since your last statement. They will be added to your available points balance when approved by an Administrator, minus any credits or adjustments."
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip2 }}
                            >
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Typography
                                        style={{ fontSize: "28px", lineHeight: "28px" }}
                                        className={classes.bannerTitle}>
                                        {userPts ? (Math.trunc(userPts)).toLocaleString() : 0}
                                    </Typography >

                                    <Typography variant="h2" className={classes.bannerDesc}>
                                        Pending Points
                                    </Typography >
                                </div>
                            </Tooltip>
                        </GridItem>

                        <GridItem xs={3}
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }}>
                            <Tooltip
                                id="tooltip-top"
                                title="Want to Transfer points to a friend or to yourself? Request a points transfer and an Administrator will complete it for you!"
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip2 }}
                            >
                                <Button
                                    onClick={() => setRequestModal(true)}
                                    className={classes.transferPtsButton}
                                >
                                    <TransferPoints style={{ marginRight: "10px" }} /> Transfer Points
                                </Button>
                            </Tooltip>
                        </GridItem>
                    </GridContainer>
                </div>

                <GridContainer style={{ marginTop: "0px" }}>
                    <GridItem xs={3}>
                        <Earnings lastMonthPts={lastMonthPts} lastYrPts={lastYrPts} />
                    </GridItem>

                    <GridItem xs={9}>
                        <Carousel
                            rewardsArr={rewardsArr}
                            history={history}
                        />
                    </GridItem>

                    <GridItem xs={3}>
                        <LuxuryRewards
                            adData={adData}
                        />
                    </GridItem>

                    <GridItem xs={9}>
                        <HistoryTable
                            docId={docId}
                            batchData={batchData}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                            index={index}
                            setIndex={setIndex}
                            endIndex={endIndex}
                            setEndIndex={setEndIndex}
                            itemsPerPage={itemsPerPage}
                            handlePageClick={handlePageClick}
                            headers={headers}
                        />
                    </GridItem>
                </GridContainer>
            </Hidden>

            <Dialog
                className={classes.requestModal}
                open={requestModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xl"
            >
                <Card className={classes.modalCard}>
                    <GridContainer>
                        <GridItem xs={12} style={{ display: "flex" }}>
                            <h5 className={classes.cardHeaderModal}>Request Points Transfer</h5>
                            <Button
                                onClick={() => submitRequest()}
                                className={classes.modalButton}
                                style={{ background: "#1A405F", }}>
                                <CheckCircle style={{ marginRight: "10px", marginTop: "-5px" }} /> Send
                            </Button>

                            <Button
                                onClick={() => reset()}
                                className={classes.modalButton}>
                                <Cancel style={{ marginRight: "10px", marginTop: "-5px" }} /> Cancel
                            </Button>

                            {/* <Button
                                onClick={() => setConfirmModal(true)}
                                className={classes.modalButton}>
                                For Olivia
                            </Button> */}
                        </GridItem>

                        <GridItem xs={2} className={classes.gridItemMarginTop}>
                            <Typography
                                style={errors.points ? { marginTop: "30px" } : {}}
                                className={classes.modalCardDesc}>
                                Points
                            </Typography>
                        </GridItem>

                        <GridItem xs={10} className={classes.gridItemMarginTop}>
                            <div>
                                {errors.points ? (
                                    <span className={classes.errText}>Please fill out the field</span>
                                ) : null}
                                <TextField
                                    placeholder=''
                                    type="number"
                                    className={classes.modalTextField}
                                    value={points}
                                    onChange={(e) => setPoints(e.target.value)}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            input: classes.input
                                        },
                                        inputProps: {
                                            min: 0
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        className: classes.errText
                                    }}
                                />
                            </div>
                        </GridItem>

                        <GridItem xs={2} className={classes.gridItemMarginTop}>
                            <Typography
                                style={errors.recipient ? { marginTop: "30px" } : {}}
                                className={classes.modalCardDesc}>
                                Recipient
                            </Typography>
                        </GridItem>

                        <GridItem xs={10} className={classes.gridItemMarginTop}>
                            <div>
                                {errors.recipient ? (
                                    <span className={classes.errText}> Please fill out one of the three fields</span>
                                ) : null}
                                <div style={{ display: "flex" }}>
                                    <TextField
                                        style={{ width: "40%" }}
                                        placeholder='Email Address'
                                        className={classes.modalTextField}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                input: classes.input
                                            },
                                            inputProps: {
                                                min: 0
                                            }
                                        }}
                                    />
                                    <span>
                                        <Typography className={classes.modalCardDescSpan}>
                                            or
                                        </Typography>
                                    </span>

                                    <TextField
                                        style={{ width: "30%" }}
                                        placeholder='Phone Number'
                                        className={classes.modalTextField}
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                input: classes.input
                                            },
                                            inputProps: {
                                                min: 0
                                            }
                                        }}
                                    />
                                    <span>
                                        <Typography className={classes.modalCardDescSpan}>
                                            or
                                        </Typography>
                                    </span>

                                    <TextField
                                        style={{ width: "30%" }}
                                        placeholder='Merchant Id'
                                        className={classes.modalTextField}
                                        value={merchantId}
                                        onChange={(e) => setMerchantId(e.target.value)}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                input: classes.input
                                            },
                                            inputProps: {
                                                min: 0
                                            }
                                        }}
                                    />
                                </div>

                            </div>

                        </GridItem>

                        <GridItem xs={2} className={classes.gridItemMarginTop}>
                            <Typography
                                style={errors.message ? { marginTop: "30px" } : {}}
                                className={classes.modalCardDesc}>
                                Message
                            </Typography>
                        </GridItem>


                        <GridItem xs={10} className={classes.gridItemMarginTop}>
                            <div>
                                {errors.message ? (
                                    <span className={classes.errText}> Please fill out the field</span>
                                ) : null}
                                <TextField
                                    className={classes.modalTextField}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            input: classes.input
                                        },
                                        inputProps: {
                                            min: 0
                                        }
                                    }}
                                />
                            </div>
                        </GridItem>
                    </GridContainer>
                </Card>
            </Dialog>

            <Dialog
                className={classes.requestModal}
                open={confirmModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Card className={classes.confirmModalCard}>
                    <GridContainer>
                        <GridItem xs={5}>
                            <h5 className={classes.cardHeader}>Request Points Transfer</h5>
                        </GridItem>

                        <GridItem xs={6}>
                            <Button
                                onClick={() => setConfirmModal(false)}
                                className={classes.modalButton}>
                                <Cancel style={{ marginRight: "10px", marginTop: "-5px" }} /> Exit
                            </Button>
                        </GridItem>

                        <GridItem xs={12}>
                            <Typography className={classes.modalCardDesc}>
                                Your request has been sent successfully! An administrator will approve it as soon as possible or reach out if there are any issues.
                            </Typography>
                        </GridItem>
                    </GridContainer>
                </Card>
            </Dialog>

            <Dialog
                className={classes.requestModal}
                open={acceptModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xl"
            >
                <Card className={classes.modalCard}>
                    <GridContainer>
                        <GridItem xs={12} style={{ display: "flex" }}>
                            <h5 className={classes.cardHeaderModal}>Accept Points Transfer</h5>
                            <Button
                                // onClick={() => submitRequest()}
                                className={classes.modalButton}
                                style={{ background: "#1A405F", }}>
                                <CheckCircle style={{ marginRight: "10px", marginTop: "-5px" }} /> Confirm
                            </Button>

                            <Button
                                onClick={() => setAcceptModal(false)}
                                className={classes.modalButton}>
                                <Cancel style={{ marginRight: "10px", marginTop: "-5px" }} /> Deny
                            </Button>
                        </GridItem>

                        <GridItem xs={12}>
                            <GridContainer>
                                <GridItem xs={2} className={classes.gridItemMarginTop}>
                                    <Typography
                                        className={classes.modalCardDesc}>
                                        Points
                                    </Typography>
                                </GridItem>

                                <GridItem xs={10}>
                                    <Typography
                                        className={classes.acceptModalCardDesc}>
                                        2500
                                    </Typography>
                                </GridItem>
                            </GridContainer>
                        </GridItem>

                        <GridItem xs={12}>
                            <GridContainer>
                                <GridItem xs={2} className={classes.gridItemMarginTop}>
                                    <Typography
                                        className={classes.modalCardDesc}>
                                        Sender
                                    </Typography>
                                </GridItem>

                                <GridItem xs={10}>
                                    <Typography
                                        className={classes.acceptModalCardDesc}>
                                        Marco Lopes - Kordspace -mlopes@kordspace.com
                                    </Typography>
                                </GridItem>
                            </GridContainer>
                        </GridItem>

                        <GridItem xs={12}>
                            <GridContainer>
                                <GridItem xs={2} className={classes.gridItemMarginTop}>
                                    <Typography
                                        className={classes.modalCardDesc}>
                                        Message
                                    </Typography>
                                </GridItem>

                                <GridItem xs={10}>
                                    <Typography
                                        className={classes.acceptModalCardDesc}>
                                        Thanks for your help with the big project, treat yourself!
                                    </Typography>
                                </GridItem>
                            </GridContainer>
                        </GridItem>

                        {/* <GridItem xs={12} >
                            <div className={classes.divBorder} />
                            <GridContainer>
                                <GridItem xs={12}>
                                    <Typography className={classes.usePointsText}>
                                        Add to total points ({numberFormatter.format(Number(2500))}) available
                                    </Typography>
                                </GridItem>

                                <GridItem xs={12} style={{ display: "flex", marginTop: "15px", justifyContent: "space-between" }}>
                                    <FormControlLabel
                                        className={classes.checkboxes}
                                        control={
                                            <Radio
                                                style={{ color: "#56CA93", marginTop: "-15px", marginRight: "-25px" }}
                                                checked={option === "Total"}
                                                onClick={() => setOption("Total")}
                                                value="Total"
                                            />
                                        }
                                    />

                                    <Typography className={classes.usePointsText} style={{ lineHeight: "16px" }}>
                                        Points redeemed
                                    </Typography>

                                    <TextField
                                        disabled={option !== "Total" ? true : false}
                                        placeholder=''
                                        type="number"
                                        className={classes.ptsTextField}
                                        value={redeemPts}
                                        onChange={(e) => {
                                            // if (Number(e.target.value) > (totalAmount * 100) && (user.tuzoPoints) > (totalAmount * 100)) {
                                            //     setRedeemPoints(totalAmount * 100)

                                            // }
                                            // else if (Number(e.target.value) > user.tuzoPoints && user.tuzoPoints < (totalAmount * 100)) {
                                            //     setRedeemPoints(Number(user.tuzoPoints))
                                            // }
                                            // else {
                                                setRedeemPts(e.target.value)
                                            // }

                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                adornedEnd: classes.adournedEnd,
                                                input: classes.input
                                            },
                                            inputProps: {
                                                min: 0,
                                                max: user.tuzoPoints
                                            }
                                        }}
                                    />
                                    <Button
                                        className={classes.updateButton}
                                        // onClick={() => updatePts()}
                                    >
                                        Update
                                    </Button>
                                </GridItem>
                            </GridContainer>

                        </GridItem> */}
                    </GridContainer>
                </Card>
            </Dialog>
        </div>
    )
}

function mapStateToProps(state) {

    return {
        user: state.auth.user,
        userType: state.auth.user.userType,
        userMid: state.auth.user.merchantId,
        userPts: state.auth.user.tuzoPoints,
        rewards: state.rewards.rewards,
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(ActivityPage));