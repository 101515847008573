import React, { useEffect, useState } from "react";
import DonutChart from "react-donut-chart";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Filter from "./components/Filter";
import Table from "./components/Table";
import moment from "moment"
//core components
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import Hidden from "@material-ui/core/Hidden";
import { Dialog, TextareaAutosize, TextField, Typography } from "@material-ui/core";

// styling
import withStyles from "@material-ui/core/styles/withStyles";
import Style from "assets/jss/material-dashboard-pro-react/views/isoAdminDashboardStyle";
import loader from "assets/img/Tuzo-Preloader-Transparent.gif";

// @material-ui/icons
import { AiOutlineDollar } from "react-icons/ai";
import { Add, Cancel, CheckCircle } from "@material-ui/icons";
import { db, firebase } from "firebase/client";
import { sendIrisGetFunction } from "firebase/client";
import Loader from "components/Loader/Loader";
import { sendTransferNotification } from "firebase/client";
import DialogLoader from "components/Loader/DialogLoader";

const statusArr = ["Approved Points", "Pending Points", "Name", "Joined"];

const ascArr = ["Asc", "Desc"];

const entriesArr = [1, 2, 3, 4, 5, 6];

export function Dashboard(props) {
  const { classes, history, userCompany, userEmail, IsoAdminCompanyName, user } = props;

  const [rewardsSwitch, setRewardsSwitch] = useState("All Users");
  const [statusDropdown, setStatusDropdown] = useState("Approved Points");
  const [ascDropdown, setAscDropdown] = useState("Asc");
  const [listSwitch, setListSwitch] = useState("List");
  const [searchString, setSearchString] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPage);
  const [data, setData] = useState(false);
  const [copyArr, setCopyArr] = useState(false);
  const [transactionLen, setTransactionLen] = useState(false);
  const [emptyData, setEmptyData] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false)
  const [transferModal, setTransferModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [finishModal, setFinishModal] = useState(false)
  const [currMerchant, setCurrMerchant] = useState("")
  const [bonusMessage, setBonusMessage] = useState("")
  const [points, setPoints] = useState("")
  const [isoEmail, setIsoEmail] = useState("")
  const [errors, setErrors] = useState({
    points: false
  })

  const numberFormatter = new Intl.NumberFormat("en-US", {});

  const calculateAfterPoints = (currentPts, transferPts) => {
    let newPts = transferPts.replace(/,/g, '')
    let newTotal = Number(currentPts) + Number(newPts)

    return numberFormatter.format(newTotal)
  }

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage);
    setEndIndex(selected * itemsPerPage + itemsPerPage);
  };

  const resetPageFilter = (filter) => {
    setData(filter)
    setIndex(0);
    setEndIndex(itemsPerPage);
    setPageNumber(0);
  }

  const resetTransferModal = () => {
    setConfirmModal(false)
    setTransferModal(false)
    setPoints("")
    setBonusMessage("")
    setErrors({
      points: false,
    })
  }

  const formatInput = (value) => {
    let newValue = value.replace(/[^0-9\.]/g, '').replace(/\./g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    setPoints(newValue)
  }

  const getIsoEmail = (isoName) => {
    db.collection('users')
      .where("companyName", "==", isoName)
      .where("userType", "==", "IsoAdmin")
      .get()
      .then((snapshots) => {
        if (!snapshots.empty) {
          snapshots.forEach((d) => {
            d = d.data()
            setIsoEmail(d.email)
          })

        }
      })
  }

  const transferPoints = async () => {
    setShowModal(true)
    const transferId = db.collection('users').doc(user.uid).collection('point-transfers').doc().id
    let newPts = points.replace(/,/g, '')
    let docId = currMerchant.uid ? currMerchant.uid : currMerchant.id
    const userRef = db.collection('users').doc(docId)
    userRef.get()
      .then((snapshot) => {
        if (snapshot.exists) {
          let data = snapshot.data()
          let newPendingPts = Number(newPts) + Number(data.tuzoPoints)
          userRef.update({
            bonusPoints: newPts,
            tuzoPoints: newPendingPts
          })
        } else {
          const tempRef = db.collection("temp-user").doc(docId)
          tempRef.get()
            .then((tempSnapshot) => {
              if (tempSnapshot.exists) {
                let tempData = tempSnapshot.data()
                let tempPendingPts = newPts + Number(tempData.tuzoPoints)
                tempRef.update({
                  bonusPoints: newPts,
                  tuzoPoints: tempPendingPts
                })
              }
            })
        }
      }).then(() => {
        let transferObj = {
          transferId,
          senderId: user.uid,
          senderFirstName: user.firstName,
          senderLastName: user.lastName,
          senderCompanyName: user.IsoAdminCompanyName ? user.IsoAdminCompanyName : user.companyName,
          merchantId: currMerchant.merchantId,
          merchantName: currMerchant.companyName,
          points: newPts,
          transferDate: firebase.firestore.Timestamp.fromDate(new Date())
        }
        let emailArr = [
          "admin@tuzorewards.com",
          isoEmail,
          currMerchant.email
        ]
        let newObj = {
          domain: window.location.host === "www.wpirewards.com" || user.companyName === "Wholesale Payments" || user.IsoAdminCompanyName === "Wholesale Payments" ? "notifications@wpirewards.com" : "notifications@tuzorewards.com",
          emailTitle: window.location.host === "www.wpirewards.com" || user.companyName === "Wholesale Payments" || user.IsoAdminCompanyName === "Wholesale Payments" ? "WPI Rewards" : "Tuzo Rewards",
          isoName: user.companyName ? user.companyName : "Your ISO company",
          bonusHeader: user.branding !== false && user.bonusHeader ? user.bonusHeader : "https://firebasestorage.googleapis.com/v0/b/tuzo-379415.appspot.com/o/EmailImg%2FbaseBonusHeader.png?alt=media&token=e1a33a31-2d0e-4f02-8ad3-94d897721f63",
          merchantName: currMerchant.companyName,
          tuzoPoints: newPts,
          email: emailArr,
          supportEmail: window.location.host === "www.wpirewards.com" || user.companyName === "Wholesale Payments" || user.IsoAdminCompanyName === "Wholesale Payments" ? "info@wpirewards.com" : "admin@tuzorewards.com",
          bonusMessage: bonusMessage
        }
        db.collection('users').doc(user.uid).collection('point-transfers').doc(transferId).set(transferObj)
        db.collection('point-transfers').doc(transferId).set(transferObj)
          .then(() => {
            sendTransferNotification(newObj).then(() => {
              setConfirmModal(false)
              setShowModal(false)
              setFinishModal(true)
            })
          })

      })
  }

  const handleFilter = (ascValue, sortValue) => {
    if (ascValue === "Asc") {
      if (statusDropdown === "Approved Points") {
        const filtered = copyArr.sort(
          (a, b) =>
            (a.approvedPts != null ? a.approvedPts : 0) -
            (b.approvedPts != null ? b.approvedPts : 0)
        );
        resetPageFilter(filtered);
      }

      if (statusDropdown === "Pending Points") {
        const filtered = copyArr.sort(
          (a, b) =>
            (a.tuzoPoints != null ? a.tuzoPoints : 0) -
            (b.tuzoPoints != null ? b.tuzoPoints : 0)
        );
        resetPageFilter(filtered);
      }

      if (sortValue === "Name") {
        const filtered = copyArr.sort(function (a, b) {
          if (a.contact) {
            if (a.contact < b.contact) {
              return -1;
            }
            if (a.contact > b.contact) {
              return 1;
            }
            return 0;
          } else {
            if (a.lastName < b.lastName) {
              return -1;
            }
            if (a.lastName > b.lastName) {
              return 1;
            }
            return 0;
          }
        });
        resetPageFilter(filtered);
      }

      if (sortValue === "Joined") {
        const filtered = copyArr.sort(function (a, b) {
          return (
            (a.lastLoggedIn != null ? a.lastLoggedIn.seconds : 0) -
            (b.lastLoggedIn != null ? b.lastLoggedIn.seconds : 0)
          );
        });
        resetPageFilter(filtered);
      }
    }
    if (ascValue === "Desc") {
      if (statusDropdown === "Approved Points") {
        const filtered = copyArr.sort(
          (a, b) =>
            (b.approvedPts != null ? b.approvedPts : 0) -
            (a.approvedPts != null ? a.approvedPts : 0)
        );
        resetPageFilter(filtered);
      }

      if (statusDropdown === "Pending Points") {
        const filtered = copyArr.sort(
          (a, b) =>
            (b.tuzoPoints != null ? b.tuzoPoints : 0) -
            (a.tuzoPoints != null ? a.tuzoPoints : 0)
        );
        resetPageFilter(filtered);
      }

      if (sortValue === "Name") {
        const filtered = copyArr.sort(function (a, b) {
          if (a.contact) {
            if (a.contact > b.contact) {
              return -1;
            }
            if (a.contact < b.contact) {
              return 1;
            }
            return 0;
          } else {
            if (a.lastName > b.lastName) {
              return -1;
            }
            if (a.lastName < b.lastName) {
              return 1;
            }
          }
        });
        resetPageFilter(filtered);
      }

      if (sortValue === "Joined") {
        const filtered = copyArr.sort(function (a, b) {
          return (
            (b.lastLoggedIn != null ? b.lastLoggedIn.seconds : 0) -
            (a.lastLoggedIn != null ? a.lastLoggedIn.seconds : 0)
          );
        });
        resetPageFilter(filtered);
      }
    }
  }

  const handleSearch = () => {
    if (searchString) {
      const filtered = copyArr.filter((f) => {
        if (
          (f.firstName &&
            f.firstName.toLowerCase().includes(searchString.toLowerCase())) ||
          (f.lastName &&
            f.lastName.toLowerCase().includes(searchString.toLowerCase())) ||
          (f.companyName &&
            f.companyName.toLowerCase().includes(searchString.toLowerCase())) ||
          (f.email &&
            f.email.toLowerCase().includes(searchString.toLowerCase())) ||
          (f.merchantId &&
            f.merchantId.toLowerCase().includes(searchString.toLowerCase()))
        ) {
          return true;
        }
      });
      if (filtered.length === 0) {
        setEmptyData(true);
      }
      setData(filtered);
    } else {
      setData(copyArr);
    }
  };

  const resetEmptyData = () => {
    setEmptyData(false);
    setSearchString("");
    setData(copyArr);
  };

  const getData = () => {
    let combineArr = [];
    let count = 0;
    return db
      .collection("users")
      .where("IsoAdminCompanyName", "==", IsoAdminCompanyName)
      .get()
      .then(async (snapshots) => {
        if (!snapshots.empty) {
          snapshots.forEach((s) => {
            s = s.data();
            combineArr.push(s);
          });
        }
        db.collection("temp-user")
          .where("IsoAdminCompanyName", "==", IsoAdminCompanyName)
          .get()
          .then((snapshots) => {
            if (!snapshots.empty) {
              snapshots.forEach((s) => {
                s = s.data();
                let newObj = {
                  ...s,
                  status: "Pending",
                };
                combineArr.push(newObj);
              });

            }
          });
        setData(combineArr);
        setCopyArr(combineArr);

        for (let i = 0; i < combineArr.length; i++) {
          if (combineArr[i].merchantId) {
            db.collection("merchants-live")
              .where("merchantNumber", "==", combineArr[i].merchantId)
              .get()
              .then((snapshots) => {
                if (!snapshots.empty) {
                  snapshots.docs.forEach((doc) => {
                    let docId = doc.data().docId;
                    db.collection("merchants-live")
                      .doc(docId)
                      .collection("batch-summary")
                      .get()
                      .then((subSnapshot) => {
                        subSnapshot.docs.forEach((subDoc) => {
                          subDoc = subDoc.data();
                          count += Number(subDoc.transactionCount);
                        });
                        setTransactionLen(count);
                      });
                  });
                } else {
                  setTransactionLen("0");
                }
              });
          }
        }
      }).then(() => {
        setDataLoaded(true)
      })
  };

  useEffect(() => {
    if (!data) {
      getData();
    }
  }, []);

  if (!data || !dataLoaded) return <Loader />;

  return (
    <div>
      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem xs={2}>
            <Typography
              variant="h2"
              className={classes.dashboardTitle}
              style={{ paddingBottom: "10%" }}
            >
              Merchants
            </Typography>

            <Button
              className={classes.addButton}
              onClick={() => {
                history.push(
                  `/iso-staff/${userCompany
                    .replace(/ /g, "")
                    .toLowerCase()}/add-merchant`
                );
              }}
            >
              <Add /> Invite User
            </Button>
          </GridItem>

          <GridItem xs={3}>
            <Card className={classes.card}>
              <GridContainer style={{ marginBottom: "15px" }}>
                <GridItem xs={2}>
                  <AiOutlineDollar style={{ marginTop: "5px" }} />
                </GridItem>
                <GridItem xs={5}>Total Merchants</GridItem>
                <GridItem
                  xs={5}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontWeight: 600,
                    fontSize: "21px",
                  }}
                >
                  {numberFormatter.format(copyArr.length)}
                </GridItem>
              </GridContainer>
              <div style={{ borderTop: "2px solid #FECD2C" }} />
              <GridContainer style={{ marginTop: "15px" }}>
                <GridItem xs={2}>
                  <AiOutlineDollar style={{ marginTop: "5px" }} />
                </GridItem>
                <GridItem xs={5}>Total Transactions</GridItem>
                <GridItem
                  xs={5}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontWeight: 600,
                    fontSize: "21px",
                  }}
                >
                  {numberFormatter.format(transactionLen)}
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
          <GridItem xs={7}>
            <Card className={classes.card}>
              <GridContainer style={{ marginTop: "20px" }}>
                <GridItem xs={2}>
                  <p className={classes.circleChartDesc}>
                    Wish List Items{"\n"}by Category
                  </p>
                </GridItem>
                <GridItem xs={10}>
                  <GridContainer>
                    <GridItem xs={3}>
                      <div style={{ display: "flex" }}>
                        <DonutChart
                          data={[
                            {
                              // label: 'Luxury Goods',
                              value: 0,
                            },
                            {
                              label: "",
                              value: 0,
                              isEmpty: true,
                            },
                          ]}
                          innerRadius={0.5}
                          outerRadius={0.9}
                          legend={false}
                          interactive={false}
                          height={100}
                          width={50}
                          formatValues={() =>
                            `${((50 / 100) * 100).toFixed(2)}%`
                          }
                          colors={["#E2A400"]}
                          strokeColor="#E2A400"
                          colorFunction={(colors, index) =>
                            colors[index % colors.length]
                          }
                          emptyColor={"#FECD2C"}
                        />
                        <p className={classes.circleChartLabel}>
                          Luxury{"\n"}Goods
                        </p>
                      </div>
                    </GridItem>
                    <GridItem xs={3}>
                      <div style={{ display: "flex" }}>
                        <DonutChart
                          data={[
                            {
                              // label: 'Luxury Goods',
                              value: 0,
                            },
                            {
                              value: 0,
                              isEmpty: true,
                            },
                          ]}
                          innerRadius={0.5}
                          outerRadius={0.9}
                          legend={false}
                          interactive={false}
                          height={100}
                          width={50}
                          formatValues={() =>
                            `${((50 / 100) * 100).toFixed(2)}%`
                          }
                          colors={["#E2A400"]}
                          strokeColor="#E2A400"
                          colorFunction={(colors, index) =>
                            colors[index % colors.length]
                          }
                          emptyColor={"#FECD2C"}
                        />
                        <p className={classes.circleChartLabel}>Electronics</p>
                      </div>
                    </GridItem>
                    <GridItem xs={3}>
                      <div style={{ display: "flex" }}>
                        <DonutChart
                          data={[
                            {
                              // label: 'Luxury Goods',
                              value: 0,
                            },
                            {
                              value: 0,
                              isEmpty: true,
                            },
                          ]}
                          innerRadius={0.5}
                          outerRadius={0.9}
                          legend={false}
                          interactive={false}
                          height={100}
                          width={50}
                          formatValues={() =>
                            `${((50 / 100) * 100).toFixed(2)}%`
                          }
                          colors={["#E2A400"]}
                          strokeColor="#E2A400"
                          colorFunction={(colors, index) =>
                            colors[index % colors.length]
                          }
                          emptyColor={"#FECD2C"}
                        />
                        <p className={classes.circleChartLabel}>Travel</p>
                      </div>
                    </GridItem>
                    <GridItem xs={3}>
                      <div style={{ display: "flex" }}>
                        <DonutChart
                          data={[
                            {
                              // label: 'Luxury Goods',
                              value: 0,
                            },
                            // {
                            //     label: '',
                            //     value: 100 - 10,
                            //     isEmpty: true,
                            // },
                          ]}
                          innerRadius={0.5}
                          outerRadius={0.9}
                          legend={false}
                          interactive={false}
                          height={100}
                          width={50}
                          formatValues={() =>
                            `${((50 / 100) * 100).toFixed(2)}%`
                          }
                          colors={["#E2A400"]}
                          strokeColor="#E2A400"
                          colorFunction={(colors, index) =>
                            colors[index % colors.length]
                          }
                          emptyColor={"#FECD2C"}
                        />
                        <p className={classes.circleChartLabel}>
                          Gift{"\n"}Cards
                        </p>
                      </div>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>

          <GridItem xs={12}>
            <Card className={classes.cardTable}>
              <GridContainer>
                {data.length > 0 || copyArr.length > 0 ? (
                  <GridItem xs={12}>
                    <Filter
                      rewardsSwitch={rewardsSwitch}
                      setRewardsSwitch={setRewardsSwitch}
                      statusDropdown={statusDropdown}
                      setStatusDropdown={setStatusDropdown}
                      ascDropdown={ascDropdown}
                      setAscDropdown={setAscDropdown}
                      searchString={searchString}
                      setSearchString={setSearchString}
                      listSwitch={listSwitch}
                      setListSwitch={setListSwitch}
                      statusArr={statusArr}
                      ascArr={ascArr}
                      itemsPerPage={itemsPerPage}
                      setItemsPerPage={setItemsPerPage}
                      entriesArr={entriesArr}
                      handleSearch={handleSearch}
                      emptyData={emptyData}
                      resetEmptyData={resetEmptyData}
                      handleFilter={handleFilter}
                    />
                  </GridItem>
                ) : null}

                <GridItem xs={12}>
                  <Table
                    copyArr={copyArr}
                    data={data}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    index={index}
                    setIndex={setIndex}
                    endIndex={endIndex}
                    setEndIndex={setEndIndex}
                    itemsPerPage={itemsPerPage}
                    handlePageClick={handlePageClick}
                    history={history}
                    setTransferModal={setTransferModal}
                    setCurrMerchant={setCurrMerchant}
                    getIsoEmail={getIsoEmail}
                  />
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
        </GridContainer>
      </Hidden>

      <Dialog
        classes={{
          paper: classes.transferModal
        }}
        open={transferModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <Card className={classes.modalCard}>
          <GridContainer>
            <GridItem xs={12}>
              <h5 className={classes.cardHeaderModal}>Transfer Points To: {currMerchant.companyName}</h5>
            </GridItem>

            <GridItem xs={5} className={classes.gridItemMarginTop}>
              <Typography
                style={errors.points ? { marginTop: "30px" } : {}}
                className={classes.modalCardDesc}>
                Amount of Points
              </Typography>
            </GridItem>

            <GridItem xs={7} className={classes.gridItemMarginTop}>
              <div>
                {errors.points ? (
                  <span className={classes.errText}> Please fill out the field</span>
                ) : null}
                <TextField
                  placeholder="0"
                  className={classes.modalTextField}
                  value={points}
                  onChange={(e) => formatInput(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input
                    },
                  }}
                />
              </div>
            </GridItem>

            <GridItem xs={5} className={classes.gridItemMarginTop}>
              <Typography
                className={classes.modalCardDesc}>
                Message for Merchant
              </Typography>
            </GridItem>

            <GridItem xs={7} className={classes.gridItemMarginTop}>
              <div>
                <TextareaAutosize
                  style={{ height: "66px", color: "#1A405F", paddingTop: "5px" }}
                  className={classes.modalTextField}
                  value={bonusMessage}
                  onChange={(e) => setBonusMessage(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input
                    },
                  }}
                />
              </div>
              </GridItem>

              <GridItem xs={12} style={{ display: "flex", justifyContent: "space-evenly", marginTop: "15px" }}>
                <Button
                  onClick={() => resetTransferModal()}
                  className={classes.modalButton}>
                  <Cancel style={{ marginRight: "10px", marginTop: "-5px" }} /> Cancel
                </Button>
                <Button
                  onClick={() => {
                    setTransferModal(false)
                    setConfirmModal(true)
                  }}
                  className={classes.modalButton}
                  style={{ background: "#1A405F", }}>
                  <CheckCircle style={{ marginRight: "10px", marginTop: "-5px" }} /> Send
                </Button>
              </GridItem>
          </GridContainer>
        </Card>
      </Dialog>

      <Dialog
        classes={{
          paper: classes.contactModal
        }}
        open={confirmModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <Card className={classes.modalCard}>
          <GridContainer>
            <GridItem xs={12}>
              <h5 className={classes.cardHeaderModal} style={{ fontSize: "22px", display: "flex", justifyContent: "center" }}>
                Are you sure you want to tranfer {points} points to {currMerchant.companyName}?
              </h5>
            </GridItem>
            <GridItem xs={12} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Typography className={classes.modalCardDesc} style={{ fontSize: "18px" }}>
                Current Pending Points: {numberFormatter.format(currMerchant.tuzoPoints ? currMerchant.tuzoPoints : 0)}
              </Typography>
              <Typography className={classes.modalCardDesc} style={{ fontSize: "18px" }}>
                After Point Transfer: {calculateAfterPoints(currMerchant.tuzoPoints ? currMerchant.tuzoPoints : 0, points)}
              </Typography>
            </GridItem>
            <GridItem xs={12} style={{ display: "flex", justifyContent: "space-evenly", marginTop: "15px" }}>
              <Button
                onClick={() => resetTransferModal()}
                className={classes.modalButton}>
                <Cancel style={{ marginRight: "10px", marginTop: "-5px" }} /> Cancel
              </Button>
              <Button
                onClick={() => transferPoints()}
                className={classes.modalButton}
                style={{ background: "#1A405F", }}>
                <CheckCircle style={{ marginRight: "10px", marginTop: "-5px" }} /> Confirm
              </Button>
            </GridItem>
          </GridContainer>

        </Card>
      </Dialog>

      <Dialog
        classes={{
          paper: classes.contactModal
        }}
        open={finishModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <Card className={classes.modalCard}>
          <GridContainer>
            <GridItem xs={12}>
              <h5 className={classes.cardHeaderModal} style={{ fontSize: "22px", display: "flex", justifyContent: "center" }}>Point Tranfer Complete!</h5>
            </GridItem>
            {/* <GridItem xs={12}>
                          <Typography className={classes.modalCardDesc} style={{ fontSize: "18px" }}>
                            Thanks for requesting a trip package! A travel concierge will reach out shortly to assist in your booking.
                          </Typography>
                        </GridItem> */}
            <GridItem xs={12} style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
              <Button
                onClick={() => {
                  setFinishModal(false)
                  window.location.reload()
                }}
                className={classes.modalButton}
                style={{ background: "#1A405F", }}>
                <Cancel style={{ marginRight: "10px", marginTop: "-5px" }} /> Close
              </Button>
            </GridItem>
          </GridContainer>

        </Card>
      </Dialog>

      <DialogLoader showModal={showModal} />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userCompany: state.auth.user.companyName,
    userEmail: state.auth.user.email,
    IsoAdminCompanyName: state.auth.user.IsoAdminCompanyName,
    user: state.auth.user
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({});
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
  )(Dashboard)
);
