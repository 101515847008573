import React, { useEffect } from "react";
import { useState } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { db } from "firebase/client";
import { addProduct } from "store/actions/cart";
import Card from "components/Card/Card";
import Map from "./components/Map";
import SelectRoom from "./components/SelectRoom";
import Search from "./components/Search";

// mui
import {
  Button,
  Hidden,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Loader from "components/Loader/Loader";

// stylings
import Style from "assets/jss/material-dashboard-pro-react/views/rewardsStyle";
import withStyles from "@material-ui/core/styles/withStyles";

// icons
import { ReactComponent as Add } from "assets/img/tuzo/icons/AddCircle.svg";
import { addDatasource } from "store/actions/recordset";

const quantityArr = ["1", "2", "3", "4", "5"];
const denominationArr = [100];

export function RewardsDetail(props) {
  const { classes, history, rewardData, addProduct, cart, user } = props;

  const numberFormatter = new Intl.NumberFormat("en-US", {});

  const numberFormatterDecimals = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [denomination, setDenomination] = useState(denominationArr[0]);
  const [quantity, setQuantity] = useState("1");
  const [price, setPrice] = useState("");
  const [adults, setAdults] = useState("");
  const [children, setChildren] = useState("");
  const [data, setData] = useState(rewardData ? rewardData : false);

  // const updateQuantity = (quantity) => {
  //     let toNumQty = Number(quantity)
  //     let toNumDenom = data.giftCardType ? Number(denomination) : data.salePrice
  //     const newTotal = toNumQty * toNumDenom
  //     let newData = { ...data }
  //     console.log("checking new data", newData)
  //     newData["salePrice"] = newTotal
  //     newData["salePricePoints"] = newTotal * 100
  //     newData["quantity"] = quantity

  //     setPrice(newTotal)
  //     setData(newData)
  // }

  // useEffect(() => {
  //     updateQuantity(quantity)
  // }, [])

  useEffect(() => {
    if (data) {
      setFromDate(moment(data.startDate).format("MM/DD/YYYY"));
      setToDate(moment(data.endDate).format("MM/DD/YYYY"));
      setAdults(data.adults);
      setChildren(data.children ? data.children : 0);
    }
  }, []);

  if (!data && !rewardData) return <Loader />;

  return (
    <div>
      {/* <Hidden mdUp implementation="css">
                <div style={{ marginTop: "50px" }}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Typography variant="h2" className={classes.title}>
                                Reward Detail
                            </Typography>
                        </GridItem>

                        <GridItem xs={12}>
                            {user.userType.includes('Merchants') ?
                                !data.added ?
                                    <Button
                                        onClick={() => {
                                            checkedAdded()
                                            addProduct(data)
                                        }}
                                        className={classes.addToCartButtonDetailMobile}>
                                        <Add className={classes.icon} /> Add to Cart
                                    </Button>
                                    :
                                    <Button
                                        style={{ background: "#06667C" }}
                                        className={classes.addToCartButtonDetailMobile}>
                                        <Add className={classes.icon} /> 
                                        Added to Cart
                                    </Button>
                                :
                                null
                            }
                        </GridItem>

                        <GridItem xs={12}>
                            {user.userType.includes('Merchants') ?
                                <Button
                                    onClick={() => {
                                        checkedAdded()
                                        addProduct(data)
                                        if (user.IsoAdminCompanyName) {
                                            history.push(`/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/cart`)
                                        } else {
                                            history.push('/merchant/cart')
                                        }

                                    }}
                                    className={classes.addToCartButtonDetailMobile} style={{ background: "#56CA93" }}>
                                    <Add className={classes.icon} /> Redeem Now
                                </Button>
                                : null
                            }
                        </GridItem>

                        <GridItem xs={12}>
                            <Card className={classes.detailCard}>
                                <GridContainer>
                                    <GridItem xs={5}>
                                        <img
                                            className={classes.cardListImg}
                                            src={data.mainImage}
                                            alt="img here"
                                        />
                                    </GridItem>

                                    <GridItem xs={12}>
                                        <Typography className={classes.rewardDetailName}>
                                            {data.rewardName}
                                        </Typography>

                                        <Typography className={classes.rewardDetailDescription}>
                                            {data.topDescription.length > 8 ? <span dangerouslySetInnerHTML={{ __html: data.topDescription }}></span> : "No Description"}
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={12}>
                                        <div style={{ display: "flex", marginTop: "15px" }}>
                                            <Typography className={classes.detailDesc}>
                                                SKU: <span style={{ fontWeight: 400 }}>
                                                    {data.sku}
                                                </span>
                                            </Typography>

                                            <Typography className={classes.detailDesc}>
                                                Category:
                                                <span style={{ fontWeight: 400 }}>
                                                    {data.categories.electronics === true ? " Electronics"
                                                        :
                                                        data.categories.sportsOutdoors === true ? " Experiences"
                                                            :
                                                            data.categories.giftCards === true ? " Gift Cards"
                                                                :
                                                                data.categories.luxuryGoods === true ? " Luxury Goods"
                                                                    :
                                                                    data.categories.homeKitchen === true ? " Home/Kitchen"
                                                                        :
                                                                        " Travel"}
                                                </span>
                                            </Typography>
                                        </div>
                                    </GridItem>

                                    {user.userType.includes('Merchants') ?
                                        <GridItem xs={12}>
                                            {data.giftCardType ?
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <Typography className={classes.rewardDetailDescriptionDropdown}>
                                                        Denomination
                                                    </Typography>
                                                    <Select
                                                        style={{ width: "100%" }}
                                                        className={classes.selectMenuSortDetailRewardsPage}
                                                        value={denomination}
                                                        disableUnderline
                                                        classes={{
                                                            root: classes.selectText,
                                                        }}
                                                        onChange={(e) => {
                                                            setDenomination(e.target.value)
                                                        }}
                                                    >
                                                        {denominationArr.map((o, i) => {
                                                            return (
                                                                <MenuItem
                                                                    key={i}
                                                                    value={o}
                                                                    classes={{
                                                                        root: classes.selectMenuItem,
                                                                    }}
                                                                >
                                                                    {o}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>

                                                    <Typography className={classes.rewardDetailDescriptionDropdown}>
                                                        Type
                                                    </Typography>

                                                    <Select
                                                        style={{ width: "100%" }}
                                                        className={classes.selectMenuSortDetailRewardsPage}
                                                        value={giftCardType}
                                                        disableUnderline
                                                        classes={{
                                                            root: classes.selectText,
                                                        }}
                                                        onChange={(e) => {
                                                            setGiftCardType(e.target.value)
                                                        }}
                                                    >
                                                        {data.giftCardType.map((o, i) => {
                                                            return (
                                                                <MenuItem
                                                                    key={i}
                                                                    value={o}
                                                                    classes={{
                                                                        root: classes.selectMenuItem,
                                                                    }}
                                                                >
                                                                    {o}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>


                                                    <Typography className={classes.rewardDetailDescriptionDropdown}>
                                                        Quantity
                                                    </Typography>
                                                    <Select
                                                        style={{ width: "100%" }}
                                                        className={classes.selectMenuSortDetailRewardsPage}
                                                        value={quantity}
                                                        disableUnderline
                                                        classes={{
                                                            root: classes.selectText,
                                                        }}
                                                        onChange={(e) => {
                                                            setQuantity(e.target.value)
                                                            updateQuantity(e.target.value)
                                                        }}
                                                    >
                                                        {quantityArr.map((o, i) => {
                                                            return (
                                                                <MenuItem
                                                                    key={i}
                                                                    value={o}
                                                                    classes={{
                                                                        root: classes.selectMenuItem,
                                                                    }}
                                                                >
                                                                    {o}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>

                                                </div>
                                                :
                                                <div style={{ display: "flex" }}>
                                                    <Select
                                                        className={classes.selectMenuSortDetailRewardsPage}
                                                        value={quantity}
                                                        disableUnderline
                                                        classes={{
                                                            root: classes.selectText,
                                                        }}
                                                        onChange={(e) => {
                                                            setQuantity(e.target.value)
                                                            updateQuantity(e.target.value)
                                                        }}
                                                    >
                                                        {quantityArr.map((o, i) => {
                                                            return (
                                                                <MenuItem
                                                                    key={i}
                                                                    value={o}
                                                                    classes={{
                                                                        root: classes.selectMenuItem,
                                                                    }}
                                                                >
                                                                    {o}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>

                                                    <Typography className={classes.rewardDetailDescriptionDropdown}>
                                                        Quantity
                                                    </Typography>
                                                </div>
                                            }
                                        </GridItem>
                                        : null}
                                </GridContainer>
                            </Card>
                        </GridItem>

                        <GridItem xs={12} style={{ marginTop: "-30px" }}>
                            <Quantity
                                user={user}
                                quantity={quantity}
                                price={price}
                                giftCardType={giftCardType}
                                addProduct={addProduct}
                                data={data}
                                denomination={denomination}
                                checkAdded={checkedAdded}
                            />
                        </GridItem>
                    </GridContainer>
                </div>
            </Hidden> */}

      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h2" className={classes.title}>
              Travel Detail
            </Typography>

            {/* {user.userType.includes('Merchants') ?
                            !data.added ?
                                <Button
                                    onClick={() => {
                                        checkedAdded()
                                        addProduct(data)
                                    }}
                                    className={classes.addToCartButtonDetail}>
                                    <Add className={classes.icon} /> Add to Cart
                                </Button>
                                :
                                <Button
                                    style={{ background: "#06667C" }}
                                    className={classes.addToCartButtonDetail}>
                                    Added to Cart
                                </Button>
                            :
                            null
                        } */}

            {/* {user.userType.includes('Merchants') ?
                            <Button
                                onClick={() => {
                                    checkedAdded()
                                    addProduct(data)
                                    if (user.IsoAdminCompanyName) {
                                        history.push(`/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/cart`)
                                    } else {
                                        history.push('/merchant/cart')
                                    }

                                }}
                                className={classes.addToCartButtonDetail} style={{ background: "#56CA93" }}>
                                <Add className={classes.icon} /> Redeem Now
                            </Button>
                            : null
                        } */}
          </GridItem>

          <GridItem xs={8}>
            <Card className={classes.detailCard}>
              <GridContainer>
                <GridItem xs={12}>
                  <img
                    className={classes.cardListImg}
                    src={data.ImageURL}
                    alt="img here"
                  />
                </GridItem>

                <GridItem xs={7} style={{ marginTop: "15px" }}>
                  <GridContainer>
                    <GridItem xs={7}>
                      <Typography className={classes.travelDetailName}>
                        {data.HotelName}
                      </Typography>
                    </GridItem>
                    <GridItem
                      xs={5}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Typography className={classes.travelDetailPts}>
                        {numberFormatter.format(
                          data.nightlyRate.AmountAfterTax * 100
                        )}{" "}
                        <span style={{ fontSize: "13px" }}> points</span>
                      </Typography>
                    </GridItem>
                  </GridContainer>

                  <Typography className={classes.travelDetailNightlyDesc}>
                    {moment(data.startDate).format("MM/DD/YYYY") +
                      " - " +
                      moment(data.endDate).format("MM/DD/YYYY") +
                      " ("}
                    {moment(data.endDate).diff(data.startDate, "days") -
                      1 +
                      " Nights, " +
                      data.adults +
                      " Guest(s), 1 Room)"}
                  </Typography>

                  <Typography className={classes.travelDetailNightlyDesc}>
                    Popular Amenities
                  </Typography>

                  <GridContainer>
                    {data.Amenities.Amenity.map((d, index) => (
                      <GridItem xs={6}>
                        <Typography
                          className={classes.travelDetailNightlyDesc}
                          style={{ fontWeight: 400 }}
                        >
                          • {d.Description}
                        </Typography>
                      </GridItem>
                    ))}
                  </GridContainer>
                </GridItem>

                <GridItem xs={5}>
                  <Map
                    hotelLongitude={Number(data.longitude)}
                    hotelLatitude={Number(data.latitude)}
                  />

                  <Typography
                    className={classes.travelDetailNightlyDesc}
                    style={{ fontWeight: 400 }}
                  >
                    {data.StateProv
                      ? data.AddressLine1 +
                        " " +
                        data.CityName +
                        ", " +
                        data.StateProv +
                        " " +
                        data.PostalCode
                      : data.AddressLine1 +
                        " " +
                        data.CityName +
                        ", " +
                        data.PostalCode}
                  </Typography>

                  <div style={{ display: "flex", marginTop: "15px" }}>
                    <Typography className={classes.detailDesc}>
                      SKU:{" "}
                      <span style={{ fontWeight: 400 }}>
                        {data.sku ? data.sku : ""}
                      </span>
                    </Typography>

                    <Typography className={classes.detailDesc}>
                      Category:
                      <span style={{ fontWeight: 400 }}> Travel</span>
                    </Typography>
                  </div>
                </GridItem>

                {user.userType.includes("Merchants") ? (
                  <GridItem xs={7}>
                    {
                      // data.giftCardType ?
                      //     <div style={{ display: "flex" }}>
                      //         <Select
                      //             className={classes.selectMenuSortDetailRewardsPage}
                      //             value={denomination}
                      //             disableUnderline
                      //             classes={{
                      //                 root: classes.selectText,
                      //             }}
                      //             onChange={(e) => {
                      //                 setDenomination(e.target.value)
                      //             }}
                      //         >
                      //             {denominationArr.map((o, i) => {
                      //                 return (
                      //                     <MenuItem
                      //                         key={i}
                      //                         value={o}
                      //                         classes={{
                      //                             root: classes.selectMenuItem,
                      //                         }}
                      //                     >
                      //                         {o}
                      //                     </MenuItem>
                      //                 );
                      //             })}
                      //         </Select>

                      //         <Typography className={classes.rewardDetailDescriptionDropdown}>
                      //             Denomination
                      //         </Typography>

                      //         <Select
                      //             style={{ marginLeft: "10px" }}
                      //             className={classes.selectMenuSortDetailRewardsPage}
                      //             value={giftCardType}
                      //             disableUnderline
                      //             classes={{
                      //                 root: classes.selectText,
                      //             }}
                      //             onChange={(e) => {
                      //                 setGiftCardType(e.target.value)
                      //             }}
                      //         >
                      //             {data.giftCardType.map((o, i) => {
                      //                 return (
                      //                     <MenuItem
                      //                         key={i}
                      //                         value={o}
                      //                         classes={{
                      //                             root: classes.selectMenuItem,
                      //                         }}
                      //                     >
                      //                         {o}
                      //                     </MenuItem>
                      //                 );
                      //             })}
                      //         </Select>

                      //         <Typography className={classes.rewardDetailDescriptionDropdown}>
                      //             Type
                      //         </Typography>

                      //         <Select
                      //             style={{ marginLeft: "10px", width: "50px" }}
                      //             className={classes.selectMenuSortDetailRewardsPage}
                      //             value={quantity}
                      //             disableUnderline
                      //             classes={{
                      //                 root: classes.selectText,
                      //             }}
                      //             onChange={(e) => {
                      //                 setQuantity(e.target.value)
                      //                 updateQuantity(e.target.value)
                      //             }}
                      //         >
                      //             {quantityArr.map((o, i) => {
                      //                 return (
                      //                     <MenuItem
                      //                         key={i}
                      //                         value={o}
                      //                         classes={{
                      //                             root: classes.selectMenuItem,
                      //                         }}
                      //                     >
                      //                         {o}
                      //                     </MenuItem>
                      //                 );
                      //             })}
                      //         </Select>

                      //         <Typography className={classes.rewardDetailDescriptionDropdown} style={{ marginLeft: "10px" }}>
                      //             Quantity
                      //         </Typography>
                      //     </div>
                      //     :
                      !data.HotelCode ? (
                        <div style={{ display: "flex" }}>
                          <Select
                            style={{ width: "50px" }}
                            className={classes.selectMenuSortDetailRewardsPage}
                            value={quantity}
                            disableUnderline
                            classes={{
                              root: classes.selectText,
                            }}
                            onChange={(e) => {
                              setQuantity(e.target.value);
                              // updateQuantity(e.target.value)
                            }}
                          >
                            {quantityArr.map((o, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  value={o}
                                  classes={{
                                    root: classes.selectMenuItem,
                                  }}
                                >
                                  {o}
                                </MenuItem>
                              );
                            })}
                          </Select>

                          <Typography
                            className={classes.rewardDetailDescriptionDropdown}
                            style={{ marginLeft: "10px" }}
                          >
                            Quantity
                          </Typography>
                        </div>
                      ) : null
                    }
                  </GridItem>
                ) : null}
              </GridContainer>
            </Card>
          </GridItem>

          {/* <GridItem xs={4}>
                        <Search
                            data={data}
                            user={user}
                            fromDate={fromDate}
                            setFromDate={setFromDate}
                            toDate={toDate}
                            setToDate={setToDate}
                            adults={adults}
                            setAdults={setAdults}
                            children={children}
                            setChildren={setChildren}
                        />
                    </GridItem> */}

          <GridItem xs={8}>
            <SelectRoom data={data} />
          </GridItem>
        </GridContainer>
      </Hidden>
    </div>
  );
}

function mapStateToProps(state, location) {
  return {
    rewardData: location.history.location.state?.rewardData,
    cart: state.cart.cart,
    user: state.auth.user,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    addProduct: (product) => dispatch(addProduct(product)),
  });
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
  )(RewardsDetail)
);
