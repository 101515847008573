import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/cartStyle';
import withStyles from '@material-ui/core/styles/withStyles';

// icons
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";


// core components
import { Button, Checkbox, FormControlLabel, Hidden, Typography } from "@material-ui/core";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import { MockUserData } from 'variables/config';



export function ItemInfo(props) {
    const {
        classes,
        history,
        userData
    } = props;

    return (
        <div>
            {/* <Hidden mdUp implementation='css'>
                <Card className={classes.card}>
                    <GridContainer style={{ alignItems: "center" }}>
                        <GridItem xs={12}>
                            <Typography className={classes.totalCost}>
                                Merhcant Information
                            </Typography>
                        </GridItem>
                    </GridContainer>

                    {orderData.map((data, index) => (
                        <div className={classes.itemBorderDiv} key={index}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <img
                                        className={classes.mainImg}
                                        src={data.mainImage}
                                    />
                                </GridItem>

                                <GridItem xs={12} >
                                    <Typography className={classes.cardSubtitle}
                                        onClick={() => history.push(
                                            `/merchant/reward-detail/${data.rewardId}`,
                                            {
                                                rewardData: data
                                            }
                                        )}
                                    >
                                        {data.rewardName}
                                    </Typography>

                                    <Typography className={classes.cardDescription}>
                                        {data.topDescription.length > 8 ? <span dangerouslySetInnerHTML={{ __html: data.topDescription }}></span> : "No Description"}
                                    </Typography>

                                    <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                                        <Typography
                                            className={classes.cardPolicy}
                                            onClick={() => {
                                                window.open("https://www.tuzorewards.com/contact/terms-of-use/",
                                                    '_blank',
                                                    'noopener,noreferrer'
                                                )
                                            }}
                                        >
                                            Rules, policies and cancellations
                                        </Typography>

                                        <Typography
                                            className={classes.cardPolicy}
                                            onClick={() => history.push(
                                                `/merchant/reward-detail/${data.rewardId}`,
                                                {
                                                    rewardData: data
                                                }
                                            )}
                                        >
                                            Details
                                        </Typography>

                                        {data.customerService ?
                                            <div style={{ display: "flex", }}>
                                                <Typography
                                                    className={classes.cardPolicy}
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    Contact:
                                                </Typography>

                                                <Typography
                                                    className={classes.cardPolicy}
                                                    style={{ marginLeft: "15px", fontWeight: 400, textDecoration: "none" }}
                                                >
                                                    Contact Info Here
                                                </Typography>
                                            </div>
                                            :
                                            null
                                        }


                                    </div>
                                </GridItem>

                                <GridItem xs={12} style={{ display: "flex", alignItems: "flex-end", flexDirection: "column" }}> */}
                                    {/* <Typography className={classes.totalCost}>
                                ${numberFormatterDecimals.format(data.salePrice)}
                            </Typography> */}

                                    {/* <Typography className={classes.totalCostMarginTop}>
                                        {(data.salePricePoints).toLocaleString()} points
                                    </Typography>

                                    <Typography className={classes.cardDescriptionFees}>
                                        Includes {data.taxClass} tax/fees
                                    </Typography>
                                </GridItem>
                            </GridContainer>
                        </div>
                    ))}
                </Card>
            </Hidden> */}

            <Hidden smDown implementation='css'>
                <Card className={classes.card}>
                    <GridContainer style={{ alignItems: "center" }}>
                        <GridItem xs={12}>
                            <Typography className={classes.totalCost}>
                                Merchant Information
                            </Typography>
                        </GridItem>

                        <GridItem xs={12}>
                            <Typography 
                                className={classes.merchantInfoUnderline}
                                onClick={() => history.push(`/admin/user-profile/${userData.uid}`)}
                            >
                                {userData.firstName + " " + userData.lastName}
                            </Typography>
                        </GridItem>

                        <GridItem xs={12}>
                            <Typography className={classes.merchantInfo}>
                                Account #: {userData.uid}
                            </Typography>
                        </GridItem>

                        <GridItem xs={12}>
                            <Typography className={classes.merchantInfo}>
                                {userData.email}
                            </Typography>
                        </GridItem>

                        <GridItem xs={12}>
                            <Typography className={classes.merchantInfo}>
                                {userData.cellPhone ? userData.cellPhone : "N/A"}
                            </Typography>
                        </GridItem>
                    </GridContainer>

                </Card>
            </Hidden>
        </div>

    )
}

function mapStateToProps(state) {
    return {
        cart: state.cart.cart,
        user: state.auth.user,
        globalCalculator: state.search.globalCalculatorOpen
    };
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(ItemInfo);