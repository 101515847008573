import React, { useEffect } from 'react';
import ReactPaginate from "react-paginate";
import "views/Pages/reports/batch/components/pagination.css";

// core components
import Box from '@material-ui/core/Box';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CardContent, IconButton, Typography } from '@material-ui/core';

// @material-ui/icons
import { ReactComponent as Info } from "assets/img/tuzo/icons/InfoGreen.svg";
import { ReactComponent as Diamond } from "assets/img/tuzo/icons/DiamondGreen.svg";
import { ReactComponent as Plane } from "assets/img/tuzo/icons/PlaneGreen.svg";
import { ReactComponent as Computer } from "assets/img/tuzo/icons/ComputerGreen.svg";
import { ReactComponent as SportsandOutdoors } from "assets/img/tuzo/icons/SportsAndOutdoors.svg"
import { ReactComponent as GiftCard } from "assets/img/tuzo/icons/GiftCardGreen.svg";
import { ReactComponent as HomeAndKitchen } from "assets/img/tuzo/icons/RaffleGreen.svg";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";

// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/ordersStyle';
import withStyles from '@material-ui/core/styles/withStyles';


export function CardListMobile(props) {
    const {
        classes,
        arr,
        history,
        user,
        pageNumber,
        setPageNumber,
        index,
        setIndex,
        mobileEndIndex,
        setMobileEndIndex,
        itemsPerPageMobile,
        orderType
    } = props;

    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
        setIndex(selected * itemsPerPageMobile);
        setMobileEndIndex(selected * itemsPerPageMobile + itemsPerPageMobile);
    };

    useEffect(() => {
        arr.slice(index, mobileEndIndex);
    }, [index]);

    return (
        <div>
            <GridContainer>
                {arr.slice(
                    pageNumber * itemsPerPageMobile,
                    pageNumber * itemsPerPageMobile + itemsPerPageMobile
                )
                    .map((data, index) => (
                        <>
                            {data.orderStatus === orderType ?
                                <GridItem xs={12} style={{ display: "flex", }}>
                                    <Card className={classes.rewardsCardListStyling}>
                                        <CardContent style={{ padding: "0px" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <IconButton
                                                    onClick={() => {
                                                        if (user.IsoAdminCompanyName) {
                                                            history.push(
                                                                `/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/orders-detail/${data.orderId}`)
                                                        }
                                                        else {
                                                            history.push(`/merchant/orders-detail/${data.orderId}`)
                                                        }

                                                    }}
                                                >
                                                    <Info />
                                                </IconButton>
                                                <IconButton className={classes.iconButton}>
                                                    {data.categories.luxuryGoods ?
                                                        <Diamond className={classes.icon} />
                                                        :
                                                        data.categories.travel ?
                                                            <Plane className={classes.icon} />
                                                            :
                                                            data.categories.electronics ?
                                                                <Computer className={classes.icon} />
                                                                :
                                                                data.categories.sportsOutdoors ?
                                                                    <SportsandOutdoors className={classes.icon} />
                                                                    :
                                                                    data.categories.giftCards ?
                                                                        <GiftCard className={classes.icon} />
                                                                        :
                                                                        <HomeAndKitchen className={classes.icon} />
                                                    }
                                                </IconButton>
                                            </div>

                                            <div
                                                onClick={() => {
                                                    if (user.IsoAdminCompanyName) {
                                                        history.push(
                                                            `/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/orders-detail/${data.orderId}`)
                                                    }
                                                    else {
                                                        history.push(`/merchant/orders-detail/${data.orderId}`)
                                                    }

                                                }}
                                                className={classes.divHover}
                                            >
                                                <img
                                                    src={data.mainImage}
                                                    className={classes.cardListImg}
                                                />

                                                <Typography variant="h2" className={classes.cardListTitleNoOverflow}>
                                                    {(data.rewardName).length < 33 ? <br /> : null}
                                                    {data.rewardName}
                                                </Typography>
                                            </div>

                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div>
                                                    <Typography variant="h2" className={classes.cardListDesc}>
                                                        Order Date
                                                    </Typography>
                                                    <Typography variant="h2" className={classes.cardListPts}>
                                                        12/29/2023
                                                    </Typography>
                                                </div>

                                                <div>
                                                    <Typography variant="h2" className={classes.cardListDesc}>
                                                        Points Price
                                                    </Typography>
                                                    <Typography variant="h2" className={classes.cardListPts}>
                                                        {(data.salePricePoints).toLocaleString()}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </GridItem>
                                : null}
                        </>
                    ))}
            </GridContainer>
            <GridContainer>
                <GridItem xs={8} style={{ marginTop: "5px", display: "flex", justifyContent: "flex-end" }}>
                    <h5
                        className={classes.indexes}
                    >
                        {index + 1} - {mobileEndIndex} of{" "}
                        {Math.ceil(arr.length / itemsPerPageMobile)}
                    </h5>
                </GridItem>
                <GridItem xs={4}>
                    <ReactPaginate
                        forcePage={pageNumber}
                        paginationSize={0}
                        onPageChange={handlePageClick}
                        pageCount={Math.ceil(arr.length / itemsPerPageMobile)}
                        nextLabel={
                            <ArrowRight
                                style={{
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    color: "white",
                                    marginTop: "-2px"
                                }}
                            />
                        }
                        previousLabel={
                            <ArrowLeft
                                style={{
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    color: "white",
                                    marginTop: "-2px"
                                }}
                            />
                        }
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                    />
                </GridItem>
            </GridContainer>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        userId: state.auth.user.uid,
        user: state.auth.user
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(CardListMobile));
