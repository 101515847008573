import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  ContentState,
  createWithContent,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Datetime from "react-datetime";
import moment from "moment";
import { db, firebase, storage } from "firebase/client.js";
import General from "./editFormComponents/General";
import MainImage from "./editFormComponents/MainImage";
import RewardGallery from "./editFormComponents/RewardGallery";
import Categories from "./editFormComponents/Categories";
import RoomOptions from "./editFormComponents/RoomOptions";
import TravelDescription from "./editFormComponents/TravelDescription";
import Amenities from "./editFormComponents/Amenities";
import TravelRewardName from "./editFormComponents/TravelRewardName";
import TravelGeneral from "./editFormComponents/TravelGeneral";
// core components
import GridContainer from "components/Grid/GridContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Hidden,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import Loader from "components/Loader/Loader";
import DialogLoader from "components/Loader/DialogLoader";

// icons
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";
import { BsCheckCircleFill } from "react-icons/bs";
import { BiSolidLeftArrow } from "react-icons/bi";

// styling
import Style from "assets/jss/material-dashboard-pro-react/views/rewardsStyle";
import "./calendar.css";
import loadingWheel from "assets/img/Tuzo-Preloader-Transparent.gif";

const statusArr = ["Draft", "Active", "Schedule"];

const taxStatusArr = ["Taxable", "None"];

const taxClassArr = ["Standard", "Zero Rate"];

const yesterday = moment().subtract(1, "day");
const disablePastDt = (current) => {
  return current.isAfter(yesterday);
};

export function EditTravelRewardsForm(props) {
  const { classes, history, user } = props;
  const docId = window.location.pathname.split("/")[5];

  const [status, setStatus] = useState("Draft");
  const [rewardName, setRewardName] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [topDescription, setTopDescription] = useState(
    EditorState.createEmpty()
  );
  const [longDescription, setLongDescription] = useState(
    EditorState.createEmpty()
  );
  const [amenities, setAmenities] = useState([]);
  const [other, setOther] = useState("");
  const [regularPrice, setRegularPrice] = useState("");
  const [regPriceConvert, setRegPriceConvert] = useState(0);
  const [salePrice, setSalePrice] = useState("");
  const [salePriceConvert, setSalePriceConvert] = useState(0);
  const [sku, setSku] = useState("");
  const [taxStatus, setTaxStatus] = useState("Taxable");
  const [taxClass, setTaxClass] = useState("Standard");
  const [notes, setNotes] = useState("");
  const [mainImage, setMainImage] = useState([]);
  const [rewardImages, setRewardImages] = useState([]);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [roomInfo, setRoomInfo] = useState([
    {
      roomName: "",
      roomDescription: EditorState.createEmpty(),
      nightlyPrice: "",
      nightlyPricePts: "",
      salePrice: "",
      salePricePts: "",
      roomMainImg: [],
      mainUrl: "",
      roomGallery: [],
      galleryUrl: [],
      id: 0,
    },
  ]);
  const [categories, setCategories] = useState({
    luxuryGoods: false,
    travel: true,
    electronics: false,
    sportsOutdoors: false,
    giftCards: false,
    homeKitchen: false,
  });
  const [openModal, setOpenModal] = useState(false);
  const [scheduleDate, setScheduleDate] = useState("");
  const [time, setTime] = useState("");
  const [calendarOpen, setCalendarOpen] = useState(false);

  useEffect(() => {
    if (status === "Schedule") {
      setOpenModal(true);
      setCalendarOpen(true);
    }
  }, [status]);

  const reset = () => {
    setOpenModal(false);
    setCalendarOpen(false);
    setStatus("Draft");
    setScheduleDate("");
    setTime("");
  };

  const handleChecked = (e) => {
    setCategories({
      ...categories,
      [e.target.value]: e.target.checked,
    });
  };

  const ptsCalculatorReg = (pts) => {
    setRegPriceConvert(pts * 100);
  };

  const ptsCalculatorSale = (pts) => {
    setSalePriceConvert(pts * 100);
  };

  const addRoom = () => {
    let idCount = "";
    setRoomInfo((prev) => {
      let arrLen = prev.length;
      idCount = prev[arrLen - 1].id + 1;
    });
    const emptyObj = {
      roomName: "",
      roomDescription: EditorState.createEmpty(),
      nightlyPrice: "",
      nightlyPricePts: "",
      salePrice: "",
      salePricePts: "",
      roomMainImg: [],
      roomGallery: [],
      id: idCount,
    };
    setRoomInfo([...roomInfo, emptyObj]);
  };

  const handleSelect = (address, placeholder) => {
    geocodeByAddress(address).then((results) => {
      const lat = results[0].geometry.location.lat();
      const lng = results[0].geometry.location.lng();
      if (placeholder === "Search your pick up address..") {
      }
      if (placeholder === "Search the address..") {
        const addressResults = results[0];
        addressResults.address_components.forEach((r) => {
          if (r.types.includes("locality")) {
            setCity(r.long_name);
          }
          if (r.types.includes("administrative_area_level_1")) {
            setState(r.short_name);
          }
          if (r.types.includes("postal_code")) {
            setZipCode(r.short_name);
          }
        });
        setAddress(addressResults.formatted_address);
      }
    });
  };

  const handleAmenities = (data) => {
    if (!amenities.includes(data)) {
      setAmenities((prev) => [...prev, data]);
    } else {
      const filtered = amenities.filter(function (value) {
        return value !== data;
      });
      setAmenities(filtered);
    }
  };

  const handleOther = (value) => {
    const index = amenities.indexOf("Other");
    if (index !== -1) {
      amenities[index] = value;
    }
  };

  const getDownloadURL = (array, index) => {
    setShowModal(true);
    let urlArr = [];
    array.map((image) => {
      if (typeof image !== "string") {
        const uploadTask = firebase
          .storage()
          .ref(
            `rewards-form/travel/${rewardName}/room/gallery-images/${image.name}`
          )
          .put(image);
        // promises.push(uploadTask);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
          },
          (error) => {
            console.log(error);
          },
          async () => {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            urlArr.push(downloadURL);
          }
        );
      } else {
        urlArr.push(image);
      }

      setRoomInfo((prevValue) => {
        return prevValue.map((item) => {
          if (item.id === index) {
            return { ...item, roomGallery: array, galleryUrl: urlArr };
          } else {
            return item;
          }
        });
      });
    });
    setTimeout(() => {
      setShowModal(false);
    }, 2000);
  };

  const getRoomMainImgUrl = async (array, index) => {
    setShowModal(true);
    let mainImageUrl = "";
    const file = array[0];
    await firebase
      .storage()
      .ref()
      .child(`rewards-form/travel/${rewardName}/room/main-image/${file.name}/`)
      .put(file)
      .then(async () => {
        await firebase
          .storage()
          .ref()
          .child(
            `rewards-form/travel/${rewardName}/room/main-image/${file.name}/`
          )
          .getDownloadURL()
          .then(async (url) => {
            mainImageUrl = url;
          })
          .then(() => {
            setRoomInfo((prevValue) => {
              return prevValue.map((item) => {
                if (item.id === index) {
                  return { ...item, roomMainImg: array, mainUrl: mainImageUrl };
                } else {
                  return item;
                }
              });
            });
          })
          .then(() => {
            setTimeout(() => {
              setShowModal(false);
            }, 1000);
          });
      });
  };

  const saveForm = async () => {
    setShowModal(true);
    let mainImageUrl = "";

    if (other) {
      handleOther(other);
    }

    const copyArr = [];
    roomInfo.forEach((val) => copyArr.push(Object.assign({}, val)));
    for (let i = 0; i < copyArr.length; i++) {
      let htmlValue = draftToHtml(
        convertToRaw(copyArr[i].roomDescription.getCurrentContent())
      );
      copyArr[i].roomDescription = htmlValue;
      delete copyArr[i].roomGallery;
      delete copyArr[i].roomMainImg;
    }

    const data = {
      status,
      rewardName,
      topDescription: draftToHtml(
        convertToRaw(topDescription.getCurrentContent())
      ),
      longDescription: draftToHtml(
        convertToRaw(longDescription.getCurrentContent())
      ),
      regularPrice,
      regularPricePoints: regPriceConvert,
      salePrice,
      salePricePoints: salePriceConvert,
      sku,
      taxStatus,
      taxClass,
      notes,
      mainImage: mainImageUrl,
      categories,
      scheduleDate,
      time,
      timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
      roomOptions: copyArr,
      amenities,
      address,
      state,
      city,
      zipCode,
    };

    try {
      db.collection("rewards-list-test")
        .doc(docId)
        .update(data)
        .catch((error) => console.log("checking update error", error))
        .then(() => {
          if (mainImage.length > 0 && typeof mainImage !== "string") {
            const file = mainImage[0];
            const uploadTask = firebase
              .storage()
              .ref(`rewards-form/${rewardName}/main-image/${file.name}`)
              .put(file);
            // promises.push(uploadTask);
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const progress = Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
              },
              (error) => {
                console.log(error);
              },
              async () => {
                const mainImageURL = await uploadTask.snapshot.ref.getDownloadURL();

                await db.collection("rewards-list-test").doc(docId).update({
                  mainImage: mainImageURL,
                });
              }
            );
          }
          if (rewardImages.length > 0) {
            rewardImages.map((image) => {
              if (typeof image !== "string") {
                const uploadTask = firebase
                  .storage()
                  .ref(
                    `/rewards-form/${rewardName}/gallery-images/${image.name}`
                  )
                  .put(image);
                // promises.push(uploadTask);
                uploadTask.on(
                  "state_changed",
                  (snapshot) => {
                    const progress = Math.round(
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                  },
                  (error) => {
                    console.log(error);
                  },
                  async () => {
                    const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();

                    await db
                      .collection("rewards-list-test")
                      .doc(docId)
                      .update({
                        galleryImages: firebase.firestore.FieldValue.arrayUnion(
                          downloadURL
                        ),
                      })
                      .then(() => {
                        setShowModal(false);
                        setTimeout(() => {
                          window.location.href = "/admin/rewards";
                        }, 3000);
                      });
                  }
                );
              } else {
                db.collection("rewards-list-test")
                  .doc(docId)
                  .update({
                    galleryImages: rewardImages,
                  })
                  .then(() => {
                    setShowModal(false);
                    setTimeout(() => {
                      window.location.href = "/admin/rewards";
                    }, 3000);
                  });
              }
            });
          } else {
            setShowModal(false);
            setTimeout(() => {
              window.location.href = "/admin/rewards";
            }, 1000);
          }
        });
    } catch (error) {
      console.log("checking error", error);
    }
  };

  useEffect(() => {
    if (!dataLoaded) {
      const getData = () => {
        db.collection("rewards-list-test")
          .doc(docId)
          .get()
          .then((snapshot) => {
            let rewardsData = snapshot.data();
            let copyArr = [];
            if (rewardsData.roomOptions && rewardsData.roomOptions.length > 0) {
              rewardsData.roomOptions.forEach((val) =>
                copyArr.push(Object.assign({}, val))
              );
              for (let i = 0; i < copyArr.length; i++) {
                let htmlValue = EditorState.createWithContent(
                  ContentState.createFromBlockArray(
                    convertFromHTML(copyArr[i].roomDescription)
                  )
                );
                let galleryArr = copyArr[i].galleryUrl;
                copyArr[i].roomGallery = galleryArr;
                copyArr[i].roomDescription = htmlValue;
              }
            }
            setRewardName(rewardsData.rewardName);
            setRegularPrice(rewardsData.regularPrice);
            setRegPriceConvert(rewardsData.regularPricePoints);
            setSalePrice(rewardsData.salePrice);
            setSalePriceConvert(rewardsData.salePricePoints);
            setSku(rewardsData.sku);
            setStatus(
              rewardsData.status.charAt(0).toUpperCase() +
                rewardsData.status.slice(1)
            );
            setTaxStatus(rewardsData.taxStatus);
            setTaxClass(rewardsData.taxClass);
            setNotes(rewardsData.notes ? rewardsData.notes : "");
            setCategories({
              luxuryGoods: rewardsData.categories.luxuryGoods,
              travel: rewardsData.categories.travel,
              electronics: rewardsData.categories.electronics,
              sportsOutdoors: rewardsData.categories.sportsOutdoors,
              giftCards: rewardsData.categories.giftCards,
              homeKitchen: rewardsData.categories.homeKitchen,
            });
            setTopDescription(
              EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  convertFromHTML(rewardsData.topDescription)
                )
              )
            );
            setLongDescription(
              EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  convertFromHTML(rewardsData.longDescription)
                )
              )
            );
            setMainImage(rewardsData.mainImage ? rewardsData.mainImage : []);
            setRewardImages(
              rewardsData.galleryImages ? rewardsData.galleryImages : []
            );
            setScheduleDate(rewardsData.scheduleDate);
            setTime(rewardsData.time);
            setAmenities(rewardsData.amenities ? rewardsData.amenities : []);
            setRoomInfo(
              rewardsData.roomOptions && rewardsData.roomOptions.length > 0
                ? copyArr
                : [
                    {
                      roomName: "",
                      roomDescription: EditorState.createEmpty(),
                      nightlyPrice: "",
                      nightlyPricePts: "",
                      salePrice: "",
                      salePricePts: "",
                      roomMainImg: [],
                      mainUrl: "",
                      roomGallery: [],
                      galleryUrl: [],
                      id: 0,
                    },
                  ]
            );
            setAddress(rewardsData.address ? rewardsData.address : "");
            setCity(rewardsData.city ? rewardsData.city : "");
            setState(rewardsData.state ? rewardsData.state : "");
            setZipCode(rewardsData.zipCode ? rewardsData.zipCode : "");
          })
          .then(() => {
            setDataLoaded(true);
          });
      };
      getData();
    }
  }, []);

  if (!dataLoaded) return <Loader />;

  return (
    <div>
      <Hidden mdUp implementation="css">
        <div>
          <Typography
            variant="h2"
            className={classes.dashboardTitle}
            style={{ color: "#1A405F" }}
          >
            Please View Page on Desktop / Laptop
          </Typography>
        </div>
      </Hidden>

      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem xs={2}>
            <Typography variant="h2" className={classes.RewardsFormTitle}>
              Edit Reward
            </Typography>
          </GridItem>
          <GridItem xs={4}>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <Button
                className={classes.saveButton}
                style={{
                  background: "#1A405F",
                }}
                onClick={() => saveForm()}
              >
                <SaveIcon className={classes.icon} /> Save
              </Button>
              <Button className={classes.saveButton}>
                <ViewIcon className={classes.icon} /> Preview
              </Button>
              <Button
                className={classes.saveButton}
                style={{
                  background: "#56CA93",
                }}
                onClick={() => history.push("/admin/rewards")}
              >
                <TrashIcon className={classes.icon} /> Cancel
              </Button>
            </div>
          </GridItem>
          <GridItem
            xs={6}
            style={{ justifyContent: "flex-end", display: "flex" }}
          >
            <div style={{ display: "block" }}>
              <p
                style={{
                  fontFamily: "Josefin Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                  color: "#004253",
                  marginBottom: "-5px",
                }}
              >
                Status
              </p>
              <Select
                style={status === "Active" ? { color: "#56CA93" } : {}}
                className={classes.selectStatus}
                value={status}
                disableUnderline
                classes={{
                  root: classes.selectText,
                }}
                onChange={(e) => setStatus(e.target.value)}
              >
                {statusArr.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </GridItem>
          <GridItem xs={9}>
            <TravelRewardName
              rewardName={rewardName}
              setRewardName={setRewardName}
              handleSelect={handleSelect}
              address={address}
              setAddress={setAddress}
            />
            <TravelDescription
              topDescription={topDescription}
              setTopDescription={setTopDescription}
            />

            <Amenities
              handleAmenities={handleAmenities}
              amenities={amenities}
              other={other}
              setOther={setOther}
            />

            <TravelGeneral
              regPriceConvert={regPriceConvert}
              salePriceConvert={salePriceConvert}
              ptsCalculatorReg={ptsCalculatorReg}
              ptsCalculatorSale={ptsCalculatorSale}
              regularPrice={regularPrice}
              setRegularPrice={setRegularPrice}
              salePrice={salePrice}
              setSalePrice={setSalePrice}
              sku={sku}
              setSku={setSku}
              taxStatus={taxStatus}
              setTaxStatus={setTaxStatus}
              taxClass={taxClass}
              setTaxClass={setTaxClass}
              notes={notes}
              setNotes={setNotes}
              taxStatusArr={taxStatusArr}
              taxClassArr={taxClassArr}
            />

            <RoomOptions
              roomInfo={roomInfo}
              setRoomInfo={setRoomInfo}
              addRoom={addRoom}
              getDownloadURL={getDownloadURL}
              getRoomMainImgUrl={getRoomMainImgUrl}
            />
          </GridItem>

          <GridItem xs={3}>
            <Categories
              categories={categories}
              setCategories={setCategories}
              handleChecked={handleChecked}
            />
            <MainImage mainImage={mainImage} setMainImage={setMainImage} />
            <RewardGallery
              rewardImages={rewardImages}
              setRewardImages={setRewardImages}
            />
          </GridItem>
        </GridContainer>
      </Hidden>
      <Dialog
        className={classes.scheduleModal}
        open={openModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Card className={classes.card}>
          <GridContainer>
            <GridItem xs={12}>
              <h5 className={classes.cardHeader}>Schedule</h5>
            </GridItem>
            <GridItem
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Datetime
                open={calendarOpen}
                isValidDate={disablePastDt}
                input={false}
                selected={scheduleDate}
                dateFormat="MM/DD/YY"
                // dateFormat={false}
                onChange={(date, e) => {
                  setScheduleDate(date);
                }}
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside
              />
            </GridItem>
            <GridItem
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Datetime
                placeholder="From"
                open={calendarOpen}
                isValidDate={disablePastDt}
                input={false}
                selected={time}
                dateFormat={false}
                onChange={(time, e) => {
                  setTime(time);
                }}
                timeFormat={true}
                closeOnSelect={true}
                closeOnClickOutside
              />
            </GridItem>
          </GridContainer>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginBottom: "10px",
            }}
          >
            <Button
              style={{ background: "#1A405F", width: "50%" }}
              className={classes.saveButton}
              onClick={() => setOpenModal(false)}
            >
              <BsCheckCircleFill
                style={{ marginRight: "5px", fontSize: "22px" }}
              />
              Schedule
            </Button>
            <Button
              onClick={reset}
              className={classes.saveButton}
              style={{ background: "#56CA93", width: "50%" }}
            >
              <BiSolidLeftArrow
                style={{ marginRight: "5px", fontSize: "22px" }}
              />{" "}
              Back
            </Button>
          </div>
        </Card>
      </Dialog>

      <DialogLoader showModal={showModal} />
    </div>
  );
}
function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}
function mapDispatchToProps(actions) {
  return (dispatch) => ({});
}
export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
  )(EditTravelRewardsForm)
);
